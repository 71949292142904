import { Typography } from "@mui/material";
import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="container">
      <Typography variant="h4" color="secondary">
        WHO WE ARE
      </Typography>
      <Typography>Our website address is: rockhousefoundation.org.</Typography>
      <Typography variant="h5" color="secondary">
        WHAT PERSONAL DATA WE COLLECT AND WHY WE COLLECT IT
      </Typography>
      <Typography variant="h5" color="secondary">
        COMMENTS
      </Typography>
      <Typography>
        When visitors leave comments on the site we collect the data shown in
        the comments form, and also the visitor’s IP address and browser user
        agent string to help spam detection. An anonymized string created from
        your email address (also called a hash) may be provided to the Gravatar
        service to see if you are using it. The Gravatar service privacy policy
        is available here: https://automattic.com/privacy/. After approval of
        your comment, your profile picture is visible to the public in the
        context of your comment.
      </Typography>
      <Typography variant="h5" color="secondary">
        MEDIA
      </Typography>
      <Typography>
        If you upload images to the website, you should avoid uploading images
        with embedded location data (EXIF GPS) included. Visitors to the website
        can download and extract any location data from images on the website.
      </Typography>
      <Typography variant="h5" color="secondary">
        CONTACT FORMS
      </Typography>
      <Typography variant="h6" color="secondary">
        COOKIES
      </Typography>
      <Typography>
        If you leave a comment on our site you may opt-in to saving your name,
        email address and website in cookies. These are for your convenience so
        that you do not have to fill in your details again when you leave
        another comment. These cookies will last for one year. If you have an
        account and you log in to this site, we will set a temporary cookie to
        determine if your browser accepts cookies. This cookie contains no
        personal data and is discarded when you close your browser. When you log
        in, we will also set up several cookies to save your login information
        and your screen display choices. Login cookies last for two days, and
        screen options cookies last for a year. If you select "Remember Me",
        your login will persist for two weeks. If you log out of your account,
        the login cookies will be removed. If you edit or publish an article, an
        additional cookie will be saved in your browser. This cookie includes no
        personal data and simply indicates the post ID of the article you just
        edited. It expires after 1 day.
      </Typography>
      <Typography variant="h5" color="secondary">
        EMBEDDED CONTENT FROM OTHER WEBSITES
      </Typography>
      <Typography>
        Articles on this site may include embedded content (e.g. videos, images,
        articles, etc.). Embedded content from other websites behaves in the
        exact same way as if the visitor has visited the other website. These
        websites may collect data about you, use cookies, embed additional
        third-party tracking, and monitor your interaction with that embedded
        content, including tracking your interaction with the embedded content
        if you have an account and are logged in to that website.
      </Typography>
      <Typography variant="h6" color="secondary">
        ANALYTICS
      </Typography>
      <Typography variant="h5" color="secondary">
        WHO WE SHARE YOUR DATA WITH
      </Typography>
      <Typography variant="h5" color="secondary">
        HOW LONG WE RETAIN YOUR DATA
      </Typography>
      <Typography>
        If you leave a comment, the comment and its metadata are retained
        indefinitely. This is so we can recognize and approve any follow-up
        comments automatically instead of holding them in a moderation queue.
        For users that register on our website (if any), we also store the
        personal information they provide in their user profile. All users can
        see, edit, or delete their personal information at any time (except they
        cannot change their username). Website administrators can also see and
        edit that information.
      </Typography>
      <Typography variant="h5" color="secondary">
        WHAT RIGHTS YOU HAVE OVER YOUR DATA
      </Typography>
      <Typography>
        If you have an account on this site, or have left comments, you can
        request to receive an exported file of the personal data we hold about
        you, including any data you have provided to us. You can also request
        that we erase any personal data we hold about you. This does not include
        any data we are obliged to keep for administrative, legal, or security
        purposes.
      </Typography>
      <Typography variant="h5" color="secondary">
        WHERE WE SEND YOUR DATA
      </Typography>
      <Typography>
        Visitor comments may be checked through an automated spam detection
        service.
      </Typography>
      <Typography variant="h5" color="secondary">
        YOUR CONTACT INFORMATION
      </Typography>
      <Typography variant="h5" color="secondary">
        ADDITIONAL INFORMATION
      </Typography>
      <Typography variant="h6" color="secondary">
        HOW WE PROTECT YOUR DATA
      </Typography>
      <Typography variant="h6" color="secondary">
        WHAT DATA BREACH PROCEDURES WE HAVE IN PLACE
      </Typography>
      <Typography variant="h6" color="secondary">
        WHAT THIRD PARTIES WE RECEIVE DATA FROM
      </Typography>
      <Typography variant="h6" color="secondary">
        WHAT AUTOMATED DECISION MAKING AND/OR PROFILING WE DO WITH USER DATA
      </Typography>
      <Typography variant="h6" color="secondary">
        INDUSTRY REGULATORY DISCLOSURE REQUIREMENTS
      </Typography>
    </div>
  );
};

export default PrivacyPolicy;

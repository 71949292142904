import React from "react";
import { decode } from "html-entities";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import { Button, Typography } from "@mui/material";

export const NewsletterForm = ({ status, message, onValidated }) => {
  const [error, setError] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [first, setFirst] = React.useState("");
  const [last, setLast] = React.useState("");
  const handleFormSubmit = () => {
    setError("");

    if (email === "") {
      setError("Please enter your email.");
      return false;
    }

    const isFormValidated = onValidated({
      EMAIL: email,
      SIGNUP: "rhf_newsletter",
    });

    setEmail("");
    setFirst("");
    setLast("");

    // On success return true
    if (isFormValidated) {
      //   clear fields
      setEmail("");
    }
    return email && email.indexOf("@") > -1 && isFormValidated;
  };
  const handleInputKeyEvent = (event) => {
    setError("");
    // Number 13 is the "Enter" key on the keyboard
    if (event.keyCode === 13) {
      // Cancel the default action, if needed
      event.preventDefault();
      // Trigger the button element with a click
      handleFormSubmit();
    }
  };
  const getMessage = (message) => {
    if (!message) {
      return null;
    }
    const result = message?.split("-") ?? null;
    if ("0" !== result?.[0]?.trim()) {
      return decode(message);
    }
    const formattedMessage = result?.[1]?.trim() ?? null;
    return formattedMessage ? decode(formattedMessage) : null;
  };
  return (
    <div className="my-10 mx-auto  flex max-w-xl flex-col space-y-2">
      <Typography
        variant="h2"
        component="h1"
        sx={{ fontWeight: "bold", textAlign: "center", mb: "1rem" }}
        color="secondary"
      >
        Sign up for our newsletter
      </Typography>
      <div className="form-group ">
        <label htmlFor="first">First Name</label>
        <input
          type="text"
          className="form-control rounded-md "
          id="first"
          value={first}
          onChange={(e) => setFirst(e.target.value)}
        />
      </div>
      <div className="form-group">
        <label htmlFor="last">Last Name</label>
        <input
          type="text"
          className="form-control rounded-md  "
          id="last"
          value={last}
          onChange={(e) => setLast(e.target.value)}
        />
      </div>
      <div className="form-group">
        <label htmlFor="email">Email address</label>
        <input
          required
          type="email"
          className="form-control mb-1 rounded-md "
          id="email"
          aria-describedby="emailHelp"
          placeholder="Enter email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onKeyDown={handleInputKeyEvent}
        />
      </div>

      <small id="emailHelp" className="form-text text-muted ">
        By submitting this form, you are consenting to receive marketing emails
        from: Rockhouse Foundation. You can revoke your consent to receive
        emails at any time by using the SafeUnsubscribe® link, found at the
        bottom of every email.
      </small>
      {error && <div className="alert alert-danger mb-2">{error}</div>}
      {status === "sending" && (
        <div className="alert alert-info mb-2">Sending...</div>
      )}
      {status === "error" && (
        <div
          className="alert alert-danger mb-2"
          dangerouslySetInnerHTML={{ __html: getMessage(message) ?? "" }}
        />
      )}
      {status === "success" && (
        <div
          className="alert alert-success mb-2"
          dangerouslySetInnerHTML={{ __html: getMessage(message) ?? "" }}
        />
      )}
      <input type="hidden" name="SIGNUP" value="rhf_newsletter" />
      {/* <button
        type="button"
        className="btn btn-primary"
        onClick={handleFormSubmit}
      >
        Submit
      </button> */}
      <Button
        variant="contained"
        color="primary"
        onClick={handleFormSubmit}
        sx={{
          color: "white",
          borderRadius: "25px",
          marginTop: 2,
          fontWeight: "bold",
          //   alignSelf: 'center',
        }}
      >
        Submit
      </Button>
    </div>
  );
};

export default function Newsletter() {
  return (
    <MailchimpSubscribe
      url="https://skylarknegril.us19.list-manage.com/subscribe/post?u=05ef4612941924f8daf6aa200&amp;id=12975f07d3&amp;f_id=003eb1e4f0&amp;SIGNUP=rhf_newsletter"
      render={(props) => {
        const { subscribe, status, message } = props || {};
        return (
          <NewsletterForm
            status={status}
            message={message}
            onValidated={(formData) => subscribe(formData)}
          />
        );
      }}
    />
  );
}

/*
<div id="mc_embed_shell">
  
           <div id="mc_embed_signup">
               <form action="https://skylarknegril.us19.list-manage.com/subscribe/post?u=05ef4612941924f8daf6aa200&amp;id=12975f07d3&amp;f_id=003eb1e4f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank">
                   <div id="mc_embed_signup_scroll"><h2>Subscribe</h2>
                       <div class="indicates-required"><span class="asterisk">*</span> indicates required</div>
                       <div class="mc-field-group"><label for="mce-SIGNUP">Signup form location </label><input type="text" name="SIGNUP" class=" text" id="mce-SIGNUP" value=""></div><div class="mc-field-group"><label for="mce-FNAME">First Name </label><input type="text" name="FNAME" class=" text" id="mce-FNAME" value=""></div><div class="mc-field-group"><label for="mce-LNAME">Last Name </label><input type="text" name="LNAME" class=" text" id="mce-LNAME" value=""></div><div class="mc-field-group"><label for="mce-EMAIL">Email Address <span class="asterisk">*</span></label><input type="email" name="EMAIL" class="required email" id="mce-EMAIL" value="" required=""></div>
           <div hidden=""><input type="hidden" name="tags" value="11090429"></div>
                   <div id="mce-responses" class="clear">
                       <div class="response" id="mce-error-response" style="display: none;"></div>
                       <div class="response" id="mce-success-response" style="display: none;"></div>
                   </div><div aria-hidden="true" style="position: absolute; left: -5000px;"><input type="text" name="b_05ef4612941924f8daf6aa200_12975f07d3" tabindex="-1" value=""></div><div class="clear"><input type="submit" name="subscribe" id="mc-embedded-subscribe" class="button" value="Subscribe"></div>
               </div>
           </form>
           </div>
           <script type="text/javascript" src="//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js"></script><script type="text/javascript">(function($) {window.fnames = new Array(); window.ftypes = new Array();fnames[6]='SIGNUP';ftypes[6]='text';fnames[1]='FNAME';ftypes[1]='text';fnames[2]='LNAME';ftypes[2]='text';fnames[0]='EMAIL';ftypes[0]='email';}(jQuery));var $mcj = jQuery.noConflict(true);</script></div>
           
 */

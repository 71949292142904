import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EventItem from './EventItem';
import EventItemReverse from './EventItemReverse';
import StyledTitle from '../StyledTitle';
import { fetchEvents } from '../../../features/events/eventSlice';
import { Box, Stack, Typography } from '@mui/material';
import ButtonExternal from '../ButtonExternal';

// {
//     "id": "9735745e-103a-4a1c-abd3-b7f03bbede7c",
//     "pkid": 2,
//     "title": "test 2",
//     "subtitle": "INCLUDING THE ROCKHOUSE RAFFLE DRAW",
//     "date": "November 2021 NYC",
//     "text": "efdfg gwaf",
//     "text2": "",
//     "video_url": null,
//     "is_video": false,
//     "image": "https://res.cloudinary.com/dfwvu4gct/image/upload/v1/mediafiles/events/F0-DbJoaIAAR0Uu_jpmfuo",
//     "btn_text": null,
//     "btn_url": null,
//     "img_left": false,
//     "list_items": [],
//     "category": "Previous Events"
// },

function CurrentEvents() {
	// fetch Current events from database
	const dispatch = useDispatch();
	const { events } = useSelector((state) => state.events);

	useEffect(() => {
		dispatch(fetchEvents());
	}, []);

	return (
		<Stack spacing={4}>
			{/* <StyledTitle title="CURRENT EVENTS" /> */}
			{/* <Box className="mx-auto text-center">
        <Typography
          variant="h2"
          component="h2"
          color="secondary"
          align="center"
        >
          {" "}
          Dinner on the Beach with Chef Andre Fowles
        </Typography>
        <Typography variant="subtitle2" align="center">
          December 28th, 2023
        </Typography>
        <Stack
          component="a"
          href="https://rockhousefoundation.networkforgood.com/events/65001-dinner-on-the-beach-with-chef-andre-fowles"
        >
          <img
            alt=""
            className="mx-auto object-contain md:w-3/4 w-full h-auto mb-2"
            loading="lazy"
            src="/dinner-on-the-beach-Andrew-Fowles_Feed.jpeg"
          />
          <Box className="mx-auto mt-3">
            <ButtonExternal href="https://rockhousefoundation.networkforgood.com/events/65001-dinner-on-the-beach-with-chef-andre-fowles">
              Buy Tickets
            </ButtonExternal>
          </Box>
        </Stack>
        <Typography variant="body1" align="left">
          On Thursday, December 28, Miss Lily's culinary director, chef Andre
          Fowles will host a special Rockhouse Foundation dinner on the beach,
          alongside celebrated chefs and Kingston natives, Danya Smith of Lulu's
          Apron and Simon Levy of Roast Specialty Meats. Chef Andre, who also
          hails from Kingston, will curate a night of live-fire and elevated
          beachfront cooking with the best of Jamaica's produce, much of which
          will be sourced from the onsite Rockhouse hydroponic farm. Guests will
          enjoy a three course dinner featuring a whole roasted pig & herb
          porchetta, along with welcome canapes like corn & truffle dumplings
          and pepper shrimp summer rolls, paired with cocktails from Worthy
          Park, for a night celebrating the talents and flavors of the island
          (vegetarian options will be available).
        </Typography>
        <Typography variant="body1" align="left">
          100% of proceeds from ticket sales will benefit the Rockhouse
          Foundation, our charitable arm dedicated to transforming the places
          where Jamaica's children learn, and supporting the great people who
          teach them. Specifically, this event will fund the ongoing work
          expanding Sav Inclusive's Primary School campus, built from the ground
          up by the Foundation, and serving children with and without
          disabilities in a full inclusion setting.
        </Typography>
        <Typography variant="body1" align="left">
          <span className="font-bold block">
            Evening Schedule: Thursday, December 28
          </span>
          <span className="font-bold">5:30pm</span> - Sunset Cocktails from
          Worthy Park rum, wine and canapes at Miss Lily's Negril at Skylark
          with entertainment from the Silver Birds Steel Drum Orchestra <br />
          <span className="font-bold">6:45pm</span> - Seating begins for dinner
          on the beach at Skylark Negril Beach Resort with chef Andre Fowles,
          chef Danya Smith and chef Simon Levy, with support from Miss Lily's
          Negril executive chef, Kahari Woolcock <br />
          <span className="font-bold">7:00pm</span> - Welcome presentation from
          Paul Salmon, chairman of Rockhouse and co-founder of Rockhouse
          Foundation <br />
          <span className="font-bold">7:15pm</span> - Chef Andre introduces
          Danya and Simon and presents the menu details; dinner commences <br />
          <span className="font-bold">9pm - 11pm</span> Dinner concludes; after
          party at Miss Lily's at Skylark with DJ Max Glazer
        </Typography>
        <ButtonExternal href="https://rockhousefoundation.networkforgood.com/events/65001-dinner-on-the-beach-with-chef-andre-fowles">
          Buy Tickets
        </ButtonExternal>
      </Box> */}

			{events
				?.filter((evt) => evt.category === 'Current Events')
				.map(
					(
						{
							btn_url,
							id,
							list_items,
							image,
							btn_text,
							text,
							text2,
							title,
							subtitle,
							is_video,
							video_url,
							img_left,
							date,
							btn_center_under_text = null,
						},
						i
					) =>
						img_left ? (
							<EventItem
								key={i}
								title={title}
								subtitle={subtitle}
								date={date}
								text={text}
								text2={text2}
								videoUrl={video_url}
								btnText={btn_text}
								btnUrl={btn_url}
								video={is_video}
								hasBtn={!!btn_url}
								src={image}
								listItems={list_items}
								btn_center_under_text={btn_center_under_text}
							/>
						) : (
							<EventItemReverse
								key={i}
								title={title}
								subtitle={subtitle}
								date={date}
								text={text}
								text2={text2}
								videoUrl={video_url}
								btnText={btn_text}
								btnUrl={btn_url}
								video={is_video}
								src={image}
								hasBtn={!!btn_url}
								listItems={list_items}
								btn_center_under_text={btn_center_under_text}
							/>
						)
				)}
		</Stack>
	);
}

export default CurrentEvents;

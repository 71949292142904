import { Box, Typography } from "@mui/material";
import React, { useState } from "react";

import VideoPlayer from "../layout/VideoPlayer";
import RHButtonText from "./RHButtonText";
import ImageModal from "./ImageModal";

const TextImage = ({
  text1,
  title,
  text2 = "",
  text3 = "",
  link = "",
  linkText = "",
  vUrl = null,
  url = null,
  subtitle = "",
  variant = "h2",
}) => {
  const [selectedImg, setSelectedImg] = useState(null);

  return (
    <>
      <div className="flex items-center my-4">
        <Box className="lg:grid lg:grid-cols-2 lg:gap-16  gap-4 flex flex-col lg:items-center items-start">
          <Box className="container">
            <Typography variant="h2" component="h2" color="secondary">
              {title}
            </Typography>
            {subtitle !== "" && (
              <Typography variant="subtitle1">{subtitle}</Typography>
            )}
            <Typography variant="body1">{text1}</Typography>
            {text2 !== "" && <Typography variant="body1">{text2}</Typography>}
            {text3 !== "" && <Typography variant="body1">{text3}</Typography>}

            {link !== "" && (
              <span className="font-bold">
                <RHButtonText url={link} text={linkText} />
              </span>
            )}
          </Box>
          {url && (
            <Box>
              <img
                onClick={() => setSelectedImg(url)}
                src={url}
                alt={title}
                loading="lazy"
                className="object-contain  w-full"
              />
            </Box>
          )}
          {vUrl && <VideoPlayer url={vUrl} />}
        </Box>
      </div>
      {selectedImg && (
        <ImageModal selectedImg={selectedImg} setSelectedImg={setSelectedImg} />
      )}
    </>
  );
};

export default TextImage;

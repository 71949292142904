import React from "react";

function ImageTextLeft({ title, img, url, content, video = false }) {
  return (
    <div className="my-4 grid w-screen grid-cols-1 sm:gap-4 gap-0 md:grid-cols-2">
      {video ? (
        <iframe src={url} height={300} className="hidden md:block" />
      ) : (
        <img src={img} alt={title} className="hidden md:block" />
      )}
      <div className="align-center flex flex-col justify-center px-10">
        {content}
      </div>
      <img src={img} alt="" className="block md:hidden" />
    </div>
  );
}

export default ImageTextLeft;

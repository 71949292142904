import { Box, Typography } from "@mui/material";
import React, { useState } from "react";

import RHButtonText from "./RHButtonText";
import ImageModal from "./ImageModal";

const TextImageReverse = ({
  url,
  title,
  text1,
  text2 = "",
  text3 = "",
  linkText = "",
  link = "",
  subtitle = "",
  variant = "h2",
}) => {
  const [selectedImg, setSelectedImg] = useState(null);

  return (
    <>
      <div className="flex items-center my-4">
        <Box className="lg:grid lg:grid-cols-2 lg:gap-16 gap-4 lg:items-center flex  items-start flex-col-reverse">
          <img
            className=" object-contain w-full"
            src={url}
            alt={title}
            loading="lazy"
            onClick={() => setSelectedImg(url)}
          />
          <Box className="container">
            <Typography variant="h2" component="h2" color="secondary">
              {title}
            </Typography>
            {subtitle !== "" && (
              <Typography variant="subtitle1">{subtitle}</Typography>
            )}

            <Typography variant="body1">{text1}</Typography>
            {text2 !== "" && <Typography variant="body1">{text2}</Typography>}
            {text3 !== "" && <Typography variant="body1">{text3}</Typography>}
            {link !== "" && (
              <span className="font-bold">
                <RHButtonText url={link} text={linkText} />
              </span>
            )}
          </Box>
        </Box>
      </div>
      {selectedImg && (
        <ImageModal selectedImg={selectedImg} setSelectedImg={setSelectedImg} />
      )}
    </>
  );
};

export default TextImageReverse;

import React from 'react';

import { Box, Typography } from '@mui/material';
import VideoPlayer from '../../layout/VideoPlayer';
import ButtonExternal from '../ButtonExternal';

export default function EventItem({
	title,
	subtitle,
	text,
	// text1,
	text2,
	src,
	title2 = '',
	video,
	videoUrl = '',
	reverseCol = false,
	reverseRow = false,
	hasBtn = false,
	btnText = null,
	btnUrl = null,
	date = '',
	listItems = [],
	btn_center_under_text = false,
}) {
	return (
		<div className="md:my-4 my-2">
			<Box
				className={`gap-3 md:gap-4 md:items-center items-start flex md:space-x-10 space-x-0 ${reverseCol ? 'flex-col-reverse' : 'flex-col'
					} ${reverseRow ? 'md:flex-row-reverse' : 'md:flex-row'} md:my-4 my-2`}
			>
				{src !== null && (
					<Box className="md:w-1/2 w-full ">
						<img
							alt=""
							className="mx-auto object-contain w-full h-auto"
							loading="lazy"
							src={src}
						/>
					</Box>
				)}

				{video && (
					<Box className="md:w-1/2 w-full ">
						<VideoPlayer url={videoUrl} />
					</Box>
				)}

				<Box className="md:w-1/2 w-full">
					<Typography variant="h2" component="h2" color="secondary">
						{title}
					</Typography>
					{!!subtitle && (
						<Typography variant="h3" component="h3" color="secondary">
							{subtitle}
						</Typography>
					)}
					<Typography variant="subtitle2">{date}</Typography>

					<Typography variant="body1">{text}</Typography>
					{!!text2 && <Typography variant="body1">{text2}</Typography>}
					{listItems?.length > 0 &&
						listItems.map((item) => (
							<Typography variant="body1" key={item}>
								{item}
							</Typography>
						))}
					{btnUrl !== null && btnText !== null && (
						<Box sx={{ textAlign: 'center' }}>
							<ButtonExternal href={btnUrl}>{btnText}</ButtonExternal>
						</Box>
					)}
				</Box>
			</Box>
			{/* {btnUrl !== null && btnText !== null && !btn_center_under_text && (
				<Box sx={{ textAlign: 'center' }}>
					<ButtonExternal href={btnUrl}>{btnText}</ButtonExternal>
				</Box>
			)} */}
			{/* <CustomDialog open={open} handleClose={() => setOpen(false)}>
        <img
          alt=""
          className="mx-auto object-contain w-full h-auto"
          loading="lazy"
          src={src}
        />
      </CustomDialog> */}
		</div>
	);
}

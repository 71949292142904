import { Helmet } from "react-helmet";

export default function Seo({ title, description, name, type }) {
  return (
    <Helmet
      style={[
        {
          cssText: `
		body:{ 
			margin: 0;
			padding: 0 ;
		}
		`,
        },
      ]}
    >
      {/* Standard metadata tags */}
      <title>{title + " | Rockhouse Foundation"}</title>

      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
      <link
        href="https://fonts.googleapis.com/css2?family=Cormorant+Garamond&family=Open+Sans&family=PT+Sans+Narrow&display=swap"
        rel="stylesheet"
      ></link>
      <meta
        name="description"
        content="The Rockhouse Foundation over the past decade has invested over US$4 million in projects improving education for the children in the Negril area. Since its inception, the Foundation has completely transformed and modernized six schools: Moreland Hill Primary and Infant Schools, Negril All Age School, Negril Basic School, Little Bay All Age and Infant Schools, Bunch of Stars E.C.I., and Savanna-la-Mar Inclusive Infant Academy (SIIA), in addition to the complete renovation and expansion of the Negril Community Library."
      />
      {/* End standard metadata tags */}
      {/* Facebook tags */}
      <meta property="og:type" content={type} />
      <meta property="og:title" content={title} />
      <meta
        property="og:description"
        content="The Rockhouse Foundation over the past decade has invested over US$4 million in projects improving education for the children in the Negril area. Since its inception, the Foundation has completely transformed and modernized six schools: Moreland Hill Primary and Infant Schools, Negril All Age School, Negril Basic School, Little Bay All Age and Infant Schools, Bunch of Stars E.C.I., and Savanna-la-Mar Inclusive Infant Academy (SIIA), in addition to the complete renovation and expansion of the Negril Community Library."
      />
      {/* End Facebook tags */}
      {/* Twitter tags */}
      <meta name="twitter:creator" content={name} />
      <meta name="twitter:card" content={type} />
      <meta name="twitter:title" content={title} />
      <meta
        name="twitter:description"
        content="The Rockhouse Foundation over the past decade has invested over US$4 million in projects improving education for the children in the Negril area. Since its inception, the Foundation has completely transformed and modernized six schools: Moreland Hill Primary and Infant Schools, Negril All Age School, Negril Basic School, Little Bay All Age and Infant Schools, Bunch of Stars E.C.I., and Savanna-la-Mar Inclusive Infant Academy (SIIA), in addition to the complete renovation and expansion of the Negril Community Library."
      />
      {/* End Twitter tags */}
    </Helmet>
  );
}

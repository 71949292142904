import { Box } from "@mui/material";
import React from "react";
import Pages from "../components/features/Pages";
import Seo from "../components/layout/Seo";

import PreviousEvents from "../components/features/events/PreviousEvents";
import CurrentEvents from "../components/features/events/CurrentEvents";

export const RAFFLE_URL = 'https://www.gofundme.com/f/sav-inclusive-expansion-campaign-2024'


const raffle = [
  'https://res.cloudinary.com/dfwvu4gct/image/upload/v1714409468/RHF/RHF_RaffleWeb_800x375_01_eqaqbt.jpg',
  'https://res.cloudinary.com/dfwvu4gct/image/upload/v1714409469/RHF/RHF_RaffleWeb_800x375_02_w99awu.jpg',
  'https://res.cloudinary.com/dfwvu4gct/image/upload/v1714409469/RHF/RHF_RaffleWeb_800x375_03_hq0b8e.jpg',
  'https://res.cloudinary.com/dfwvu4gct/image/upload/v1714409471/RHF/RHF_RaffleWeb_800x375_04_stisct.jpg',
  'https://res.cloudinary.com/dfwvu4gct/image/upload/v1714409471/RHF/RHF_RaffleWeb_800x375_05_h0zva8.jpg',
  'https://res.cloudinary.com/dfwvu4gct/image/upload/v1714409472/RHF/RHF_RaffleWeb_800x375_06_rmlkox.jpg',
  'https://res.cloudinary.com/dfwvu4gct/image/upload/v1714409473/RHF/RHF_RaffleWeb_800x375_07_omcpds.jpg',
  'https://res.cloudinary.com/dfwvu4gct/image/upload/v1714409474/RHF/RHF_RaffleWeb_800x375_08_c0mwz6.jpg',
  'https://res.cloudinary.com/dfwvu4gct/image/upload/v1714409475/RHF/RHF_RaffleWeb_800x375_09_fw27ju.jpg',
  'https://res.cloudinary.com/dfwvu4gct/image/upload/v1714409476/RHF/RHF_RaffleWeb_800x375_10_cg7qqn.jpg'

];

const Events = () => {
  const raffleUrl = 'https://www.gofundme.com/f/sav-inclusive-expansion-campaign-2024'
  return (
    <>
      <Seo title="Events" />

      <Pages
        useNew
        title="CURRENT EVENTS"
        imageUrl="https://res.cloudinary.com/dfwvu4gct/image/upload/fl_preserve_transparency/v1665598688/RHF/RHF_Supporters_1920x823_A_Events_j8euyu.jpg?_s=public-apps"
        mobileSrc="https://res.cloudinary.com/dfwvu4gct/image/upload/fl_preserve_transparency/v1665598688/RHF/RHF_Supporters_1920x823_A_Events_j8euyu.jpg?_s=public-apps"
      >
        <Box className=" my-4 container">
          {/* <div className="w-full max-w-xl mx-auto cursor-pointer mb-4">
            <img onClick={() => {
              window.open(raffleUrl, '_blank')
            }} src="https://res.cloudinary.com/dfwvu4gct/image/upload/v1714409319/RHF/Screen_Shot_2024-04-29_at_10.42.11_AM_uahzsn.png" className="object-contain w-full" />
            {
              raffle.map((img, index) => (
                <img key={index} src={img} className="object-contain w-full" onClick={() => {
                  window.open(raffleUrl, '_blank')
                }} />
              ))
            }
            <img src="https://res.cloudinary.com/dfwvu4gct/image/upload/v1714409919/RHF/Screen_Shot_2024-04-29_at_10.44.18_AM_wva8fz.png" className="object-contain w-full" onClick={() => {
              window.open(raffleUrl, '_blank')
            }} />
            <img src="https://res.cloudinary.com/dfwvu4gct/image/upload/v1714409893/RHF/Screen_Shot_2024-04-29_at_10.44.50_AM_q3pm8i.png" className="object-contain w-full" onClick={() => {
              window.open('https://rockhousefoundation.networkforgood.com/projects/95296-rockhouse-foundation', '_blank')
            }} />
          </div> */}
          <CurrentEvents />
        </Box>

        <div className="container my-4">
          {/* <Box className="mx-auto space-y-4">
            <EventItem
              title="SUMMER RAFFLE"
              date="Drawing July 15, 2023"
              text1="Sav Inclusive is Jamaica’s only public school serving children with disabilities side-by-side typical learners in a full-inclusion setting, currently age three through grade three. Rockhouse Foundation needs your help as we continue expansion of Sav Inclusive’s new primary campus as we support some of western Jamaica’s most vulnerable children pursue their academic journey straight through graduation from high school on the same compound. This summer we will be constructing the primary school campus administration center, in addition to building an inclusive playspace and climbing wall specially designed to cater to all children, regardless of physical or developmental profile. All administrative costs are fully underwritten by the hotel and its ownership."
              video
              videoUrl="https://www.youtube.com/embed/DOl5z2SyAkI"
            />
            <EventItemReverse
              title="DINNER WITH CHEF ERIC ADJEPONG"
              title2="INCLUDING THE ROCKHOUSE RAFFLE DRAW"
              date="July 15th, 2023"
              text1="On Saturday, July 15th, 2023 we’re teaming up with our friend, chef Eric Adjepong, for a special guest chef dinner on the beach at Skylark Negril Beach Resort in Jamaica. A first generation Ghanaian-American, chef Eric Adjepong is celebrated for infusing West African flavors into his dishes. Chef Eric has cooked in various Michelin-starred restaurants, was a finalist on Top Chef, and can now be found hosting Alex vs America on Food Network. Eric will prepare a three course dinner with welcome canapés, celebrating the flavors of his West African roots alongside the best of Jamaica’s local produce, much of which is sourced from Rockhouse’s new on-site hydroponic farm. The dinner ends with the highly-anticipated drawing of Rockhouse’s annual Raffle (each ticket purchase includes an entry in the raffle) to be followed by an after party at Miss Lily’s at Skylark with DJ Max Glazer."
              src="https://firebasestorage.googleapis.com/v0/b/skylark-f6267.appspot.com/o/rhf%2FDOTB-EricA_800x800.jpg?alt=media&token=915fe88b-e464-4d30-beed-fc0555f03461"
              hasBtn
              btnText="BUY TICKETS"
              btnUrl="https://rockhousefoundation.networkforgood.com/events/57708-dinner-on-the-beach-with-chef-eric-adjepong"
            />

         
          </Box> */}
          <PreviousEvents />
          {/* 
          <EventItem
            title="CHEF NYESHA ARRINGTON"
            date="December 28th, 2022"
            text1="Chef Nyesha Arrington hosted our latest guest chef dinner on the beach at Skylark Negril Beach Resort in Jamaica. Chef Nyesha has competed on Bravo’s Top Chef and is most recently featured as a recurring judge on Chopped Next Generation and Fox’s Next Level Chef. Nyesha was supported by Miss Lily’s Culinary Direction, Chef Andre Fowles and Executive Chef Kahari Woolcock. Nyesha prepared an amazing three course dinner combining the best of Jamaica’s local ingredients, with inspiration from her vibrant cultural background and California roots. 100% of proceeds benefited The Rockhouse Foundation, with over US$30,000 raised."
            src="https://firebasestorage.googleapis.com/v0/b/skylark-f6267.appspot.com/o/rhf%2Fchopped.jpeg?alt=media&token=9d0a6bdd-f2c5-4c1b-bc67-7578771f63ce"
          />
          <EventItemReverse
            title="ANNUAL BENEFIT DINNER AT THE PUBLIC THEATER"
            src="https://firebasestorage.googleapis.com/v0/b/skylark-f6267.appspot.com/o/rhf%2Fevents-980x849.jpeg?alt=media&token=d2698a5d-4c5f-44f8-b20a-1f8d410596f0"
            date="October 2022 NYC"
            text1="On October 17, 2022 the Rockhouse Foundation held the Annual Benefit at Joe’s Pub at the Public Theater in New York City. With Rockhouse, Skylark and Miss Lily’s paying all the event’s expenses, over US$300,000 was raised. The menu was a collaboration from Miss Lily’s Negril Executive Chef Kahari Woolcock together with Chef Ricky Mungaray from Andrew Carmellini’s Kitchen at Joe’s Pub & The Library. The energy was up and a sea of paddles were in the air supporting both the auction and fund and item pledges. To close the night Wayne Wonder gave a high energy performance of his hit parade and had the whole room on its feet, and board member Max Glazer kept the vibes up with some inspired DJ-ing, converting the stage to a dance floor! Thanks so much to all our supporters who gave so generously."
          />
          <EventItem
            title="SUMMER RAFFLE"
            date="September 2022"
            text1="The raffle was a great success and we send our heartfelt thanks to all those who supported this great cause. We are pleased to announce the winners:"
            text2={
              "1st prize – Rockhouse trip flying JetBlue *Travis Robertson\n2nd prize – Skylark Negril trip flying JetBlue *Diana Rhodes\n3rd prize – Dinner for 10 at Miss Lily’s *Victoria Peet\n4th prize – 3 nights at Jake’s Resort *Leslie Lanahan\n5th prize – 5 days of Spa treatments *Lisa William\n6th prize – Golf for 2 at Tryall Club *Tara Prout\n7th prize – Dinner for 2 at Zimbali *Jessica Kerwin\n8th prize – Dinner for 2 at The Caves *Harlan Stone\n9th prize – Dinner for 2 at Ivan’s *Matthew Greenwood\n10th prize – Dinner for 2 at Tensing Pen *Nicola Vassel"
            }
            src="https://firebasestorage.googleapis.com/v0/b/skylark-f6267.appspot.com/o/rhf%2Frhf-980x980.jpeg?alt=media&token=84059d63-3597-490f-b421-6ca1fcc17fb7"
          />
          <EventItemReverse
            title="“TEACH DI YUTES” 2021-22 CAMPAIGN"
            src="https://firebasestorage.googleapis.com/v0/b/skylark-f6267.appspot.com/o/rhf%2FRHF-Teach-Di-Yutes-21-22.png?alt=media&token=ca7bb159-4eba-4f29-af62-a586d717dc3a"
            date="November 2021 – April 2022"
            text1="This campaign as the primary source for underwriting our activities for the the first half of 2022. The funds raised underwrote our the beginning of the further expansion of the Sav Inclusive School, and Ongoing Programatic and Maintenance Support for our Partner Schools."
            hasBtn
            btnText="SEE MORE"
            btnUrl="https://www.gofundme.com/f/teach-di-yutes-20212022-campaign"
          />
          <EventItem
            title="MASHAMA BAILEY IN NEGRIL"
            date="April 2022 Negril, Jamaica"
            text1="James Beard award winner Mashama Bailey and John Morisano of The Grey in Savannah, Georgia brought their culinary magic to Negril for a weekend of food, friends and good vibes to raise money for the Rockhouse Foundation. Saturday night at Skylark Negril, Mashama and Johno wowed the sold out dinner with a Savannah meets Negril feast looking out over the Caribbean Sea. On Friday night at Pushcart at Rockhouse a magnificent Jamaican feast was prepared by Miss Lily’s Culinary Director Chef Andre Fowles, Executive Chef of Miss Lily’s Negril, Chef Kahari Woolcock, and Rockhouse Executive Chef, Jermaine “Bagga” Forrester. Awesome cocktails were supplied by Ten To One Caribbean Rum, and founder Marc Farrell joining the festivities. On Saturday morning Chef Andre Fowles moderated an enlightening conversation with Mashama and Johno, exploring their journey together opening The Grey, one of America’s most celebrated restaurants. They were also kind enough to present all in attendance with signed copies of their new book, Black, White and The Grey – The Story of an Unexpected Friendship and a Beloved Restaurant."
            src="https://firebasestorage.googleapis.com/v0/b/skylark-f6267.appspot.com/o/rhf%2FGetawayMashama_Feed.jpg?alt=media&token=4a122075-b886-4e12-a91a-03e4482110bf"
          />
          <EventItemReverse
            title="ANNUAL BENEFIT DINNER AT THE PUBLIC THEATER"
            src="https://firebasestorage.googleapis.com/v0/b/skylark-f6267.appspot.com/o/rhf%2FDSC_8850.jpeg?alt=media&token=4f78062b-81a6-491e-919c-70f6a443107e"
            date="November 2021 NYC"
            text1="On November 22, 2021 the Rockhouse Foundation returned to an in real life Annual Benefits at the 16th annual dinner and performance at Joe’s Pub at the Public Theater in New York City. With Rockhouse, Skylark and Miss Lily’s paying all the event’s expenses, over US$300,000 was raised. The menu was a collaboration from Miss Lily’s Chef Andre Fowles and Chef Kahari Woolcock together with Chef Ricky Mungaray from Andrew Carmellini’s Kitchen at Joe’s Pub & The Library. New board member, Jamaican born Kaysilyn Lawson gave a speech about her education experience growing up in Jamaica and her connection to the Foundation and its work in Jamaica. Jamaica’s former Minister of Education, Ronnie Thwaites, was the keynote speaker, including reading a letter from the Permanent Secretary of Education, Maureen Dwyer, detailing the immeasurable impact that the Foundation has had on the Jamaican school system. To close the night Shaggy gave an inspired performance that had the whole room on its feet."
          />
          <EventItem
            title="SUMMER RAFFLE"
            date="June 2021 – August 2021"
            text1="The 2021 Rockhouse Foundation Summer Raffle was a great success, exceeding our goal and raising over $107,000. Thanks to all those that supported us. We are pleased to announce the winners:"
            text2={
              "1st prize – Rockhouse trip flying JetBlue *James Ector\n2nd prize – Skylark Negril trip flying JetBlue *Bess Fifer\n3rd prize – Dinner for 10 at Miss Lily’s *William Obrien\n4th prize – 3 nights at Jake’s Resort *Sharon Lucius\n5th prize – 5 days of Spa treatments *Briant Hamrell\n6th prize – Golf for 2 at Tryall Club *Terry Igell\n7th prize – Dinner for 2 at Zimbali *Jeffrey Bashore\n8th prize – Dinner for 2 at The Caves *Matthew Zucker\n9th prize – Dinner for 2 at Ivan’s *Susan Binch\n10th prize – Dinner for 2 at Tensing Pen *Shelley Paulson"
            }
            src="https://firebasestorage.googleapis.com/v0/b/skylark-f6267.appspot.com/o/rhf%2Frkraffle22.jpeg?alt=media&token=f3123eb9-e964-43df-a1c4-fa2bfd94c88f"
            hasBtn
            btnText="SEE MORE"
            btnUrl="https://www.gofundme.com/"
          />
          <EventItemReverse
            title="“TEACH DI YUTES” CAMPAIGN"
            src="https://firebasestorage.googleapis.com/v0/b/skylark-f6267.appspot.com/o/rhf%2FTeachDiYutes2021.jpeg?alt=media&token=9a44dafb-3ec8-456a-8663-be7b9c1f09c6"
            date="Year End 2020"
            text1="Without our regular annual fundraising event we establishing this campaign as the primary source for underwriting our activities for the the first half of 2021. The funds raised underwrote our Food Security, Online and In Person Learning Support, further expansion of the Sav Inclusive School, and Ongoing Programatic and Maintenance Support for our Partner Schools."
            hasBtn
            btnText="SEE MORE"
            btnUrl="https://www.gofundme.com/"
          />
          <EventItem
            title="2020 COVID-19 RELIEF FUND"
            date="April – May 2020 Negril, Jamaica"
            text1="In response to COVID related  emergency challenges the Rockhouse
			Foundation in March 2020 began weekly distributions of food
			staples to our most vulnerable families at the partner schools.
			To fund this ongoing program a GoFundMe Campaign was launched,
			turning to their network of generous and loyal donors, the Covid
			Relief Fund raised over US$100,000 within a matter of weeks. The
			campaign culminated in and instagram live benefit concert
			featuring long term supporters Chronixx and Shaggy."
            src="https://firebasestorage.googleapis.com/v0/b/skylark-f6267.appspot.com/o/rhf%2FCovid19_ReliefFundLiveEvent_800x530-1.jpg?alt=media&token=4c7a36da-c960-4d12-a9e6-83c1dbfd661a"
            hasBtn
            btnText="SEE MORE"
            btnUrl="https://www.rockhousefoundation.org/blog/covid-19-update-for-negril-jamaica/"
          />
          <EventItemReverse
            title="MARCUS SAMUELSSON DINNER AT SKYLARK"
            date="December 2019 Negril, Jamaica"
            text1="What would you say Ethiopia and Jamaica have in common? A whole lot more after Sunday, December 29 when Ethiopian-born chef Marcus Samuelsson hosted an Ethopian-Jamaican fusion four-course meal for the annual Rockhouse Foundation’s Benefit Dinner! The dinner took place at the iconic Miss Lily’s’ restaurant’s new outpost in Negril, in none other than Skylark, the newly opened spunky younger-sister property of Rockhouse. The evening’s menu was a face-off between the two countries’ cuisines, but the real winning ticket was the over- J$4 million (US$30,000) raised by guests for the Rockhouse Foundation’s initiative to improve education in western Jamaica."
            video
            videoUrl="https://www.youtube.com/embed/u5_sjuJ9ivI"
            hasBtn
            btnText="SEE MORE"
            btnUrl="https://www.rockhousefoundation.org/"
          />
          <EventItem
            title="ANNUAL BENEFIT DINNER AT THE PUBLIC THEATER"
            date="October 2019 NYC"
            text1="In 2019 the Rockhouse Foundation continued its run of ever more successful Annual Benefits at the 15th annual dinner and performance at Joe’s Pub at the Public Theater in New York City. With Rockhouse, Skylark and Miss Lily’s paying all the event’s expenses, US$250,000 was raised. The menu was a collaboration by Chef Marcus Samuelsson, together with Jamaica’s own Andre Fowles and Chef John Ramirez from Andrew Carmellini’s Kitchen at Joe’s Pub & The Library. New board member, Jamaican born Sean Legister gave a heartfelt speech about his connection to the Foundation and its work in Jamaica. Afterwards, Paul Issa, Chairman of the Issa Foundation gave a thank-you speech on behalf of the people of Jamaica. And to close the night Ky-Mani Marley bellowed his father’s great song “One Love,” a feeling that encapsulated the whole evening."
            src="https://res.cloudinary.com/dfwvu4gct/image/upload/v1665598720/RHF/RHF_570x429_3ndJoesPup_2019_px0w6k.jpg"
            hasBtn
            btnText="SEE MORE"
            btnUrl="https://www.rockhousefoundation.org/covid-19-update-for-negril-jamaica/"
          />
          <EventItemReverse
            title="RAFFLE DRAW & SPRING DINNER AT MISS LILY’S"
            date="May 2019 NYC"
            text1="Thanks to all those who so generously supported the Rockhouse Foundation Raffle and Spring Dinner. We raised over US$60,000! Every cent of every contribution goes towards our projects with all costs underwritten by Miss Lily’s, Rockhouse & Skylark Hotels. Special thanks to Rhy Waddington & Sam Whitford, Adam Schop and the Miss Lily’s Team, Tara Harrison and Max Glazer for the music and JetBlue, our longstanding sponsor."
            text2={"Congratulations to our Spring Raffle drawing winners!"}
            src="https://res.cloudinary.com/dfwvu4gct/image/upload/v1665598743/RHF/RHF_570x429_2ndRaffle_ecatz7.jpg"
          /> */}
        </div>
      </Pages>

      {/* <VideoBlueBG
        text1="Conducted every summer for the past 7 years, this 10 day program immerses teenagers as camp counselors at the Moreland Hill Summer School during the days, and exposes the group to community life in Western Jamaica through a series of programed activities. The group stays at Rockhouse and Skylark hotels. Participants work together with a team of local councillors and learn from their interactions with the subsistence farming community of Moreland Hill."
        title="SUMMER LEADERSHIP CAMP"
        url="https://www.youtube.com/embed/TyZchJ1N8YI"
        date="July 2019 Negril, Jamaica"
        variant="h5"
      /> */}
      {/* <Container>
        <EventItem
          title="CHEF NINA COMPTON AT SKYLARK"
          date="July 2018 Negril Jamaica"
          text1="On July 28th 2018 Skylark Negril Beach Resort and Chef Andre Fowles, hosted the St Lucian star chef Nina Compton for an intimate 5 course jerk themed dinner benefiting the Rockhouse Foundation. The James Beard Foundation recently named Compton it’s Best Chef: South and she was named one of Food & Wine’s “Best New Chefs” in 2017. The Benefit Dinner raised US$10,000 for the Rockhouse Foundation."
          src="https://res.cloudinary.com/dfwvu4gct/image/upload/v1665598792/RHF/RHF_570x429_NinaCompton_nuzjze.jpg"
        />
        <EventItemReverse
          title="24 HOUR TECHNOLOGY TURN DOWN"
          date="May 2018 NYC"
          text1="Turn “off” your technology and turn “on” technology for school children in Jamaica. The challenge: Turn off all forms of technology for 24 hours, i.e., no video or computer games, television, Snapchat, Facebook, Instagram, personal emails, phone calls or texting. And our amazing crew of teenagers rose to the challenge and raised over US$30,000. We did make an exception for legitimate work, school projects and emergency telephone use so we didn’t stop all momentum for the kids. Funds raised from the Turn Down supported the Rockhouse Foundation’s technology commitment to its partner schools, as well as Sav Inclusive School!"
          src="https://res.cloudinary.com/dfwvu4gct/image/upload/v1665598776/RHF/RHF_570x429_40HrTech_cuhxmp.jpg"
        />
      </Container> */}
    </>
  );
};

export default Events;

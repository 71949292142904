import { Box, Divider } from "@mui/material";
import React from "react";

const RHDivider = ({ variant = "middle" }) => {
  return (
    <Box className={` mx-auto my-3 ${variant === "middle" ? "max-w-2xl" : ""}`}>
      <Divider
        variant={variant}
        sx={{ backgroundColor: "#F281B1", color: "#F281B1", p: 0.2 }}
      />
    </Box>
  );
};

export default RHDivider;

export const CustomDivider = ({ color = "primary.main", mx = "auto" }) => {
  return (
    <Box
      className="max-w-xl mx-auto"
      padding={0.2}
      sx={{ backgroundColor: color, mx: mx, mt: 1, mb: 4 }}
    />
  );
};

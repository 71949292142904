import { Box, Typography } from "@mui/material";
import React from "react";

import InstagramDiv from "../components/features/InstagramDiv";

import { Link as MUILink } from "@mui/material";

import DonationBanner from "../components/features/DonationBanner";
import Seo from "../components/layout/Seo";
// import Raffle from "../components/Raffle";
import CardCallOuts from "../components/features/CardCallOuts";
import ImageTextRight from "../components/layout/ImageTextRight";
import ImageTextLeft from "../components/layout/ImageTextLeft";
import { Link } from "react-router-dom";
import FullwidthSection from "../components/features/FullwidthSection";

import { FullscreenYouTubeVideo } from "../components/elements/VideoDisplay";

const Home = () => {
  return (
    <Box>
      <Seo title="Home" />

      <FullscreenYouTubeVideo />
      <DonationBanner />
      <div className="grid grid-cols-1 mt-4">
        <ImageTextRight
          img="https://res.cloudinary.com/dfwvu4gct/image/upload/v1637844418/RHF/RHF_746x1440_OurMission_O_ijk8ir.jpg"
          content={
            <>
              <Typography
                variant="h1"
                component="h2"
                color="secondary"
                sx={{ textAlign: { md: "left", xs: "center" } }}
              >
                OUR MISSION
              </Typography>
              <Typography sx={{ textAlign: { md: "left", xs: "center" } }}>
                The{" "}
                <MUILink
                  underline="none"
                  color="primary"
                  href="rockhousefoundation.org"
                  sx={{
                    ":hover": {
                      color: "secondary.main",
                      fontWeight: "bold",
                    },
                  }}
                >
                  Rockhouse Foundation
                </MUILink>{" "}
                is dedicated to transforming the places where Jamaica's children
                learn and supporting the great people who teach them. Since its
                inception in 2004, the Foundation has invested over USD$10
                million building, expanding and renovating six Negril area
                public schools and the Negril Community Library, directly
                impacting the lives of thousands of young people and their
                families. The current primary project, depicted in the video
                above, is the Sav Inclusive school, built from the ground up by
                the Foundation.
              </Typography>
              <Typography sx={{ textAlign: { md: "left", xs: "center" } }}>
                All administrative and marketing costs of the foundation are
                underwritten by{" "}
                <MUILink
                  underline="none"
                  color="primary"
                  href="https://www.rockhouse.com"
                  sx={{
                    ":hover": {
                      color: "secondary.main",
                      fontWeight: "bold",
                      cursor: "pointer",
                    },
                  }}
                >
                  Rockhouse Hotel
                </MUILink>
                ,{" "}
                <MUILink
                  underline="none"
                  color="primary"
                  href="https://www.skylarknegril.com"
                  sx={{
                    ":hover": {
                      color: "secondary.main",
                      fontWeight: "bold",
                      cursor: "pointer",
                    },
                  }}
                >
                  Skylark Negril Beach Resort
                </MUILink>
                ,{" "}
                <MUILink
                  underline="none"
                  color="primary"
                  href="https://www.misslilys.com"
                  sx={{
                    ":hover": {
                      color: "secondary.main",
                      fontWeight: "bold",
                      cursor: "pointer",
                    },
                  }}
                >
                  Miss Lily's
                </MUILink>{" "}
                and their owners. All donations go directly to the projects and
                programs.
              </Typography>
            </>
          }
        />
        <ImageTextLeft
          img="https://res.cloudinary.com/dfwvu4gct/image/upload/v1637844419/RHF/RHF_746x1440_OurWork_NEW-1_lfdzso.jpg"
          content={
            <>
              <Typography
                variant="h1"
                component="h2"
                color="secondary"
                sx={{ textAlign: { md: "left", xs: "center" } }}
              >
                OUR WORK
              </Typography>
              <Typography sx={{ textAlign: { md: "left", xs: "center" } }}>
                The Foundation has transformed and modernized six schools
                throughout Western Jamaica: Moreland Hill Primary and Infant
                Schools, Negril All Age School, Negril Basic School, Little Bay
                All Age and Infant Schools, Bunch of Stars Early Childhood
                Institute and Savanna-la-Mar Inclusive Academy (Sav Inclusive),
                in addition to the complete renovation and expansion of the
                Negril Community Library. Currently, the Foundation’s primary
                focus is the expansion of the Sav Inclusive School, developing
                the Primary and High School campuses.
              </Typography>
              <Typography sx={{ textAlign: { md: "left", xs: "center" } }}>
                At the Sav Inclusive School, conceived and built from the ground
                up by the Rockhouse Foundation, children with and without
                disabilities are taught side-by-side in a full-inclusion
                setting, a one-of-a-kind school across all of Jamaica. In
                partnership with Jamaica’s Ministry of Education, the Rockhouse
                Foundation continues the multi-year expansion of Sav Inclusive’s
                primary campus, with construction of new grade classrooms each
                year, and are currently working on the primary school play space
                and the 6th grade classroom. This is all in anticipation of
                starting the high school expansion upon completion of the
                primary campus.
              </Typography>
              <Typography sx={{ textAlign: { md: "left", xs: "center" } }}>
                The Rockhouse Foundation creates transformative learning
                environments and opportunities for Jamaican children and their
                families.
              </Typography>
              <Link
                to="our-work"
                style={{
                  textDecoration: "none",
                }}
              >
                <Typography
                  sx={{
                    textAlign: { md: "left", xs: "center" },
                    cursor: "pointer",
                    color: "primary.main",
                    fontWeight: "bold",
                    "&:hover": {
                      color: "scondary.main",
                    },
                  }}
                >
                  {" "}
                  See more of Our Work here.
                </Typography>
              </Link>
            </>
          }
        />
        <ImageTextRight
          img="/eventshp.jpeg"
          content={
            <>
              <Typography
                variant="h1"
                component="h2"
                color="secondary"
                sx={{ textAlign: { md: "left", xs: "center" } }}
              >
                EVENTS & FUNDRAISING
              </Typography>
              <Typography sx={{ textAlign: { md: "left", xs: "center" } }}>
                Throughout the year the Rockhouse Foundation holds a series of
                virtual and in real life events and fundraisers including
                special chef driven dinners in Jamaica, the annual raffle, and
                our major annual fundraiser in New York at the Public Theater,
                usually in October or November.
              </Typography>
              <Typography sx={{ textAlign: { md: "left", xs: "center" } }}>
                The annual fundraiser in New York is the cornerstone of the
                fundraising calendar and features updates on the foundation’s
                work in Jamaica, an incredible seated dinner and a live reggae
                music performance. Past performances have included Shaggy, Sean
                Paul, Third World, Toots and the Maytals, Barrington Levy,
                Tarrus Riley, Tonya Stevens, Gyptian, Marcia Griffiths, Kymani
                Marley, Wayne Wonder and many more. With Rockhouse, Skylark and
                Miss Lily's paying all the event's expenses, every dollar
                donated on the evening goes directly to our projects and
                programs
              </Typography>

              <Link
                to="events"
                style={{
                  textDecoration: "none",
                }}
              >
                <Typography
                  sx={{
                    textAlign: { md: "left", xs: "center" },
                    cursor: "pointer",
                    color: "primary.main",
                    fontWeight: "bold",
                    "&:hover": {
                      color: "scondary.main",
                    },
                  }}
                >
                  {" "}
                  See more on our Events page here.
                </Typography>
              </Link>
            </>
          }
        />
      </div>

      <div className="grid md:grid-cols-3 grid-cols-1 md:gap-10 gap-4 my-4">
        <CardCallOuts
          title="MARCUS SAMUELSSON"
          subtitle="Six-Time James Beard Award Winning Chef"
          text="“I am a huge fan of what the whole Rockhouse family has put together with the inclusion of the community. I love the Foundation, I love its mission, I love the inclusive environment.”"
          url="https://www.youtube.com/embed/QfWRy0d6lxk"
        />
        <CardCallOuts
          title="SEAN PAUL"
          url="https://www.youtube.com/embed/dA6ivFQb4_A"
          subtitle="Grammy Award Winning Reggae Artist"
          text="“Bigging up the Rockhouse Foundation, respect due to you for true for all the things you are doing in Western Jamaica for the kids… Uplift!” "
        />
        <CardCallOuts
          title="RONALD THWAITES"
          url="https://www.youtube.com/embed/uF8yvuD7DWo"
          subtitle="Jamaica’s Former Minster of Education"
          text="“Rockhouse Foundation… thank-you for your friendship and generosity…thousands are being uplifted through your support”"
        />
      </div>

      <Box className="relative">
        <FullwidthSection
          title="OUR BLOG"
          img="https://res.cloudinary.com/dfwvu4gct/image/upload/v1637844419/RHF/RHF_Feature_1024x439_Landing_OurBlog_NEW-1_mjpsxm.jpg"
          link="/blog"
          linkText="Read Our Blog"
          text="Our team puts together a series of blog posts updating you on developments with the Foundation including the latest on the projects, reports on our events, and information around how you can get involved."
          subtitle="LATEST NEWS ON THE ROCKHOUSE FOUNDATION"
          floating={false}
          imgMobile="https://res.cloudinary.com/dfwvu4gct/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1637844419/RHF/RHF_Feature_1024x439_Landing_OurBlog_NEW-1_mjpsxm.jpg"
        />

        <FullwidthSection
          subtitle="2022-23 Annual Report"
          img="https://res.cloudinary.com/dfwvu4gct/image/upload/v1637844419/RHF/RHF_Feature_1024x439_FINANCIALS-1_pob5xs.jpg"
          link="https://www.rockhousefoundation.org/RHF_Annual_Report_2023_Final.pdf"
          linkText="Read Our Financials"
          text="In 2023 the Rockhouse Foundation celebrated its 19th year of transforming learning environments in and around Negril Jamaica and continued its support for the great men and women who teach the next generation."
          title="FINANCIALS"
          floating={true}
          external
          imgMobile="https://res.cloudinary.com/dfwvu4gct/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1637844419/RHF/RHF_Feature_1024x439_FINANCIALS-1_pob5xs.jpg"
        />
      </Box>
      <Box>
        <InstagramDiv />
      </Box>
      <Box className="grid md:grid-cols-2 grid-cols-1 md:gap-4 my-4">
        <Box className="px-4 md:p-6">
          <Typography variant="h2" color="secondary" component="h2">
            MORE WAYS TO SUPPORT
          </Typography>
          <Typography variant="body1">
            There are a number of ways to get involved:
          </Typography>
          <Typography variant="body1">
            <MUILink
              className="font-bold"
              href="https://rockhousefoundation.networkforgood.com/projects/95296-rockhouse-foundation"
              target="_blank"
              sx={{
                ":hover": {
                  color: "secondary.main",
                },
              }}
            >
              Donate
            </MUILink>{" "}
            Today through our secure server.
          </Typography>
          <Typography variant="body1">
            <MUILink
              className="font-bold"
              href="/RHF_WishList_091624.pdf"
              target="_blank"
              sx={{
                ":hover": {
                  color: "secondary.main",
                },
              }}
            >
              General School Wish List
            </MUILink>{" "}
            On your next trip to Negril, pack with the children in mind and
            bring some school supplies with you.
          </Typography>
          <Typography variant="body1">
            <MUILink
              className="font-bold"
              href="https://smile.amazon.com/?ref_=smi_ge_switch_cust"
              target="_blank"
              sx={{
                ":hover": {
                  color: "secondary.main",
                },
              }}
            >
              Amazon Smile
            </MUILink>{" "}
            While shopping at Amazon, set up Rockhouse Foundation as your
            charity and they will donate 0.5% of your purchases to the Rockhouse
            Foundation.
          </Typography>
          <Typography variant="body1">
            <MUILink
              className="font-bold"
              href="mailto:info@rockhousefoundation.com"
              target="_blank"
              sx={{
                ":hover": {
                  color: "secondary.main",
                },
              }}
            >
              Visit us
            </MUILink>{" "}
            while in Negril. Join the Rockhouse Foundation tour, with buses
            departing from Rockhouse Hotel & Spa on the cliffs and Skylark
            Negril Beach Resort. To confirm times, please contact us{" "}
            <MUILink
              className="font-bold"
              href="mailto:info@rockhousefoundation.com"
              target="_blank"
              sx={{
                ":hover": {
                  color: "secondary.main",
                },
              }}
            >
              info@rockhousefoundation.com
            </MUILink>
            .
          </Typography>
          <Typography variant="body1">
            <MUILink
              className="font-bold"
              href="/IRS-Approval-Letter-Oct-22-2008_lpFb4Yt_DHQAqRF.pdf"
              target="_blank"
              sx={{
                ":hover": {
                  color: "secondary.main",
                },
              }}
            >
              IRS approved 501(c)3
            </MUILink>{" "}
            Rockhouse Foundation is a New York based charity focused on
            education in Jamaica. Rockhouse Foundation is also a Jamaican
            Government approved charity.
          </Typography>
        </Box>
        <Box>
          <img
            alt=""
            className="object-cover w-full"
            loading="lazy"
            src="https://res.cloudinary.com/dfwvu4gct/image/upload/v1637844419/RHF/Visitor_864x564_vnzuu8.jpg"
          />
        </Box>
      </Box>
      {/* <Raffle /> */}
    </Box>
  );
};

export default Home;

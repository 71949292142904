import { Box, Typography } from "@mui/material";

import React from "react";
import ButtonExternal from "../../components/features/ButtonExternal";

import PageCallouts from "../../components/PageCallouts";
import BootstrapCarousel from "../../components/layout/BootstrapCarousel";

const Covid = () => {
  return (
    <div>
      <BootstrapCarousel
        images={[
          {
            src: "https://res.cloudinary.com/dfwvu4gct/image/upload/v1665924910/RHF/RHF_BlogHeader_Covid_g1x6aa.jpg",
            name: "rockhouse hotel covid update",
          },
        ]}
      />

      <div className="container my-4">
        <Box className="grid grid-cols-1 md:grid-cols-4 gap-7">
          <Box className="md:col-span-3">
            <Typography variant="h1" color="secondary" className="mb-2">
              COVID-19 UPDATE
            </Typography>
            {/* Link is broken */}
            <ButtonExternal
              variant="contained"
              href="https://www.gofundme.com/"
            >
              Donate to covid-19 relief in western jamaica
            </ButtonExternal>
            <Typography variant="body1">
              When the border closed in Jamaica in March 2020, overnight
              hundreds of hotels and tourist destinations closed indefinitely
              leaving thousands of employees abruptly and mercilessly without a
              livelihood. Hospitality, along with remittances, sustains
              Jamaica’s chronically fragile economy, and overnight both declined
              dramatically.
            </Typography>

            <Typography variant="body1">
              Schools closed at the same time as the borders, instantly creating
              further hardship and chaos for families who, at that time, mostly
              saw the pandemic as a distant and mysterious threat. In Jamaica,
              like most places globally, the knock-on effects of the school
              closures were devastating, leaving parents scrambling for daycare
              (for the few who remain employed), and depriving them of a
              critical, daily source of nutrition for their children.
            </Typography>

            <Typography variant="body1">
              Rockhouse Foundation was scrambling too. With schools closed,
              day-to-day activities supporting our partners were involuntarily
              and indefinitely suspended. It was immediately evident that we
              were needed more than ever, and our Covid Relief initiative took
              shape within just a few days. Initially, the Foundation organized
              a distribution of food staples leftover in the Sav Inclusive
              kitchen, but we understood the urgency of establishing a
              sustainable, significant food distribution initiative at each of
              our partner schools.
            </Typography>
            <Box className="my-2">
              <img
                src="https://res.cloudinary.com/dfwvu4gct/image/upload/v1665927332/RHF/Covid-19_Event_A_t5fvtt.jpg"
                alt="Rockhouse Foundation"
                className="object-contain w-full mx-auto"
              />
            </Box>
            <Typography variant="body1">
              The Rockhouse Foundation turned to our network of generous and
              loyal donors, creating a Covid Relief Fund and raising over
              US$100,000 within a matter of weeks, supported by a live online
              benefit concert featuring long term supporters Chronixx and
              Shaggy. We began distributing bi-weekly food staples to our
              schools’ most vulnerable families with packages that included 10
              lbs flour, 10 lbs rice, 3 lbs sugar, cans of tin mackerel, cooking
              oil, cornmeal and a fresh chicken. The distribution are made
              through the Foundation’s partner school network: Sav Inclusive
              School, Moreland Hill Primary School, Little Bay All Age and
              Infant School, and the Bunch of Stars ECI.{" "}
            </Typography>
            <Box className="my-2">
              <img
                src="https://res.cloudinary.com/dfwvu4gct/image/upload/v1665928229/RHF/Covid-19_Event_C_xbaefq.jpg"
                alt="Rockhouse Foundation"
                className="object-contain w-full mx-auto"
              />
            </Box>
            <Typography variant="body1">
              With our annual fundraising campaign at the end of 2020 we
              extended the amount raised for this important cause and doubled
              the number of schools receiving distributions, adding Green Island
              Primary, St Paul’s Primary, Broughton Primary and Revival All Age
              School. This expansion has been funded in part from a generous
              donation from the TUI Cares Foundation.
            </Typography>
            <Typography variant="body1">
              To date, Rockhouse Foundation has distributed over 200 tons of
              food staples in a program that has been going on continuously
              since March 2020.{" "}
            </Typography>
            <ButtonExternal
              variant="contained"
              href="https://www.gofundme.com/"
            >
              Donate to covid-19 relief in western jamaica
            </ButtonExternal>
          </Box>
          <Box>
            <PageCallouts />
          </Box>
        </Box>
      </div>
    </div>
  );
};

export default Covid;

import { Typography } from "@mui/material";
import React from "react";
import { CustomDivider } from "./RHDivider";

const StyledTitle = ({ title, variant = "middle", align = "center" }) => {
  return (
    <div className="my-2">
      <Typography variant="h1" component="h2" color="secondary" align={align}>
        {title}
      </Typography>
      <div className="w-[200px] md:w-[500px] mx-auto">
        <CustomDivider />
      </div>
    </div>
  );
};

export default StyledTitle;

import React from "react";

function ImageTextRight({ title, img, url, content, video = false }) {
  return (
    <div className=" grid w-screen grid-cols-1 md:gap-4 gap-0 md:grid-cols-2">
      <div className="align-center flex flex-col justify-center px-10">
        {content}
      </div>
      <div className="text-left">
        {video ? (
          <iframe src={url} height={300} />
        ) : (
          <img src={img} alt={title} />
        )}
      </div>
    </div>
  );
}

export default ImageTextRight;

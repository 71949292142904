import { Card, CardContent, CardMedia, Typography } from "@mui/material";
import React from "react";

function CardCallOuts({ title, subtitle, text, url, br }) {
  return (
    <Card
      sx={{
        backgroundColor: "#F5E7D8",
        color: "#866957",
      }}
    >
      <CardMedia component="iframe" src={url} height={300} />
      <CardContent>
        <Typography align="center" variant="h3">
          {title}
        </Typography>

        <Typography align="center" color="#866957">
          {subtitle}
        </Typography>
        <Typography
          align="center"
          variant="body1"
          fontWeight={700}
          color="#866957"
        >
          {text}
        </Typography>
      </CardContent>
    </Card>
  );
}

export default CardCallOuts;

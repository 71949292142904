import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "@mui/material";

const PageCallout = ({ src, page, link, external = false }) => {
  let navigation = useNavigate();
  return (
    <Stack alignItems="center w-full">
      {external ? (
        <Link href={link}>
          <Box className="relative mx-auto  text-center">
            <img src={src} alt="" className="object-cover w-full" />

            <Box
              className=" opacity-75 absolute w-full bottom-0 cursor-pointer hover:[#009FDE]"
              sx={{
                bgcolor: "primary.main",
                "& :hover": {
                  bgcolor: "secondary.main",
                },
              }}
            >
              <Typography
                variant="h5"
                sx={{ color: "#fff", textTransform: "uppercase", py: 1 }}
                align="center"
              >
                {page}
              </Typography>
            </Box>
          </Box>
        </Link>
      ) : (
        <Link onClick={() => navigation(link, { replace: true })}>
          <Box className="relative mx-auto">
            <img src={src} alt="" className="object-cover mx-auto w-full" />

            <Box
              className=" opacity-75 absolute w-full bottom-0 cursor-pointer"
              sx={{
                bgcolor: "primary.main",

                "& :hover": {
                  bgcolor: "secondary.main",
                },
              }}
            >
              <Typography
                variant="h5"
                sx={{ color: "#fff", textTransform: "uppercase", pb: 0, py: 1 }}
                align="center"
              >
                {page}
              </Typography>
            </Box>
          </Box>
        </Link>
      )}
    </Stack>
  );
};

export default PageCallout;

import React from "react";
import { motion } from "framer-motion";

const ImageModal = ({ setSelectedImg, selectedImg }) => {
  const handleClick = (e) => {
    if (e.target.classList.contains("backdrop")) {
      setSelectedImg(null);
    }
    setSelectedImg(null);
  };

  return (
    <div className="relative">
      <motion.div
        className="backdrop fixed top-0 left-0 w-full h-full bg-[rgba(0,0,0,0.5)] z-50 flex items-center justify-center"
        onClick={handleClick}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
      >
        {/* <Close
          onClick={handleClick}
          className="cursor-pointer z-50 text-gray-50 self-start"
        /> */}
        <motion.img
          className="object-contain md:max-w-[700px] max-w-[90vw] w-full h-auto  z-50 "
          src={selectedImg}
          alt="enlarged pic"
          // initial={{ y: '-100vh' }}
          // animate={{ y: 0 }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        />
      </motion.div>
    </div>
  );
};

export default ImageModal;

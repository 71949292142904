import { Box, Typography } from "@mui/material";
import React from "react";
import VideoPlayer from "../../components/layout/VideoPlayer";
import PageCallouts from "../../components/PageCallouts";
import BootstrapCarousel from "../../components/layout/BootstrapCarousel";

const SavInclusive = () => {
  return (
    <div>
      <BootstrapCarousel
        images={[
          {
            src: "https://res.cloudinary.com/dfwvu4gct/image/upload/v1665928779/RHF/RHF_BlogHeader_SAV-Physio_oc2dus.jpg",
            name: "PHYSIOTHERAPY AT SAV INCLUSIVE - Rockhouse Foundation",
          },
        ]}
      />
      <div className="container my-4">
        <Box className="grid grid-cols-1 md:grid-cols-4 gap-7">
          <Box className="md:col-span-3">
            <Typography variant="h4" color="secondary" className="mb-2">
              PHYSIOTHERAPY AT SAV INCLUSIVE
            </Typography>

            <Typography variant="body1">
              What do you think of when you hear the words: ONE LOVE?
            </Typography>

            <Typography variant="body1">
              The Rockhouse Foundation thinks of Jernenio taking his first steps
              at our newly opened Sav Inclusive School.
            </Typography>

            <Typography variant="body1">
              Jernenio was affectionately nicknamed Tumblebug when he first came
              to Sav Inclusive. His Cerebral Palsy meant he fell every few
              steps, although this never dampened his spirits. After a few
              months of therapy, he is able to walk by himself. Now he only
              falls when he is executing daredevil moves on the playground and
              his teacher’s have nicknamed him the next Usain Bolt.
            </Typography>
            <Box className="my-3">
              <VideoPlayer url="https://www.youtube.com/embed/Q4Tcji-Bu6w" />
            </Box>
            <Typography variant="body1">
              Before Arianna came to Sav Inclusive, she couldn’t walk. She was
              diagnosed with Spina Bifida and a clubfoot deformity that
              prevented her from standing up straight. During her time at Sav
              Inclusive she graduated from a wheelchair to a walker and a few
              months ago she took her own steps. Now she can dance and play with
              all the other students.
            </Typography>
            <Box className="my-3">
              <VideoPlayer url="https://www.youtube.com/embed/SvPNlj7zdqA" />
            </Box>
            <Typography variant="body1">
              These success stories are thanks to Halleah Addiman, physical
              therapist and part-time miracle worker at Sav Inclusive. Halleah’s
              warm nature and bright smile lights up a room like a Negril
              sunset.
            </Typography>
            <Typography variant="body1">
              Halleah initially aspired to be a doctor but after a stint on the
              Physical therapy dept. she was hooked. She realized she could help
              the most when she spent more time with her patients. That’s when
              she moved into physical therapy and hasn’t looked back since.
            </Typography>
            <Typography>
              When she first started her career, Halleah felt discouraged by the
              hard circumstances that physically challenged children typically
              experience in Jamaica. She decided to work with adults instead,
              but then the opportunity at Sav Inclusive came along, and the
              lives of her children at Sav Inclusive will never be the same.
            </Typography>
            <Typography>
              Halleah has seen first-hand how the Foundation has impacted lives.
              “The Foundation has been the answer to many parents’ prayers,” she
              says. “Many of our children are not privy to experience love, care
              and value like others but Rockhouse has really depicted ONE LOVE
              in so many ways to these students.”
            </Typography>
            <Typography>
              Halleah believes that the students will never forget what has been
              done for them at Sav Inclusive. “I believe many of the students
              will grow to remember the contribution made by the Foundation and
              realize what was given to them at a pivotal time in their lives.”
            </Typography>
            <Typography>
              You see, here at the Foundation we really do believe in ONE LOVE.
              It’s what inspires us to keep improving, so that more Jernenio and
              Arianna’s can walk, and then run into their bright futures.
            </Typography>
          </Box>
          <Box>
            <PageCallouts />
          </Box>
        </Box>
      </div>
    </div>
  );
};

export default SavInclusive;

import React from "react";

import { Link, Typography } from "@mui/material";

function CustomMUILink({ url, children }) {
  return (
    <Link
      to={url}
      color="primary"
        sx={{
          textAlign: { md: "left", xs: "center" },
          cursor: "pointer",
          color: "primary.main",
          fontWeight: "bold",
          "&:hover": {
            color: "secondary.main",
          },
        }}
        target="_blank"

    >
    {children}
    </Link>
  );
}

export default CustomMUILink;

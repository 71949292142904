import { Box, Typography } from "@mui/material";
import React from "react";
import { Link as MUILink } from "@mui/material";
import { Link } from "react-router-dom";
// import H3Text from '../text/H3Text'
// import Title from '../text/Title'

export default function FullwidthSection({
  img,
  title,
  text,
  subtitle,
  link,
  linkText,
  floating,
  imgMobile,
  external = false,
}) {
  const dir = floating;
  return (
    <div className="relative my-4 w-screen">
      {/* Absolute img on desktop */}
      <Box
        sx={{
          textAlign: { md: "left", xs: "center" },
        }}
        className={`${
          floating && "right-0"
        } text-white z-20 flex w-full flex-col justify-center bg-[#009FDE] p-5  lg:absolute lg:inset-y-1/2 lg:h-2/4 lg:max-w-2xl lg:-translate-y-1/2`}
      >
        {/* <H3Text color="white" center>
          {subtitle}
        </H3Text> 
        sm:absolute sm:inset-y-1/2 sm:h-3/4 sm:max-w-xl sm:-translate-y-1/2
        */}
        <Typography
          variant="h3"
          sx={{
            color: "#fff",
            fontSize: "24px",
          }}
          textAlign={{ xs: "center", md: "left" }}
        >
          {subtitle}
        </Typography>
        <Typography
          variant="h1"
          sx={{
            color: "white",
          }}
        >
          {title}
        </Typography>

        <p className="mb-3">{text}</p>
        {external ? (
          <MUILink
            href={link}
            sx={{
              color: "white",
              textDecoration: "underline",
              cursor: "pointer",
              textTransform: "capitalize",
              "&:hover": {
                color: "#36383F",
                textDecoration: "underline",
              },
            }}
            underline="none"
          >
            <Typography
              variant="h4"
              sx={{
                fontWeight: "bold",
                color: "white",
                textDecoration: "underline",
                cursor: "pointer",
                textTransform: "capitalize",
              }}
            >
              {linkText}
            </Typography>
          </MUILink>
        ) : (
          <Link to={link}>
            <Typography
              variant="h4"
              sx={{
                color: "white",
                textDecoration: "underline",
                cursor: "pointer",
                textTransform: "capitalize",
                fontWeight: "bold",
                "&:hover": {
                  color: "#36383F",
                  textDecoration: "underline",
                },
              }}
            >
              {linkText}
            </Typography>
          </Link>
        )}
      </Box>
      {/* BG image */}

      <img className="z-10 hidden w-full lg:block" src={img} alt={title} />

      <img
        className="z-10 block w-full lg:hidden"
        src={imgMobile}
        alt={title}
      />
    </div>
  );
}

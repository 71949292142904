import { Box, Button, Container, Typography } from "@mui/material";
import React from "react";

const DonationBanner = () => {
  return (
    <Box
      sx={{
        bgcolor: "secondary.main",
      }}
    >
      <Container>
        <Box
          sx={{
            display: { md: "flex", xs: "block" },
            justifyContent: "center",
            mx: "auto",
          }}
          className="p-6 space-y-2 md:space-y-0 py-4"
        >
          <Typography
            component="h1"
            variant="h2"
            sx={{
              textTransform: "uppercase",
              color: "#fff",
              fontSize: { xs: "23px", md: "38px" },
              paddingBottom: { xs: "10px", md: "0px" },
            }}
            align="center"
          >
            Make a Monthly Donation
          </Typography>
          <Box className="grid grid-cols-3 md:gap-10 gap-6 max-w-md  mx-auto">
            <Button
              variant="contained"
              disableElevation
              sx={{
                color: "#fff",
                fontWeight: 700,

                fontSize: 20,
                mx: "auto",
                cursor: "pointer",
                "&:hover": {
                  color: "#fff",
                },
                width: "50px",
              }}
              href="https://rockhousefoundation.networkforgood.com/projects/98358-monthly-donations-10"
            >
              $10
            </Button>
            <Button
              variant="contained"
              disableElevation
              sx={{
                color: "#fff",
                fontWeight: 700,
                fontSize: 20,
                mx: "auto",
                cursor: "pointer",

                "&:hover": {
                  color: "#fff",
                },
                width: "50px",
              }}
              href="https://rockhousefoundation.networkforgood.com/projects/98356-monthly-donations-25"
            >
              $25
            </Button>
            <Button
              variant="contained"
              disableElevation
              sx={{
                color: "#fff",
                fontWeight: 700,
                fontSize: 20,
                mx: "auto",
                cursor: "pointer",

                "&:hover": {
                  color: "#fff",
                },
                width: "50px",
              }}
              href="https://rockhousefoundation.networkforgood.com/projects/98354-monthly-donations"
            >
              $100
            </Button>

            {/* buttons */}
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default DonationBanner;

import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import Pages from '../../components/features/Pages';
import Seo from '../../components/layout/Seo';

const Financials = () => {
	return (
		<div>
			<Seo title="Financials" />
			<Pages
				title="FINANCIALS"
				imageUrl="https://res.cloudinary.com/dfwvu4gct/image/upload/v1665599034/RHF/RHF_FINANCIALS_1920x823_O_olcdtg.jpg"
			>
				<Box className="max-w-4xl mx-auto my-4">
					<Typography variant="body1">
						We know it's important to you that your donation is being used
						effectively. At Rockhouse Foundation we commit that every donated
						dollar goes directly to our projects and programs. All
						administrative and fundraising expenses are underwritten by
						Rockhouse, Skylark, Miss Lily's and their owners.
					</Typography>
				</Box>
				<Box className="grid md:grid-cols-2 grid-cols-1 md:gap-8 gap-4 my-8">
					<img
						src="https://firebasestorage.googleapis.com/v0/b/skylark-f6267.appspot.com/o/rhf%2FRHF_Annual%20Report%202023_Final.jpg?alt=media&token=8b4242ce-805a-44c9-8cdc-bb5330af3ebb"
						alt=""
						loading="lazy"
						className="object-contain w-full"
					/>
					<Box>
						<Typography variant="h2" component="h2" color="secondary">
							ANNUAL REPORT
						</Typography>
						<Typography variant="body1" fontWeight={700}>
							2023
						</Typography>
						<Typography variant="body1">
							In 2023 the Rockhouse Foundation celebrated its 19th year of
							transforming learning environments in and around Negril Jamaica
							and continued its support for the great people who teach the next
							generation. The work of the Foundation was primarily focussed on
							the continuing the expansion and development of the Sav Inclusive
							School where children with disabilities and without sit side by
							side in a full inclusion setting.
						</Typography>
						<Box className="py-4 flex flex-col justify-center space-y-4 items-center">
							<Button
								variant="contained"
								sx={{
									color: '#fff',
									fontWeight: 'bold',
									fontFamily: 'Helvetica',
									borderRadius: '25px',

									'&:hover': {
										color: '#fff',
									},
								}}
								href="/RHF_Annual_Report_2023_Final.pdf"
								disableElevation
							>
								2023 Annual Report
							</Button>

							<Button
								variant="contained"
								sx={{
									color: '#fff',
									borderRadius: '25px',

									fontWeight: 'bold',
									fontFamily: 'Helvetica',
									'&:hover': {
										color: '#fff',
									},
								}}
								disableElevation
								href="/Rockhouse_Auditors_Review_Report_2023.pdf"
							>
								2023 Auditor's review
							</Button>

							<Button
								variant="contained"
								sx={{
									color: '#fff',
									borderRadius: '25px',

									fontWeight: 'bold',
									fontFamily: 'Helvetica',
									'&:hover': {
										color: '#fff',
									},
								}}
								disableElevation
								href="/ROCKHOUSE_FOUNDATION_FORM_990_FISCAL_YR 2023_ENDING.pdf"
							>
								2022-2023 form 990
							</Button>
						</Box>
					</Box>
				</Box>
			</Pages>
		</div>
	);
};

export default Financials;

import { Box, Container, Typography } from "@mui/material";
import React from "react";
import VideoPlayer from "../layout/VideoPlayer";

const VideoBlueBG = ({
  url,
  title,
  text1,
  text2 = "",
  color = "secondary.main",
  custom = false,
  subtitle = "",
  variant = "h2",
}) => {
  return (
    <Box sx={{ bgcolor: color, py: 4 }} className="mb-4">
      <Container className="space-y-4">
        <VideoPlayer url={url} />
        <Box>
          <Typography
            variant="h2"
            sx={{ color: custom ? "#866957" : "#fff" }}
            align="center"
          >
            {title}
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{ color: custom ? "#866957" : "#fff" }}
            align="center"
          >
            {subtitle}
          </Typography>
        </Box>
        <Typography variant="body1" sx={{ color: custom ? "#866957" : "#fff" }}>
          {text1}
        </Typography>
        <Typography variant="body1" sx={{ color: custom ? "#866957" : "#fff" }}>
          {text2}
        </Typography>
      </Container>
    </Box>
  );
};

export default VideoBlueBG;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../components/api/axiosApi";

const initialState = {
  events: [],
  status: "idle",
  error: null,
  previousEvents: [],
  currentEvents: [],
};

export const fetchEvents = createAsyncThunk("events/fetchEvents", async () => {
  try {
    const response = await api.get(
      `${process.env.REACT_APP_API_URL}/rhf/events`
    );

    const data = await response.data;
    console.log(data);

    return data;
  } catch (error) {
    console.log(error);
  }
});

export const eventSlice = createSlice({
  name: "events",
  initialState,
  reducers: {
    getPreviousEvents: (state) => {
      state.status = "loading";
      const previousEvents = state.events.filter(
        (event) => event.category === "Previous Events"
      );
      state.status = "succeeded";

      return previousEvents;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchEvents.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(fetchEvents.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.events = action.payload;
      // state.previousEvents =
    });
    builder.addCase(fetchEvents.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    });
  },
});

export const { getPreviousEvents } = eventSlice.actions;
export default eventSlice.reducer;

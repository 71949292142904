import { Box, Link, Typography } from "@mui/material";
import React from "react";
import Pages from "../components/features/Pages";
import SupportersList from "../components/features/SupportersList";
import Seo from "../components/layout/Seo";

const Supporters = () => {
  return (
    <>
      <Seo title="Supporters" />
      {/* onClick={() =>
                    setSelectedImg(
                      "https://res.cloudinary.com/dfwvu4gct/image/upload/v1665846005/RHF/unnamed-1_qft3sw.jpg"
                    )
                  } */}

      <Pages
        imageUrl="https://res.cloudinary.com/dfwvu4gct/image/upload/v1665774967/RHF/RHF_Supporters_1920x823_J_mmcenb.jpg"
        title="SUPPORTERS"
      >
        <Typography className="max-w-2xl mx-auto mb-4" variant="body1">
          Thank you to our many loyal donors who have so generously funded the
          work of the Foundation since 2003. All our projects and programs are
          only possible because of your incredible support.
        </Typography>
        <Box className="space-y-8">
          <SupportersList
            title="VISIONARY CIRCLE $20,000 AND ABOVE"
            list1={[
              "Berkowitz, Joshua and Juliet",
              "Bohart, Stuart & Elizabeth",
              "Caribtours Limited",
              "Cobey, Becky & Munroe",
              "Freedman, Lauri, Doug & Family",
              "Hatfield, Jay D",
              "Hollingsworth, Frederick",
              "Ippoliti, Fab & Dr Lynn Burmeister",
              "Island Gigs",
              "Jacob, Sam, Lara & Family",
              "Jamaican Ministry of Education",
              "JetBlue",
              "Joe’s PUB LLC",
              "Kelley Drye & Warren LLP",
              "Lapiner, Sven",
            ]}
            list2={[
              "Legister, Sean",
              "Miss Lily’s",
              "Mitchell, Ruth, Byron & Family",
              "Monaco Gordon, Julie",
              "Morgan, Leah",
              "Mulholland LP, Kathryn",
              "NoHo Hospitality Group",
              "O’Brien, Robert",
              "Petrilli, Christopher",
              "Picard, Peter & Family",
              "Pincus, Ana, Henry & Family",
              "The Public Theater",
              "Rockhouse Hotel & Spa",
              "Rose, Peter & Jocelyn",
              "Salmon, Aisha, Paul & Family",
            ]}
            list3={[
              "Samuelsson, Marcus & Maya",
              "Savage-Lepore, Nanette & Bob",
              "Scutt, Peter, Nadia & Family",
              "Skylark Negril Beach Resort",
              "Slattery-Balsam, Talia & John",
              "Stewart, Melissa & Tim",
              "T Edward Wines",
              "Tazza, Phil & Roselle",
              "Terrasi, Dan",
              "The Small Dark Room Inc",
              "Tudor Investment Corp.",
              "UNICEF",
              "USAID",
              "Vineyard Venue",
              "Waddington, Rhy & Sam",
              "Whitman, Beth and Brad",
            ]}
            img1="https://res.cloudinary.com/dfwvu4gct/image/upload/v1665774967/RHF/RHF_Supporters_510x384_N_aqoqqv.jpg"
            img2="https://res.cloudinary.com/dfwvu4gct/image/upload/v1665774967/RHF/RHF_Supporters_510x384_B_f1omor.jpg"
            img3="https://res.cloudinary.com/dfwvu4gct/image/upload/v1665774967/RHF/RHF_Supporters_510x384_L_g1tv2s.jpg"
          />
          <SupportersList
            title="LEADERSHIP CIRCLE $10,000 – $20,000"
            list1={[
              "Annenberg Foundation",
              "Asmodeo, Ellen",
              "Aug, James",
              "Ben-Dov, Chana",
              "Bisbee, Jackie & Sam",
              "Canela Investments",
              "Cobey Simonton, Elizabeth",
              "Coleman, Tracey",
              "de Chabert, Gisele",
              "DelVecchio, Joseph",
              "Dolan, Marty",
              "Gaton, Michele",
              "Goldman Sachs",
            ]}
            list2={[
              "Guttman, Ronald & Amy",
              "Hall, Michael",
              "Hart, John & Kathryn",
              "Hennes, Duncan & Laura",
              "Jacob, Michael",
              "Haley, Brandon & Anna Flett",
              "Kavanagh, Kevin & Amanda",
              "Lera Jewels",
              "Litwack, Alan",
              "Nagle, Robert & Jane",
              "Palikuca, Christian",
              "Palmer, Jeremy & Raquel",
              "Pickard, Josh",
            ]}
            list3={[
              "Pierson, Clare",
              "Power, Timothy",
              "Rattray, Timothy",
              "Rex Foundation",
              "Rockefeller Philanthropy Advisors",
              "Roslyn, Alison & Scott",
              "Salmon, Damian",
              "Schwartzberg, Harris",
              "Soros Charitable Fund",
              "Stephenson, Leroi",
              "Walji, Nadeem and Urvi Dalal",
              "David Gibbons Walsh Foundation",
              "Walsh, Christopher",
            ]}
            img1="https://res.cloudinary.com/dfwvu4gct/image/upload/v1665774967/RHF/RHF_Supporters_510x384_D_qedjeg.jpg"
            img2="https://res.cloudinary.com/dfwvu4gct/image/upload/v1665774967/RHF/RHF_Supporters_510x384_E_seledt.jpg"
            img3="https://res.cloudinary.com/dfwvu4gct/image/upload/v1665774967/RHF/RHF_Supporters_510x384_F_bro7m7.jpg"
          />
          <SupportersList
            title="PATRON CIRCLE $5,000 – $10,000"
            list1={[
              "Archer, Carol",
              "Artinian, Garen",
              "Balfour, Charlene",
              "Barnes, Stephon",
              "Barr, Thomas",
              "Berke, Alison & Barry",
              "Bernardi, Theresa S",
              "Bishop, Cat",
              "Blacklow, Kimberly",
              "Bloomberg",
              "Breen, Sally & Dan",
              "Burns, Colin",
              "Carlin, Richard & Michelle",
              "Casey, Cathy",
              "Chan, Ashley Francis",
              "Christensen, Lars",
              "Cunningham, Jason",
              "Dioguardi, Sarah",
              "Doyle, Peter B.",
              "Fairfield Theater Company",
            ]}
            list2={[
              "Ferrara, Laura",
              "Frisch, Patricia",
              "Fuchs, Michael",
              "Garban, Donna",
              "Garfield, Janet",
              "Haratunian, Stephen & Arda",
              "Hoang, Chrystal & Scharf, Justin",
              "Kahn Family Foundation",
              "Kerr, Patricia & Tim",
              "Kim, Lisa",
              "Kimball, Adrienne",
              "Knecht, Valerie & Fred",
              "Korb, Susie  & Joe McInerny",
              "Kuzbari, Karim",
              "Langham, Andrew",
              "Larson, Matthew & Alicia",
              "Ledva, Daniel",
              "Lee, Keun-Sam & Eun-Soon",
              "Levinson, Leslie & Allen",
              "Little, Paul & Jane",
            ]}
            list3={[
              "Loeb, Daniel",
              "Maloney, Linda",
              "Mehretu, Julie",
              "Owen Charitable Foundation",
              "Peacock, Robert",
              "Pelssers, Paul",
              "Perry, Eden",
              "Pine, Debi",
              "Pochobradsky, Mary",
              "Reuter, Christy",
              "Robbins, Michael",
              "Roe Foundation",
              "Soliman, Meriem & Serge Becker",
              "Solly, James",
              "Stephenson Family Charitable Fund",
              "Telesca, Sharon",
              "Tritt, Matthew",
              "Vales, Thomas",
              "Varvarikos, Maria",
              "Watman, Robert M.",
              "Wolf, Richard",
            ]}
            img1="https://res.cloudinary.com/dfwvu4gct/image/upload/v1665774967/RHF/RHF_Supporters_510x384_K_oesc58.jpg"
            img2="https://res.cloudinary.com/dfwvu4gct/image/upload/v1665774967/RHF/RHF_Supporters_510x384_M_mvpzem.jpg"
            img3="https://res.cloudinary.com/dfwvu4gct/image/upload/v1665774967/RHF/RHF_Supporters_510x384_I_awzhzw.jpg"
          />
          <SupportersList
            title="FRIEND CIRCLE $1,000 – $5,000"
            list1={[
              "Abdallah, Adnan",
              "Abu-Ghazaleh, Jumana",
              "Adams-Wilson, Nakeicha",
              "Agozzino, Cassondra",
              "Alagappan, Arun and Fran",
              "Allard, Ken",
              "American Express",
              "Andrea, Chapin",
              "Aqua Maxx Inc",
              "Arazi, Monica",
              "Astarabadi, Leila",
              "Augenblick, Norma",
              "Austensen, Laritz & Amanda",
              "Azima, Cricket",
              "Azovtsev, Kirill",
              "Bagley, Michael",
              "Bass, Hyatt",
              "Begley, Laura",
              "Benson II, Jac",
              "Bernard, Whitney A",
              "Bernardi, Ronald",
              "Bernstein, Toni",
              "Berti, Mark",
              "Best, Emily",
              "Best, Neal",
              "Boody, Meghan",
              "Bostock, Kate",
              "Boyce, Nelson",
              "Boyd, Diem",
              "Breslow-Newhouse, Ellen",
              "Brooks, Alrick and Joanne",
              "Brown, Alton",
              "Brown, Barry",
              "Brown, Dwayne",
              "Brown, Sheena",
              "Broyles, Rachel",
              "Bulchandani, Ashwin",
              "Burke, Julia",
              "CA Inc.",
              "Campbell, Paula",
              "Chang, Allison",
              "Chun, Eunu",
              "Clement, Jean",
              "Cobey, Elizabeth",
              "Cobey, Mary",
              "Cohn, Lynn",
              "Cole, David",
              "Cosby, Nicole",
              "Credit Suisse",
              "Crowe Deegan, Terry",
              "Crystal & Joe Wald",
              "Cuchel, Natalie",
              "Curry, Bryan",
              "Curry, Marshall",
              "David, Tracy",
              "Davidner, Shana",
              "Davis, Rachel",
              "Dawson, Chris & Lesley",
              "Deborah, Pine",
              "Dedrick, Eden",
              "Dedrick, Paige",
              "Deegan, John",
              "Depew, Patricia S.",
              "Diers, Judy",
              "Dixon, Darren",
              "Dorogi, Jonetta & John",

              "Duca, Kenneth & Kristen",
              "Eisenstadt, Steven",
              "Ena, Swansea Whisnant",
              "Enserro, Robert M.",
              "Ervin, Wilson D",
              "Etline, Adam",
              "Fantozzi, Stephen J. and Fatima Steiner",
              "Faraut, Philippe & Elizabeth",
              "Ferdinand, Richard",
              "Ford, Hadley",
              "Foulser, Richard",
              "Fox, Lisa",
              "Freedman, Jack",
              "Friedman, Jamie",
            ]}
            list2={[
              "Fusco, Mary Ann",
              "George, Mimi",
              "Gerhardter, Franz",
              "Gilfond, Chris",
              "Goodbody, Bridget",
              "Goren, James",
              "Green, Inez and Michael",
              "Greg Troilo & Lisa Krause",
              "Griffin, David",
              "Grossman, Peter",
              "Hackett, Melinda",
              "Hall, George",
              "Hampton, Dream",
              "Hans, Utsch",
              "Haratunian, Arda",
              "Hart, John",
              "Harte, Henry",
              "Harth, Misty & Gregory",
              "Hauschildt, Mark",
              "Hayden, Shawn",
              "Hayward, Matt and Brenda",
              "Heath, Leslie",
              "Hessler, Steve",
              "Hicks, Philip",
              "Hinds, David",
              "Hirani, Sunil",
              "Hoffman, Philip",
              "Howard Gilman Foundation",
              "Hughes, Charles",
              "Hurlbrink, Thomas",
              "Hunter, Margaret",
              "Hurley, Alfred",
              "Huston, Harriet",
              "Huston, John",
              "Isaacs, Ian",
              "Italiano, Joanne",
              "Italiano, Joanne & Charles Nemeth",
              "Jacob, Joel",
              "Jakupi, Binn",
              "Jakupi, Genc",
              "James, Patricia",
              "Jebbia, James & Bianca",
              "Kashlak, Roger",
              "Kaufman, gerald",
              "Kaufthal, Uri & Judy",
              "Kelman, Robert",
              "Kelson Capital LLC",
              "Kendrot, Cindy",
              "Kerr, Timothy",
              "Klausner, Josh",
              "Kleinberg, Mr & Mrs Paul",
              "Klingenstein, Paul & Kathleen Bole",
              "Kohn, Elizabeth",
              "Kolz, John",
              "Korb, Debbie",
              "Krane, Jonathan",
              "Lanahan, Leslie",
              "Landsman, Judith",
              "Lang, Michael",
              "Langenborg, Ralph",
              "Lawson, Kaysilyn",
              "Lee, Lorraine",
              "Lehmann, Michelle",
              "Liben, Sindy",
              "Licata, Vincent",
              "Lichtenstein, Herbert",
              "Lichter, Roz",
              "Lieberman, Evan S",
              "Lincoln, Brian",
              "Lindberg, Peter",
              "Linguna Lane Pharmacy Ltd",
              "Lobben, Eric",
              "Local 440 CUPE",
              "Lundin, Ake and Birgitta Palm",
              "Lutz-Morris, Tina",
              "Lyall, Sarah",
              "Lyn, Thalia",
              "Mahan, Kevin",
              "Mahar, Lisa",
            ]}
            list3={[
              "Makoff, Eileen and Greg",
              "Malcolm, Darren",
              "Malhotra, Anuj",
              "Marrero-Bernard, Miqui",
              "Martin, Francois",
              "Mascotte, Susie",
              "Mason, Jonathan",
              "Massey, Barbara",
              "Massey, Judith & Lance",
              "Massey, Lance",
              "Mathieu, Annette Louise",
              "Mayersohn, Arnold & Elizabeth",
              "McCormick, John",
              "McGee, Greg & Jennifer",
              "McGowan, Allison",
              "McMorran, Stephen",
              "Miasnikov, Alexei",
              "Milinazzo, Guy",
              "Milite, Charles",
              "Miller, James",
              "Miller, Joel",
              "Miller, Leslie",
              "Min, Sarah",
              "Moorin, Michael",
              "Moorin, Michael & Amy",
              "Moran, David",
              "Morris, Justin",
              "Mott, Stewart",
              "Motulsky Family Foundation",
              "Myasnikov, Alexey",
              "Napier, William",
              "Nash, Michael",
              "Neale, Joseph",
              "Nelson, Dennis",
              "Nelson, Dorrit",
              "Nemeth, Charles",
              "Netto, David",
              "Newhouse, Ellen",
              "Nickelberry, Jacqueline",
              "North, Steve",
              "Northern Trust Ch Giving Prog. – Zall",
              "O’Neill, Sarah",
              "Oldenburg, Mark",
              "Ostrom, Luke & Jacquie",
              "Pajolek, Marcie Gow",
              "Pajolek, Marcie Gow and Thomas",
              "Palmer, Raquel",
              "Panlilio, Leah",
              "Papworth, David",
              "Parauda, Robert",
              "Parkinson, Jennifer R",
              "Parr, Mary Gail",
              "Parr, Stuart",
              "Passarelli, Richard",
              "Perkins, Kathleen",
              "Peters, Laurie",
              "Pincus, Matthew",
              "Pine, Christopher",
              "Placas, Elliana",
              "Polisar, Lisa",
              "Pollari, Katrine",
              "Polsky, Lisa",
              "Pray, Franklin",
              "Ramirez, Roger",
              "Ramsay, Dushane",
              "Rasnavad, Alex",
              "Rathjen, Brian",
              "Razuki, Chester",
              "Retail Worx",
              "Ride For Life",
              "Robilotti, James & Kimberly",
              "Robinson, Gary",
              "Rock, Michael",
              "Rollan, Marcos",
              "Rosica, Paul",
              "Rubin, Ron and Pam",
              "Ryan, Siobhan",
              "Samuel Rubin Foundation",
              "Samuelson, Josh",
            ]}
            list4={[
              "Sanborn, Robert",
              "Sanchez Boyce, Michelle",
              "Sanders, Michelle & Derek",
              "Sayeed, Malik",
              "Schlarbaum, Mark",
              "Schmader, Toni & Matt Bedke",
              "Schmelter-Davis, Lynne",
              "Schreyer, Markus",
              "Schroeder, Jeff",
              "Schule, Don",
              "Schwab Charitable – Jack and Harriet Huston",
              "Scott, Steven",
              "Seitz, Molly",
              "Shelon, Jonathan",
              "Shelon, Jonathan & Ifat",
              "Shubuck, Simone",
              "Sidewater, Wendy",
              "Silverman, Scott & Robyn",
              "Simmons, Gail",
              "Simon, Jamil",
              "Skolnick, Douglas & Rita",
              "Smith, Gwen",
              "Solms, Elizabeth",
              "Solomon, Meron",
              "Spera, Sergio",
              "St. Clair, Heathe",
              "Steiner, Ismat & Maskin",
              "Stephenson, Valerie",
              "Stewart, Hugh",
              "Stubenhaus, Neil",
              "Supreme",
              "Susan, Berti",
              "Tarshis, Ronit",
              "Tassone, Thomas C.",
              "Taylor, Damali",
              "Tazza, Cesare",
              "Tcheyan, Leslie",
              "The Benevity Community Impact Fund",
              "The Community Fdn For The Ntl Cap Region",
              "The Greg McNeely Fund",
              "The Rockefeller Group",
              "The Sauce Lab",
              "Thompson-Adams, Nichole",
              "Thunen Family Foundation",
              "Thunen, Garret Mr & Mrs",
              "Todd, Donald",
              "Tortorella, Peter",
              "Tractenberg, Joel & Jacquie",
              "Tracy, Thomas",
              "Treantafeles, Sophia",
              "Tripp, Kali",
              "Trolio, Greg",
              "Turner, Shai",
              "Van Cleef, Victoria",
              "van der Velde, Johannes",
              "Vicki Peet & Steve Zimmerman",
              "Victor & Monica Markowicz Charitable Trust",
              "Volman, Kim",
              "Volpe, Cristina",
              "Walker, Tammara",
              "Waterbury, Susan",
              "William Somers",
              "Williams, Reginald",
              "Williams, Sarah",
              "Wilson, Corey",
              "Wilson, Jeri",
              "Winston, Alyson",
              "Wolff, Sascha",
              "Womsley, Jody",
              "Wong-Goebel, Monica",
              "Wong, Jess",
              "Yarwood, Craig & Anne",
              "Yu, Grace",
              "Yu, Susi",
              "Zimmerman, Richard",
              "Zucker, Matthew",
            ]}
          />
        </Box>
        <Box className="md:px-10 px-4">
          <Typography variant="body1">
            As carefully as we track donations, this page will be updated semi
            annually. If you identify a mistaken amount or attribution, please
            let us know by emailing{" "}
            <Link href="mailto:info@rockhousefoundation.org">
              info@rockhousefoundation.org.
            </Link>
          </Typography>
        </Box>
      </Pages>
    </>
  );
};

export default Supporters;

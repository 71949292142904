import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import DonationBanner from "../../components/features/DonationBanner";
import StyledTitle from "../../components/features/StyledTitle";
import BootstrapCarousel from "../../components/layout/BootstrapCarousel";
import Seo from "../../components/layout/Seo";
import VideoPlayer from "../../components/layout/VideoPlayer";
import ImageModal from "../../components/features/ImageModal";

const images = [
  "https://res.cloudinary.com/dfwvu4gct/image/upload/v1665925378/RHF/RHF_510x384_HISTORY_A_mcv3tr.jpg",
  "https://res.cloudinary.com/dfwvu4gct/image/upload/v1665925378/RHF/RHF_510x384_HISTORY_B_xtzvyf.jpg",
  "https://res.cloudinary.com/dfwvu4gct/image/upload/v1665925378/RHF/RHF_510x384_HISTORY_C_hkwkcs.jpg",
  "https://res.cloudinary.com/dfwvu4gct/image/upload/v1665925378/RHF/RHF_510x384_HISTORY_D_k4cacc.jpg",
];

const History = () => {
  const [selectedImg, setSelectedImg] = useState(null);
  return (
    <div>
      <Seo title="History" />
      <BootstrapCarousel
        images={[
          {
            src: "https://res.cloudinary.com/dfwvu4gct/image/upload/v1665845625/RHF/RHF_1920x823_HISTORY_BW_1_upefwd.jpg",
          },
          {
            src: "https://res.cloudinary.com/dfwvu4gct/image/upload/v1665845710/RHF/RHF_1920x823_HISTORY_BW_2_xwpezk.jpg",
          },
          {
            src: "https://res.cloudinary.com/dfwvu4gct/image/upload/v1665845685/RHF/RHF_1920x823_HISTORY_BW_3_ons3u9.jpg",
          },
        ]}
      />

      <DonationBanner />

      <div className="container py-4 mb-4">
        <StyledTitle title="HISTORY" />
        <Box className="grid grid-cols-1 md:grid-cols-4 gap-7">
          <Box className="md:col-span-3">
            <Typography>
              Rockhouse Hotel from its establishment in 1994 was built on a
              commitment to sustainable tourism and playing an active, positive
              role in the community. The initial endeavors of the philanthropic
              mission were focused on the inner city in Kingston, Jamaica’s
              capital city. The hotel assisted the Trench Town Development
              Association, supported the Victory Basic School and helped create
              one of Kingston’s most iconic cultural destinations, the
              Trenchtown Cultural Yard. Locally in Negril the hotel took on ad
              hoc projects including in the early 2000’s a breakfast feeding
              program at the Negril All Age School. To better leverage its
              programs, the hotel leadership recognized it needed to set up a
              formal, tax exempt vehicle to have more enduring and meaningful
              impact, especially as it turned its full attention to its own
              neighborhood, in and around Negril.
            </Typography>
            <Typography>
              The Rockhouse Foundation was founded in 2004 as a New York based
              charity and IRS recognized 501(c)(3) with a broad mission of
              “transforming the places where Jamaican children learn and
              supporting the great people who teach them”. Since establishment
              Rockhouse Foundation has raised and expended over US$10 million
              upgrading, expanding and modernizing seven schools and the Negril
              Public Library.
            </Typography>
            <Typography>
              Initially the focus was on improving infrastructure of public
              primary schools, recognizing the tremendous and widespread need,
              and as a visible and compelling way to establish the Foundation’s
              credibility. These first project included the Negril All Age and
              Basic School, the Negril Library and the Little Bay All Age and
              Basic School.
            </Typography>
            <Typography className="mb-4">
              A review of the first decade of work from 2004 to 2014 is depicted
              in this 10 year anniversary video:
            </Typography>
            <Box className="mb-4">
              <VideoPlayer url="https://www.youtube.com/embed/yMvZ3PowUAA" />
            </Box>
            <Box className="mb-4">
              <Typography>
                The Foundation has expanded its original focus and has funded
                additional services and personnel in its partner schools such as
                free breakfast, ongoing maintenance and repairs, technology and
                additional teachers for better student / teacher ratios.
              </Typography>
              <Typography>
                More recently, the Foundation determined to apply the bulk of
                its energy and resources to the early childhood space, beginning
                with the renovation and expansion of the Bunch of Stars Early
                Childhood Institute. In 2017, the Foundation launched its most
                ambitious project to date with the development of the
                Savana-la-Mar Inclusive Infant Academy (Sav Inclusive),
                Jamaica’s first and only mixed ability, full inclusion model
                school in Westmoreland’s capital city of Savanna-la-Mar. Built
                from the ground up by the Foundation, Sav Inclusive has a full
                enrollment of 165 children for the 2021/2022 school year with
                planning for an expansion into the primary and secondary years
                fully underway.
              </Typography>
            </Box>
            <Box className="md:mb-4">
              <Box className="md:grid md:grid-cols-2 gap-4 flex items-start md:flex-row flex-col justify-between">
                <Box className="space-y-1">
                  <Typography variant="body1">
                    In July 2021 Jamaica’s Minister of Education, Fayval
                    Williams, and Rockhouse Foundation President Peter Rose
                    (pictured) signed a new joint commitment to expand the Sav
                    Inclusive school through to 12th grade. Currently educating
                    students from pre-K to 2nd grade school, this new commitment
                    means that each year for the next decade the Foundation will
                    be adding a new grade so the current 2nd graders have a path
                    right through high school. The understanding also includes
                    additional adjoining lands allocated from the Catholic
                    Church for construction of the expanded primary and high
                    schools.
                  </Typography>
                </Box>
                <img
                  onClick={() =>
                    setSelectedImg(
                      "https://res.cloudinary.com/dfwvu4gct/image/upload/v1665846005/RHF/unnamed-1_qft3sw.jpg"
                    )
                  }
                  src="https://res.cloudinary.com/dfwvu4gct/image/upload/v1665846005/RHF/unnamed-1_qft3sw.jpg"
                  loading="lazy"
                  className="object-contain"
                  alt=""
                />
              </Box>
            </Box>
          </Box>

          <Box>
            <Box className="grid grid-cols-2 md:grid-cols-1 gap-4 mb-4">
              {images.map((image, i) => (
                <img
                  onClick={() => setSelectedImg(image)}
                  key={i}
                  src={image}
                  alt="Rockhouse Foundation History"
                  className="object-contain w-full"
                />
              ))}
            </Box>
          </Box>
        </Box>
        {selectedImg && (
          <ImageModal
            selectedImg={selectedImg}
            setSelectedImg={setSelectedImg}
          />
        )}
      </div>
    </div>
  );
};

export default History;

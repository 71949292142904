import { Box } from "@mui/material";
import React from "react";
import DonationBanner from "../components/features/DonationBanner";
import TextImage from "../components/features/TextImage";
import TextImageReverse from "../components/features/TextImageReverse";
import Seo from "../components/layout/Seo";
import BootstrapCarousel from "../components/layout/BootstrapCarousel";

const Board = () => {
  return (
    <>
      <Seo title="Board" />
      <BootstrapCarousel
        images={[
          {
            src: "https://res.cloudinary.com/dfwvu4gct/image/upload/v1665842102/RHF/RHF_Slideshow_1920x823_BOARD_2_cxamze.jpg",
          },
        ]}
      />
      <Box>
        <DonationBanner />
        <div className="container">
          <TextImage
            url="https://res.cloudinary.com/dfwvu4gct/image/upload/v1665842394/RHF/RHF_570x429_NicoleCosby_upds9v.jpg"
            title="NICOLE COSBY"
            text1="Nicole is the Chief Data and Compliance Officer for Fyllo. Fyllo is a next generation marketing tech platform that powers regulatory and marketing compliance at scale for the cannabis industry. Prior to joining Fyllo, Nicole was the global head of the advertising Standards & Partnerships group at Publicis Media."
            text2="In addition to proudly serving on the board of the Rockhouse Foundation Nicole also volunteers with various youth and education groups — all dedicated to improving the lives of young people in underserved communities through education, supportive relationships, and legal guidance. As a woman of color, Nicole finds it important for her to utilize her influence positively to impact diversity and inclusion, especially within the advertising industry. She is an active member of both the Internet Advertising Bureau’s (IAB) Diversity Task Force and is co-chair/founder of the IAB’s new Cannabis Advertising Working Group."
            text3="In 2018 Nicole was named as Cynopsis Top Women in Digital, received the Facebook/PMX Marisa Marolf Strength Award in 2019, and was named as one of Campaign Magazine’s Digital “40 Over 40” awardees."
          />
          <TextImageReverse
            title="IAN COVILLE"
            url="https://res.cloudinary.com/dfwvu4gct/image/upload/v1665843807/RHF/RHF_570x429_IanCoville_i509om.jpg"
            text1="Ian Coville is a proud father of three children, and currently resides in Palmetto, FL. Having studied at Tufts University and the Museum School of Fine Arts, Ian brings a sense of creativity and artistic nature to solving problems and implementing new projects and initiatives that he feels passionately about. Ian has spent the last 20+ years leading private and public companies in developing strategy to implement innovative changes in technology to ensure success. Ian serves as technical advisor to the Foundation, overseeing operations for the technical programs and computer labs at the Foundation schools as well as being responsible for the implementation, maintenance and support of the SuccessMaker program, the first of its kind in the Caribbean. Ian travels to Jamaica on a regular basis in support of the technology program and to ensure that this program touches as many children as possible."
          />

          <TextImage
            url="https://res.cloudinary.com/dfwvu4gct/image/upload/v1665843808/RHF/RHF_BOARD_570x429_Max-Glazer_zsemuk.jpg"
            title="MAX GLAZER"
            text1="Max Glazer as co-founder of Federation Sound has spent his entire career as a professional DJ and music producer focused on spreading the sounds of Dancehall, Reggae and the Caribbean far and wide. Max has shared stages and DJ’ed for Sean Paul, Cham, Chronixx, Estelle and Vybz Kartel, to name a few. He spent four years on tour with Rihanna as her DJ and Musical Director, performing on some of the world’s most legendary stages – London’s Wembley Arena, Jamaica’s Sumfest and NYC’s Madison Square Garden and Radio City Music Hall. As a music producer, he has contributed production to Chronixx’ Grammy nominated debut album, Chronology, and worked with Vybz Kartel, Mr. Vegas, Ricky Blaze and more. Max set up and ran the legendary online radio station, Radio Lily, which broadcast live from Miss Lily’s original New York location, providing a platform for Caribbean artists as well as some of New York’s greatest DJs. He currently oversees music at Miss Lily’s New York and Miss Lily’s Negril. Max is a partner at Skylark Negril."
          />
          <TextImageReverse
            title="Shana C Hall"
            url="https://res.cloudinary.com/dfwvu4gct/image/upload/v1686960215/RHF/Shana_Hall_uf9eca.jpg"
            text1="Shana C Hall is an Executive Director at GCM Grosvenor, a global asset manager. She leads the client reporting team for the private equity, infrastructure, real estate, and absolute return strategies responsible for client servicing, communications, and reporting. As an Operational strategist, Shana has over 16 years of experience transforming businesses to maximize efficiency, scalability, and drive growth additionally specializing in operational infrastructure, change management, and collaborative partnerships. Beyond the corporate arena, Shana is recognized for her civic engagement and passion for diversity. As the Vice Chair of the KIPP New Jersey board, she works to support the advancement and success of underrepresented minority students through motivational speaking, providing exposure and opportunity. She is member of the NAIC's Women in Alternatives Advisory Board, supporting the progression of minority women in the alternatives industry through mentorship and professional development programming. Additionally, she serves on the Board of I Choose The Ladder, a career development agency that helps Fortune 500 companies primarily develop and retain their high-performing black female talent. Shana was named 2022 40 under 40 Black Women in Asset Management and Disruptors Magazine Top 10 Women Leaders of 2022. Shana and the Hall Family are proud Jamaicans from Kingston."
          />
          <TextImage
            title="FAB IPPOLITI"
            url="https://res.cloudinary.com/dfwvu4gct/image/upload/v1665843807/RHF/RHF_570x429_FabIppoliti_zdqkez.jpg"
            text1="Fabrizio (Fab) Ippoliti is a Founder Partner and the Managing Director of  Rockhouse Hotel Jamaica. Fab has a long history in hospitality dating back to the 1970s. In the 1980’s he built and operated Subterrain Night Club and then went on to own Inflation Night Club. Both Melbourne Australia venues were design award winning masterpieces with Inflation winning the 1985 RAIA, Merit Award for Outstanding Architecture. Next he opened two highly successful restaurants in Melbourne in the late 1980s, Iguana and Carmines. Known locally as a Tsar of the Melbourne hospitality and entertainment industry his vision contributed to the deregulation of the Liquor license and the way businesses served food and alcohol in Melbourne in 1990. Throughout this period and into the early 1990’s he worked advising Price Waterhouse Coopers (then Coopers & Lybrand) in their hospitality division and overseeing Receiver Managers. In 1994 ahead of the purchase of Rockhouse Fab relocated to Jamaica and project managed and oversaw all aspect of the original development and construction of the Rockhouse in 1994/1995 and has remained as Managing Director over the past two decades with key oversight in the areas of expansion and new construction, renovation and maintenance of the facility as it has grown from 12 rooms to 34 rooms and from 2 1/2 acres to 8 acres of property. Currently Fab balances his commitments in Jamaica with numerous international commercial and residential building developments. As the Managing Partner of LF New York Property Trust he has recently converted and renovated a mixed use commercial & residential building in lower Manhattan."
          />
          <TextImageReverse
            url="https://res.cloudinary.com/dfwvu4gct/image/upload/v1665843808/RHF/RHF_570x429_SamJacobs-1_bri7wi.jpg"
            title="SAMUEL JACOB, Treasurer"
            text1="Samuel L. Jacob was born in Fairfield, CT and received a BS in Economics (Summa Cum Laude) and an MS in accounting from The Wharton School at The University of Pennsylvania. Sam is a Co-founder of the Rockhouse Foundation. Sam has been involved in capital raising, consulting and financial advisory services for companies in financial services, technology, healthcare processing, entertainment and business intelligence spaces and active as a private investor. Sam was a Managing Director at JP Morgan/Chase where he was responsible for relationships with Banks, Central Banks and Finance Companies globally. Sam also spent 16 years at Bankers Trust Company where he lived and worked in the US, Europe and Asia. He was a Partner and Senior Managing Director and held a number of positions, including Head of Global Fixed Income Sales and Deputy Head of The Asia Merchant Bank.Sam was also a senior member of a small team that pioneered Credit Derivatives and executed the first credit swap. He joined Bankers Trust as Controller of the Global Markets Businesses after 6 years at Arthur Andersen & Co."
          />
          <TextImage
            title="INISE LAWRENCE"
            url="https://res.cloudinary.com/dfwvu4gct/image/upload/v1665843808/RHF/RHF_BOARD_570x429_Inise-Lawrence_g25tiy.jpg"
            text1="Inise Lawrence for the past 5 years has held the position of General Manager at the Rockhouse Hotel and Spa, where she has worked for over two decades. Inise leads by example and encourages positive change through teaching and coaching. She has a passion for creating opportunities for today’s youth; she inspires, engages, and equips others in the belief in endless possibilities. Inise’s amazing spirit coupled with her warm smile brings positivity and light to every person she meets. As the primary face of the Rockhouse Inise holds herself accountable for exceeding the expectation of her guests and organization. In order to better serve her guests, organization and community Inise has constantly increased her knowledge in formal settings and is a certified wedding coordinator with The Wedding Planning Institute of Canada, she also holds a BSc Degree in Hospitality Management from Western Hospitality Institute. When she is not at the Rockhouse you will either find her in the gym or doing gardening in her backyard."
          />
          <TextImageReverse
            url="https://res.cloudinary.com/dfwvu4gct/image/upload/v1665843808/RHF/RHF_BOARD_570x429_Kaysilyn-Lawson_dwpifc.jpg"
            title="KAYSILYN LAWSON"
            text1="Kaysilyn Lawson was born in Jamaica and started her carer in the hospitality over 15 years ago in her home town of Montego Bay. After moving to New York City more than a decade ago to attend film school, she has worked in management and marketing with Miss Lily’s, Rockhouse Hotel and Rockhouse Foundation, in addition to the Tao Group. Kaysilyn is now the Chief Operating Officer of Marcus Samuelsson Group for whom she has led restaurant openings and team creations in New York, New Jersey, Miami, London, Montreal and the Bahamas. She considers the opportunity to work with the most talented hospitality professionals in the world to be one of her greatest points of pride. Kaysilyn is also a partner at Skylark Negril."
          />

          <TextImage
            title="SEAN LEGISTER"
            url="https://res.cloudinary.com/dfwvu4gct/image/upload/v1665843808/RHF/RHF_570x429_SeanLegister_n2ss4b.jpg"
            text1="Sean Legister is currently an Alternative Products Specialist in Goldman Sachs’ Merchant Bank. He has been with the firm since receiving his bachelor’s in both Black Studies and English from Amherst College in 2011. Prior to his current role, he was an equity salestrader on MAPS, a cross-asset team, since its 2014 inception. He started his career at GS as a sales trader in equity based financial instruments, listed derivatives, swaps, and international equities as part of the Global Institutional team. He is on the Board of Trustees of the Cristo Rey New York High School, where he mentors current students and alums. He is the founder and curator of the Monday Morning Lift(MML) and Breakfast Bites. The MML is an email subscription aimed to inspire, challenge, and motivate readers of all ages (https://www.mondaymorninglift.com/). Breakfast Bites is an initiative he created at Goldman Sachs to help Black professionals have more exposure at the firm, as well as build the culture within the Black community. Both of his parents were born and raised in Jamaica."
          />
          <TextImageReverse
            url="https://res.cloudinary.com/dfwvu4gct/image/upload/v1665843807/RHF/RHF_570x429_JulieMonaco_upu1b2.jpg"
            title="JULIE MONACO"
            text1="Julie Monaco is the Global Head of Citi’s Public Sector Group in the Corporate and Investment Banking division of Citi’s Institutional Clients Group. Julie leads the banking coverage team in 98 countries that is responsible for providing a full range of financial services to public sector entities in 160 countries. Julie was named, in 2019, to the American Banker’s list of “25 Most Powerful Women in Banking and Finance” for the tenth year. She was also recognized by Crain’s “Notable Women in Banking and Finance” for 2019. Treasury & Risk magazine named Julie as one of the “100 Most Influential People in Finance” in 2010, and Julie was honored by the YWCA-New York City Academy of Women Leaders for her professionalism and community service."
            text2="Julie serves on the Corporate and Investment Bank’s Diversity Committee, Citi Women’s Senior Steering Committee and leads the committee’s efforts on driving economic progress through gender equality. She has served as Chairman of the Board of Directors for Junior Achievement USA, on the Board of Trustees of Prep for Prep, serves as Deputy Chair of the Advisory Board at The Elliott School of International Affairs at George Washington University. She holds an MBA in Finance from New York University and a BA in International Affairs from George Washington University."
          />
          <TextImage
            title="PETER PICARD"
            url="https://res.cloudinary.com/dfwvu4gct/image/upload/v1665843808/RHF/RHF_570x429_PeterPicard_v7xnkz.jpg"
            text1="Peter Picard is a writer/producer, small business owner, and co-founder of Beargrass Writer’s Workshop (2015) and Pivot For Humanity (2019), a non-profit designed to pressure Silicon Valley to professionalize like doctors, lawyers and civil engineers. He sits on a number of boards whose focus is children, including Global Arts Initiative, the Rockhouse Foundation, and the Russian Children Welfare Society. He lives with his wife and two dogs in Connecticut."
          />
          <TextImageReverse
            url="https://res.cloudinary.com/dfwvu4gct/image/upload/v1665843808/RHF/RHF_570x429_PeterRose_outchn.jpg"
            title="PETER B. ROSE, President"
            text1="After ten years as a member of the New York Stock Exchange, beginning in 1980, Mr. Rose has spent the past 25 years working in the non-profit sector, first as Executive Director of Clearpool, Inc., and more recently as President of the Rockhouse Foundation. During 13 years as CEO of Clearpool, he lead its transformation from an insolvent summer camp for low-income children struggling to generate $500,000 annually, to an entrepreneurial, year-round educational organization serving children from kindergarten through career with an annual budget of almost $5 million. In 2002 Mr. Rose left Clearpool and soon thereafter joined with his former Clearpool Board member, Paul Salmon and Mr. Salmon’s fellow owners of the Rockhouse Hotel, to create the Rockhouse Foundation, where he has served as President since 2003."
          />
          <TextImage
            title="DAMIAN SALMON"
            url="https://res.cloudinary.com/dfwvu4gct/image/upload/v1665843807/RHF/RHF_570x429_DamianSalmon_t3oplk.jpg"
            text1="Damian Salmon was born in Melbourne, Australia, where he focused his study on matters of design and social interaction, often looking for alternative views. After completing secondary school, he studied Interior Design at the Royal Melbourne Institute of Technolodgy and began his furniture and its construction. He became an instructor at the “Melbourne School of Woodcraft” then went on to form his own company designing and building custom furniture including Retail and Hospitality fit outs. Since 2006 he has lived primarily in Jamaica as a partner and Project Director at Rockhouse Hotel in Negril overseeing design, implementation, upgrading, maintenance, grounds, and responsible for the look and feel of the resort. Since being in Negril he has become very involved with the community and was elected President of the Negril Chamber of Commerce in 2011. He has participated in a number of boards and committees including The Negril Environment Protection Trust, The Negril Beach Restoration Committee, The Negril Resort Board, UDC Tourism Advisory Committee, The Kennelworth/Heart Management Development Committee and the Chamber of Commerce where he continues to serve as a board member."
          />
          <TextImageReverse
            url="https://res.cloudinary.com/dfwvu4gct/image/upload/v1665843808/RHF/RHF_570x429_PaulSalmon_bbx5bt.jpg"
            title="PAUL SALMON, Chairman"
            text1="Paul Salmon is Chairman of Rockhouse Hotel, a forty-room boutique hotel located on the cliffs of Negril, Jamaica which he and his partner founded in 1994. Rockhouse is built on a commitment to responsible tourism that the Rockhouse Foundation is rooted in. Paul oversees all aspects of the hotel’s positioning, marketing and operations. Paul is the Managing Partner at Miss Lily’s, the celebrated Jamaican restaurant group with four locations; two in downtown New York, Dubai and its newest addition at the brand new Skylark Negril Beach Resort in Jamaica. Paul is also the Chairman of Skylark Negril. Additionally, Paul is a founding and operating partner of Joe’s Pub & the Library, one of New York’s most renowned live music and performance venues and restaurants, located at the Public Theater in NYC. Paul also serves as a Board Member at City & Country School located in New York’s West Village where his children attend."
          />
          <TextImage
            title="AISHA STEINER"
            url="https://res.cloudinary.com/dfwvu4gct/image/upload/v1665843807/RHF/RHF_570x429_AishaSteiner_3_y7rnzt.jpg"
            text1="Aisha Steiner is a founding board member of the Rockhouse Foundation. In her capacity as the US purchasing manager for the Rockhouse and Skylark Hotels she regularly visits Jamaica. Aisha also works merchandizing and developing products at the stores. Aisha has a Liberal Arts Degree in from Sarah Lawrence College with a concentration in Child Psychology and a Masters Degree in Education and Communication from New York University. Aisha worked at the Education Development Center on an adult literacy program for television. A mother of three boys Aisha is primarily devoted to their development and plays an active role in their schools."
          />
          <TextImageReverse
            url="https://res.cloudinary.com/dfwvu4gct/image/upload/v1665843808/RHF/RHF_570x429_PeterScutt_s0exsn.jpg"
            title="PETER SCUTT"
            text1="Peter is one of the founding partners of Rockhouse Hotel, Negril, Jamaica. He has founded or been early investor, board member or advisor to early stage companies in Australia, the US and Europe, and has substantial experience founding and managing businesses, restructuring businesses and launching new products. He is currently a Co-Founder and Executive Director of both Tissue Repair Pty Limited and Better Caring Pty Limited. He was also previously the co-founder and CEO of Texel Capital, a European based hedge fund. Peter is an ex-Senior Managing Director and Partner of Bankers Trust Company, New York and founded and was Managing Partner of BT Venture Partners, the venture capital arm of Bankers Trust. Peter holds a Bachelor of Commerce (Marketing Major) from the University of New South Wales, being recipient of the Hoover award as best graduating student. "
          />
          <TextImage
            title="TAMMARA WALKER"
            url="https://res.cloudinary.com/dfwvu4gct/image/upload/v1665843808/RHF/RHF_BOARD_570x429_Tammara-Walker_aqk5ln.jpg"
            text1="Tammara Walker is the founder of WKT Collective, a strategic marketing consultancy with a focus on Diversity, Equity, and Inclusion (DEI) within brand management. She specializes in integrated marketing strategies for institutions wanting meaningful solutions todrive inclusive marketing behaviors with programs and campaigns that support an organization’s overall commitment to culturaland equitable change. Formerly, Tammara served as Vice President of Corporate Marketing at J.P. Morgan and Chase (JPMC). Before joining JPMC, Tammara spent 8+ years at Nike Inc., serving in a multitude of roles. Her final role was leading the Nike Kidsbrand in New York City. During her tenure, she helped grow the digital and retail footprint of the brand through major campaigns with athletes such as Kyrie Irving, Lebron James, Serena Williams and others. In addition to her role, she played an integral part inthe development of Nike’s Purpose Marketing initiative, which supported the brand’s long-term commitment to inclusion in sport. Tammara is a native New Yorker, who has spent her life serving the communities that helped shape her."
          />
          <TextImageReverse
            url="https://res.cloudinary.com/dfwvu4gct/image/upload/v1665843808/RHF/RHF_BOARD_570x429_Charlotte-Wallace_mtmz5v.jpg"
            title="CHARLOTTE WALLACE"
            text1="Eleanor Charlotte Grant-Wallace was born in Kent, UK (to a British Mother and Jamaican father) but was raised in Kingston, Jamaica. After graduating from Campion College in Kingston, Charlotte attended University of Florida where she graduated with a Bachelors of Science in Accounting. This was followed by passing the Georgia Board Certified Public Accountants examination just 6 months later. After a short stint in Public Accounting, Charlotte discovered the Hospitality Industry in which she has worked since 1991 firstly as Group Internal Auditor for Superclubs and then as Controller at hotels such as Couples Negril and Sandals Negril. Whilst working at Superclubs, Charlotte attended Nova Southeastern University remote campus and achieved her MBA. After a stint as General Manager at Island Outpost’s The Caves in Negril she joined the team at Rockhouse Hotel first as Financial Controller in 2008 and was promoted to Hotel General Manager in 2012. In 2016, Charlotte progressed to working with Rockhouse and then Skylark as a Consultant in Acccounting and Hotel Operations while serving on the Board of Directors of both hotels."
          />
          <TextImage
            title="CHRIS WALSH"
            url="https://res.cloudinary.com/dfwvu4gct/image/upload/v1665843807/RHF/RHF_570x429_ChrisWalsh_d1gdzh.jpg"
            text1="Chris brings a wealth of entertainment industry knowledge to the Rockhouse Foundation, a product of nearly two decades of working as a Live Event Producer. Chris is a partner in Nectar’s Entertainment Group with a prime focus on producing concerts and live events in Vermont, Connecticut, Martha’s Vineyard, Colorado, Jamaica and beyond."
            text2="Chris is also the Co-Founder of Upstate Elevator Supply Company, one of the fastest growing brands in the US CBD sector. In January 2020 Chris was hired as the COO with Citiva Jamaica, a cannabinoid manufacturing company based in the Medical Science building of University of the West Indies Mona Campus in Kingston JA."
          />
        </div>
      </Box>
    </>
  );
};

export default Board;

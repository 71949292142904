import React from "react";

import Seo from "../components/layout/Seo";

const About = () => {
  return (
    <div>
      <Seo title="About us" />
      About
    </div>
  );
};

export default About;

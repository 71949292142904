import React, { useEffect } from "react";
import { Container } from "@mui/material";
import Layout from "../components/layout/Layout";
import Newsletter from "../components/forms/Newsletter";

export default function Newslettter() {
  return (
    <Container
      sx={{
        mx: "auto",
        maxWidth: "600px",
      }}
    >
      <Newsletter />
    </Container>
  );
}

import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

import { Link as MUILink } from "@mui/material";
import { Facebook, Instagram } from "@mui/icons-material";

const Footer = () => {
  let navigate = useNavigate();
  return (
    <Box
      component="footer"
      className="absolute bottom-0 top-full h-92 mt-4 w-full mx-auto"
    >
      <Box
        sx={{ bgcolor: "secondary.main" }}
        className="w-full grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-4 sm:p-8 p-4 mb-0 pt-4"
      >
        <Box className="text-center">
          <Typography
            variant="h3"
            sx={{ color: "#fff", fontWeight: "bold" }}
            align="center"
          >
            CONTACT US
          </Typography>
          <Box>
            <Typography variant="body1" sx={{ color: "#fff" }} align="center">
              Office mailing address: <br />
              Rockhouse Foundation <br />
              c/o Paul Salmon <br />
              2 Cornelia St. PH-C <br />
              New York, NY 10014
            </Typography>
            <Typography
              variant="body1"
              sx={{ color: "#fff", fontWeight: "bold" }}
              align="center"
            >
              Email:
              <MUILink
                underline="none"
                sx={{ color: "#fff" }}
                href="mailto:info@rockhousefoundation.org"
              >
                {" "}
                info@rockhousefoundation.org{" "}
              </MUILink>
            </Typography>
            <Typography
              variant="body1"
              sx={{ color: "#fff", fontWeight: "bold" }}
              align="center"
            >
              New York:
              <MUILink
                underline="none"
                sx={{ color: "#fff" }}
                href="tel:+1-212-807-0868"
              >
                {" "}
                +1 (212) 807-0868{" "}
              </MUILink>
            </Typography>
            <Typography
              variant="body1"
              sx={{ color: "#fff", fontWeight: "bold" }}
              align="center"
            >
              Jamaica:
              <MUILink
                underline="none"
                href="tel:+1-876-618-1533"
                sx={{ color: "#fff" }}
              >
                {" "}
                +1 (876) 618-1533{" "}
              </MUILink>
            </Typography>
            <Box className="space-x-2 mx-auto">
              <a
                href="https://www.instagram.com/rockhousefoundation/"
                className="cursor-pointer"
              >
                <Instagram sx={{ color: "#fff" }} fontSize="large" />
              </a>
              <a
                href="https://www.facebook.com/rockhousefoundation/"
                className="cursor-pointer"
              >
                <Facebook sx={{ color: "#fff" }} fontSize="large" />
              </a>
            </Box>
          </Box>
        </Box>
        <Box className="text-center">
          <Typography
            variant="h3"
            sx={{ color: "#fff", fontWeight: "bold" }}
            align="center"
          >
            USEFUL LINKS
          </Typography>
          <Box>
            <Typography
              sx={{ color: "#fff" }}
              className="cursor-pointer hover:text-blue-900 hover:font-bold"
              onClick={() => navigate("/our-work", { replace: true })}
            >
              Our Work
            </Typography>

            <Typography
              sx={{ color: "#fff" }}
              className="cursor-pointer hover:text-blue-900 hover:font-bold"
              onClick={() => navigate("/blog", { replace: true })}
            >
              Our Blog
            </Typography>

            <Typography
              sx={{ color: "#fff" }}
              className="cursor-pointer hover:text-blue-900 hover:font-bold"
              onClick={() => navigate("/events", { replace: true })}
            >
              Events
            </Typography>
            <Typography
              sx={{ color: "#fff" }}
              className="cursor-pointer hover:text-blue-900 hover:font-bold"
              onClick={() =>
                navigate("/about-us/supporters", { replace: true })
              }
            >
              Supporters
            </Typography>
            <Typography
              sx={{ color: "#fff" }}
              className="cursor-pointer hover:text-blue-900 hover:font-bold"
              onClick={() => navigate("/about-us/board", { replace: true })}
            >
              Our Board
            </Typography>
            <Typography
              sx={{ color: "#fff" }}
              className="cursor-pointer hover:text-blue-900 hover:font-bold"
              onClick={() => navigate("/about-us/history", { replace: true })}
            >
              History
            </Typography>
            <Typography
              sx={{ color: "#fff" }}
              className="cursor-pointer hover:text-blue-900 hover:font-bold"
              onClick={() => navigate("/privacy-policy", { replace: true })}
            >
              Privacy Policy
            </Typography>
            <a
              href="/irs.pdf"
              target="_blank"
              className="no-underline hover:text-blue-900"
            >
              <Typography
                className="cursor-pointe hover:font-bold hover:text-blue-900"
                sx={{
                  color: "#fff",
                }}
              >
                IRS Approved 501(c)(3)
              </Typography>
            </a>
          </Box>
        </Box>

        <Box className="text-center space-y-6">
          <Box>
            <Typography
              variant="h3"
              sx={{ color: "#fff", fontWeight: "bold" }}
              align="center"
            >
              JOIN OUR NEWSLETTER
            </Typography>
            <Typography variant="body1" align="center" sx={{ color: "#fff" }}>
              Subscribe to our newsletter to learn about upcoming events.
            </Typography>

            <Button
              href="/sign-up"
              color="primary"
              className="z-10"
              variant="contained"
              sx={{
                color: "#fff",
                borderRadius: "25px",
                fontWeight: "bold",
                fontFamily: "Helvetica",

                "&:hover": {
                  color: "#fff",
                },
              }}
              disableElevation
            >
              Sign Up Now
            </Button>
          </Box>
          <Box>
            <Typography
              variant="h3"
              sx={{ color: "#fff", fontWeight: "bold" }}
              align="center"
            >
              VISIT SKYLARK
            </Typography>
            <Typography variant="body1" align="center" sx={{ color: "#fff" }}>
              Stay with us at our sister hotel located at 7 Mile Beach.
            </Typography>
            <Button
              variant="contained"
              sx={{
                color: "#fff",
                borderRadius: "25px",
                fontWeight: "bold",
                fontFamily: "Helvetica",
                "&:hover": {
                  color: "#fff",
                },
              }}
              disableElevation
              href="https://skylarknegril.com/"
            >
              Skylark negril
            </Button>
          </Box>
        </Box>

        <Box className="text-center space-y-6">
          <Box>
            <Typography
              variant="h3"
              sx={{ color: "#fff", fontWeight: "bold" }}
              align="center"
            >
              VISIT ROCKHOUSE
            </Typography>
            <Typography variant="body1" align="center" sx={{ color: "#fff" }}>
              Stay with us at our award-winning hotel on the cliffs of Negril.
            </Typography>
            <Button
              variant="contained"
              sx={{
                color: "#fff",
                borderRadius: "25px",

                fontWeight: "bold",
                fontFamily: "Helvetica",
                "&:hover": {
                  color: "#fff",
                },
              }}
              disableElevation
              href="https://www.rockhouse.com/"
            >
              Rockhouse Hotel
            </Button>
          </Box>
          <Box>
            <Typography
              variant="h3"
              sx={{ color: "#fff", fontWeight: "bold" }}
              align="center"
            >
              VISIT MISS LILY'S
            </Typography>
            <Typography variant="body1" align="center" sx={{ color: "#fff" }}>
              Make a reservation at our locations in Negril, NYC or Dubai.
            </Typography>
            <Button
              variant="contained"
              sx={{
                borderRadius: "25px",
                color: "#fff",
                fontWeight: "bold",
                fontFamily: "Helvetica",
                "&:hover": {
                  color: "#fff",
                },
              }}
              disableElevation
              href="https://www.misslilys.com/"
            >
              Miss Lily's
            </Button>
          </Box>
        </Box>
      </Box>
      <Box sx={{ bgcolor: "secondary.main", px: 2, pb: 4, pt: 4 }}>
        <Typography align="center" color="whitesmoke">
          © {new Date().getFullYear()} Rockhouse Foundation. All Rights Reserved{" "}
          Web Developer{" "}
          <MUILink href="https://toniaroganti.com/">
            <strong className="text-white underline"> Tonia</strong>
          </MUILink>
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;

// tokenService.js
export const getToken = () => {
  return JSON.parse(localStorage.getItem("user"));
};

export const saveToken = (token) => {
  localStorage.setItem("user", JSON.stringify(token));
};

export const getRefreshToken = () => {
  return localStorage.getItem("refreshToken");
};

export const saveRefreshToken = (refreshToken) => {
  localStorage.setItem("refreshToken", refreshToken);
};

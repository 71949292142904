import { Button, Stack, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { login, setOpenDialog } from "../../features/auth/authSlice";

function LoginForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  //   check if user is already logged in

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // login to backend
    dispatch(login({ email, password }));
    dispatch(setOpenDialog(false));
    navigate("/admin/images");
  };
  return (
    <Stack
      width="100%"
      maxWidth={500}
      minWidth={300}
      component="form"
      className="p-4 border-2 flex flex-col items-center justify-center rounded-md"
      onSubmit={handleSubmit}
    >
      <Typography variant="h4" gutterBottom>
        Login
      </Typography>
      <TextField
        fullWidth
        label="Email"
        variant="outlined"
        type="email"
        value={email}
        onChange={handleEmailChange}
      />
      <TextField
        fullWidth
        label="Password"
        variant="outlined"
        type="password"
        value={password}
        onChange={handlePasswordChange}
      />
      <Button className="m-1" variant="contained" color="primary" type="submit">
        Login
      </Button>
    </Stack>
  );
}

export default LoginForm;

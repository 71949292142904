import { Link, Typography } from "@mui/material";
import React from "react";

const ButtonExternalText = ({ href, children }) => {
  return (
    <Link href={href}>
      <Typography
        variant="body1"
        color="primary"
        className="cursor-pointer hover:text-blue-900 hover:font-bold"
        sx={{
          display: "inline-block",
        }}
      >
        {children}
      </Typography>
    </Link>
  );
};

export default ButtonExternalText;

import { Button } from "@mui/material";
import React from "react";

const ButtonExternal = ({ href, children }) => {
  return (
    <Button
      variant="contained"
      href={href}
      disableElevation
      sx={{
        color: "#fff",
        fontWeight: "bold",
        borderRadius: "25px",

        fontSize: "1.2rem",
        mb: 2,

        // '&:hover': {
        // 	backgroundColor: '#fff',
        // 	color: 'primary.main',
        // 	borderColor: 'primary.main !important',
        // },
      }}
      className="hover:text-white"
    >
      {children}
    </Button>
  );
};

export default ButtonExternal;

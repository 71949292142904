import { Box } from "@mui/material";
import React from "react";
import MainBlog from "../../components/features/blog/MainBlog";
import Seo from "../../components/layout/Seo";

import PageCallouts from "../../components/PageCallouts";

const OurBlog = () => {
  return (
    <div className="container">
      <Seo title="Blog" />

      <Box className="grid grid-cols-1 sm:grid-cols-3 gap-4 my-4">
        <Box className="sm:col-span-2">
          <MainBlog
            src="https://res.cloudinary.com/dfwvu4gct/image/upload/v1665754557/RHF/RHF_BlogHeader_Covid-980x398_x8sqjv.jpg"
            title="COVID-19 UPDATE FOR NEGRIL, JAMAICA"
            text="As the globe continues to struggle responding to and moving beyond the coronavirus pandemic, developing countries and regions are or will be especially hard hit with limited resources and inadequate public health infrastructure."
            link="covid-19-update-for-negril-jamaica/"
          />
          {/* grid 2 */}
          <Box className="grid grid-cols-1 md:grid-cols-2 gap-4 my-2 mb-4">
            <MainBlog
              src="https://res.cloudinary.com/dfwvu4gct/image/upload/v1665767788/RHF/RHF_Blog_800x530_SAV_O-400x250_bc3cus.jpg"
              title="PHYSIOTHERAPY AT SAV INCLUSIVE"
              text="The Rockhouse Foundation thinks of Jernenio taking his first steps at our newly opened Sav Inclusive School."
              link="physiotherapy-at-sav-inclusive/"
              border={true}
            />
            <MainBlog
              src="https://res.cloudinary.com/dfwvu4gct/image/upload/v1665767901/RHF/RHF_570x429_Howard_A-400x250_vwdr2l.jpg"
              title="HOWARD UNIVERSITY PARTNERSHIP"
              text="Rockhouse Foundation, Savanna-la-mar Inclusive Infant Academy, Howard University and the University of the West Indies join"
              link="howard-university-partnership/"
              border={true}
            />
          </Box>
          {/* grid 3 */}
          <Box className="grid grid-cols-1 md:grid-cols-3 gap-4 my-2">
            <MainBlog
              src="https://res.cloudinary.com/dfwvu4gct/image/upload/v1665767915/RHF/RHF_Blog_800x530_MarcusSkylark_O-400x250_wcrfcx.jpg"
              title="MARCUS SAMUELSSON AT SKYLARK"
              text="What would you say Ethiopia and Jamaica have in common? A whole lot more after Sunday, December 29"
              link="marcus-samuelsson-at-skylark/"
              variant="h6"
              border={true}
            />
            <MainBlog
              src="https://res.cloudinary.com/dfwvu4gct/image/upload/v1665767947/RHF/blog_q6dp9y.jpg"
              title="ANNUAL BENEFIT AT THE PUBLIC THEATER"
              text="At the Public Theatre’s Joe’s Pub on Nov. 22nd 2021, Shaggy took the stage"
              link="annual-benefit-at-the-public-theater/"
              variant="h6"
              border={true}
            />
            <MainBlog
              src="https://res.cloudinary.com/dfwvu4gct/image/upload/v1665767959/RHF/RHF_Blog_800x530_About_RHF_O-400x250_n0tzyb.jpg"
              title="ALL ABOUT THE ROCKHOUSE FOUNDATION"
              text="The Rockhouse Foundation is devoted to transforming the places Jamaican children learn and"
              link="all-about-the-rockhouse-foundation/"
              variant="h6"
              border={true}
            />
          </Box>
        </Box>
        <div className="md:max-w-sm">
          <PageCallouts />
        </div>
      </Box>
    </div>
  );
};

export default OurBlog;

import { Box, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import BootstrapCarousel from "../layout/BootstrapCarousel";
import VideoPlayer from "../layout/VideoPlayer";

const TextSlides = ({
  text1,
  title,
  text2 = "",
  text3 = "",
  link = "",
  linkText = "",
  vUrl = null,
  url = null,
  slides = false,
  images = [],
}) => {
  return (
    <div className="flex items-center mb-4">
      <Box className="lg:grid lg:grid-cols-2 lg:gap-16 gap-4 lg:items-center flex items-start flex-col justify-between">
        <Box className="container">
          <Typography variant="h2" component="h2" color="secondary">
            {title}
          </Typography>
          <Typography variant="body1">{text1}</Typography>
          <Typography variant="body1">{text2}</Typography>

          <Link to={link} style={{ textDecoration: "none" }}>
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold" }}
              color="primary"
            >
              {linkText}
            </Typography>
          </Link>
        </Box>
        {url && (
          <img
            className="object-contain w-full"
            src={url}
            loading="lazy"
            alt=""
          />
        )}
        {vUrl && <VideoPlayer url={vUrl} />}
        {slides === true ? <BootstrapCarousel images={images} /> : null}
      </Box>
    </div>
  );
};

export default TextSlides;

import { Box, Typography } from "@mui/material";
import React from "react";
import ButtonExternal from "../../components/features/ButtonExternal";
import ButtonExternalText from "../../components/features/ButtonExternalText";

import PageCallouts from "../../components/PageCallouts";
import BootstrapCarousel from "../../components/layout/BootstrapCarousel";

const AnnualBenefit = () => {
  return (
    <div>
      <BootstrapCarousel
        images={[
          {
            src: "https://res.cloudinary.com/dfwvu4gct/image/upload/v1665930832/RHF/unnamed_bzfbtr.jpg",
            name: "Rockhouse Foundation - ANNUAL BENEFIT AT THE PUBLIC THEATER",
          },
        ]}
      />

      <div className="container my-4">
        <Box className="grid grid-cols-1 md:grid-cols-4 gap-7">
          <Box className="md:col-span-3">
            <Typography variant="h1" color="secondary" className="mb-2">
              ANNUAL BENEFIT AT THE PUBLIC THEATER
            </Typography>

            <Typography variant="body1">
              Rockhouse Foundation’s benefit dinner held on November 22, and
              hosted by chairman Paul Salmon and president Peter Rose at Joe’s
              Pub at The Public Theater in downtown New York was a record
              success. Attendees enjoyed a multi-course dinner from chefs Andre
              Fowles & Kahari Woolcock of Miss Lily’s, along with a lively
              reggae performance from the legendary Shaggy.
            </Typography>

            <Typography variant="body1">
              The benefit raised over US $300,000 for the Rockhouse Foundation,
              which works to transform childhood education for Jamaica’s
              students and teachers. Funds raised were primarily targeted for
              the expansion of the Sav Inclusive School, built by the Foundation
              from the ground up, where typical learning children and those with
              disabilities are taught side-by-side in a full inclusion setting.
              Sav Inclusive opened in 2017 with 30 three-year olds and now has
              an enrollment of 165 children age three through grade 2. It
              recently signed an agreement with Jamaica’s Ministry of Education
              to expand through high school on the same location.
            </Typography>

            <Typography variant="body1">
              The evening also celebrated a US $1 million commitment to the work
              of the Foundation from philanthropists Pat McKee and Julie Russo.
              Pat and Julie hail from Raleigh North Carolina and are
              longstanding visitors to Jamaica who wanted to make a significant
              impact on the lives of the children and families on an island they
              had come to love over many years. The $1 million pledge is the
              largest in the Foundation’s 18 year history and will constitute
              cornerstone underwriting for Sav Inclusive’s expansion.
              Additionally, Julie Russo will provide professional development
              for teachers at the school thanks to her many years experience
              focused on math education in the early grades.
            </Typography>

            <Typography variant="body1">
              To date, the Rockhouse Foundation has donated over $6.5M
              transforming the education system in Western Jamaica. Jamaica’s
              former Minister of Education, the Honorable Ronald Thwaites, was
              the keynote speaker, and his presentation included greetings from
              the Permanent Secretary of Education, Maureen Dwyer, detailing the
              immeasurable impact that the Foundation has had on the Jamaican
              school system.
            </Typography>
            <Typography variant="body1">
              Fashion designer Nanette Lepore and culinary personalities Gail
              Simmons, Andy Baraghani, and Nilou Motamed were among the more
              than 150 guests in attendance who danced to live music, bid on
              luxurious raffle packages, and dined on{" "}
              <ButtonExternalText href="https://www.misslilys.com/">
                Miss Lily’s
              </ButtonExternalText>{" "}
              celebrated Caribbean cuisine.
            </Typography>
            <Typography variant="body1">
              The proceeds from the event support the Rockhouse Foundation’s
              “Teach Di Yutes Campaign 2021-2022”, the online GoFundMe
              established to primarily fund the planned expansion at the Sav
              Inclusive School schedule for Summer 2022. In addition to
              supporting our overall programing at our partner schools, funds
              from this campaign will be going towards our expansion of the
              primary school at Sav Inclusive, building new classrooms, therapy
              and specials rooms and administrative offices for 3rd and 4th
              grade.
            </Typography>
            <Box className="my-3">
              <ButtonExternal href="https://www.gofundme.com/f/teach-di-yutes-20212022-campaign">
                Donate to the "Teach Di Yutes" 2021-2022 GOFUNDME
              </ButtonExternal>
            </Box>
          </Box>
          <Box>
            <PageCallouts />
          </Box>
        </Box>
      </div>
    </div>
  );
};

export default AnnualBenefit;

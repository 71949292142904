import { Fragment, useState } from "react";
import { Dialog, Disclosure, Popover, Transition } from "@headlessui/react";

import { Close, ExpandMore, KeyboardArrowUp, Menu } from "@mui/icons-material";
import { Fab, Button, styled, Typography } from "@mui/material";
import { NavLink, useLocation } from "react-router-dom";

import BackToTop from "./BackToTop";
import { RAFFLE_URL } from "../../pages/Events";

const Offset = styled("div")(({ theme }) => theme.mixins.toolbar);

const about = [
  {
    name: "Financials",
    description: "Get a better understanding of your traffic",
    href: "/about-us/financials",
  },
  {
    name: "History",
    description: "Speak directly to your customers",
    href: "/about-us/history",
  },
  {
    name: "Supporters",
    description: "Your customers’ data will be safe and secure",
    href: "/about-us/supporters",
  },
  {
    name: "Board",
    description: "Connect with third-party tools",
    href: "/about-us/board",
  },
  {
    name: "Gallery",
    description: "Build strategic funnels that will convert",
    href: "/about-us/gallery",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Header2() {
  // const trigger = useScrollTrigger();
  const location = useLocation();

  const pathname = location.pathname;
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <header className="bg-white relative ">
      <nav
        className="bg-white fixed mx-auto flex items-center justify-between p-6 z-40 w-full "
        aria-label="Global"
      >
        <div className="flex lg:flex-1">
          <NavLink
            to="/"
            className={({ isActive, isPending }) =>
              isPending ? "pending" : isActive ? "active" : ""
            }
          >
            <span className="sr-only">Rockhouse Foundation</span>
            <img
              className="max-w-full sm:h-12 md:h-14 h-10"
              src="https://res.cloudinary.com/dfwvu4gct/image/upload/v1665526086/RHF/Logo_600x112-2_se2iph.png"
              alt=""
            />
          </NavLink>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Menu color="primary" fontSize="large" />
          </button>
        </div>
        <Popover.Group className="hidden lg:flex lg:gap-x-12">
          <NavLink
            to="/summary"
            className={({ isActive, isPending }) =>
              isPending ? "pending" : isActive ? "active" : ""
            }
            style={{
              fontFamily: "PT Sans Narrow",
              fontSize: "23px",
              textDecoration: "none",
              textTransform: "uppercase",
              color: pathname.includes("summary") ? "#FA7FB9" : "#009FDE",
              ":hover": {
                color: "#FA7FB9",
              },
            }}
          >
            Summary
          </NavLink>

          <NavLink
            to="/our-work"
            className={({ isActive, isPending }) =>
              isPending ? "pending" : isActive ? "active" : ""
            }
            style={{
              fontFamily: "PT Sans Narrow",
              fontSize: "23px",
              textDecoration: "none",
              textTransform: "uppercase",
              color: pathname.includes("our-work") ? "#FA7FB9" : "#009FDE",
              ":hover": {
                color: "#FA7FB9",
              },
            }}
          >
            Our Work
          </NavLink>

          <NavLink
            to="/events"
            className={({ isActive, isPending }) =>
              isPending ? "pending" : isActive ? "active" : ""
            }
            style={{
              fontFamily: "PT Sans Narrow",
              fontSize: "23px",
              textDecoration: "none",
              textTransform: "uppercase",
              color: pathname.includes("events") ? "#FA7FB9" : "#009FDE",
              ":hover": {
                color: "#FA7FB9",
              },
            }}
          >
            Events
          </NavLink>
          <NavLink
            to="/blog"
            className={({ isActive, isPending }) =>
              isPending ? "pending" : isActive ? "active" : ""
            }
            style={{
              fontFamily: "PT Sans Narrow",
              fontSize: "23px",
              textDecoration: "none",
              textTransform: "uppercase",
              color: pathname.includes("blog") ? "#FA7FB9" : "#009FDE",
              ":hover": {
                color: "#FA7FB9",
              },
            }}
          >
            Our Blog
          </NavLink>

          <Popover className="relative">
            <Popover.Button
              className="flex items-center gap-x-1"
              style={{
                fontFamily: "PT Sans Narrow",
                fontSize: "23px",
                textDecoration: "none",
                textTransform: "uppercase",
                color: pathname.includes("about") ? "#FA7FB9" : "#009FDE",
                ":hover": {
                  color: "#FA7FB9",
                },
              }}
            >
              About Us
              <ExpandMore
                className=" flex-none text-[#009FDE]"
                aria-hidden="true"
              />
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute -left-24 top-full z-10 mt-3 w-screen max-w-xs overflow-hidden rounded-3xl bg-[#009FDE] shadow-lg ">
                <div>
                  {about.map((item) => (
                    <div
                      key={item.name}
                      className="group relative flex items-center gap-x-6 rounded-lg p-3 text-sm leading-6 text-white hover:bg-[#FA7FB9] cursor-pointer z-50"
                    >
                      <div className="flex-auto">
                        <NavLink
                          to={item.href}
                          className={({ isActive, isPending }) =>
                            isPending ? "pending" : isActive ? "active" : ""
                          }
                          style={{
                            fontFamily: "PT Sans Narrow",
                            fontSize: "20px",
                            textDecoration: "none",
                            // textTransform: "uppercase",
                            color: "#fff",
                            ":hover": {
                              textDecoration: "underline",
                            },
                          }}
                        >
                          {item.name}
                        </NavLink>
                      </div>
                    </div>
                  ))}
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>
        </Popover.Group>
        <div className="hidden lg:flex lg:ml-10 lg:justify-end ">
          <Button
            variant="contained"
            color="secondary"
            size="large"
            sx={{
              borderRadius: "25px",
              fontWeight: "bold",
              border: "2px solid transparent",
              color: "white",
              "&:hover": {
                color: "#fff",
              },
            }}
            target="_blank"
            disableElevation
            href={RAFFLE_URL}
          >
            Donate
          </Button>
        </div>
      </nav>
      <Dialog
        as="div"
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-50" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm ">
          <div className="flex items-center justify-between">
            <NavLink
              to="/"
              className={({ isActive, isPending }) =>
                isPending ? "pending" : isActive ? "active" : ""
              }
              style={{
                fontFamily: "PT Sans Narrow",
                fontSize: "23px",
                textDecoration: "none",
                textTransform: "uppercase",
                color: "#009FDE",
                ":hover": {
                  color: "#FA7FB9",
                },
              }}
            >
              <span className="sr-only">Rockhouse Foundation</span>
              <img
                className="h-8 w-auto"
                src="https://res.cloudinary.com/dfwvu4gct/image/upload/v1665526086/RHF/Logo_600x112-2_se2iph.png"
                alt=""
              />
            </NavLink>

            <button
              type="button"
              className="m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <Close fontSize="large" color="secondary" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-6 py-6 flex flex-col">
                <NavLink
                  to="/summary"
                  className={({ isActive, isPending }) =>
                    isPending ? "pending" : isActive ? "active" : ""
                  }
                  style={{
                    fontFamily: "PT Sans Narrow",
                    fontSize: "20px",
                    textDecoration: "none",
                    textTransform: "uppercase",
                    color: pathname.includes("summary") ? "#FA7FB9" : "#009FDE",
                    ":hover": {
                      color: "#FA7FB9",
                    },
                  }}
                  onClick={() => setMobileMenuOpen(false)}
                >
                  Summary
                </NavLink>
                <NavLink
                  to="/our-work"
                  className={({ isActive, isPending }) =>
                    isPending ? "pending" : isActive ? "active" : ""
                  }
                  style={{
                    fontFamily: "PT Sans Narrow",
                    fontSize: "20px",
                    textDecoration: "none",
                    textTransform: "uppercase",
                    color: pathname.includes("our-work")
                      ? "#FA7FB9"
                      : "#009FDE",
                    ":hover": {
                      color: "#FA7FB9",
                    },
                  }}
                  onClick={() => setMobileMenuOpen(false)}
                >
                  Our Work
                </NavLink>
                <NavLink
                  to="/events"
                  className={({ isActive, isPending }) =>
                    isPending ? "pending" : isActive ? "active" : ""
                  }
                  style={{
                    fontFamily: "PT Sans Narrow",
                    fontSize: "20px",
                    textDecoration: "none",
                    textTransform: "uppercase",
                    color: pathname.includes("events") ? "#FA7FB9" : "#009FDE",
                    ":hover": {
                      color: "#FA7FB9",
                    },
                  }}
                  onClick={() => setMobileMenuOpen(false)}
                >
                  Events
                </NavLink>
                <NavLink
                  to="/blog"
                  className={({ isActive, isPending }) =>
                    isPending ? "pending" : isActive ? "active" : ""
                  }
                  style={{
                    fontFamily: "PT Sans Narrow",
                    fontSize: "20px",
                    textDecoration: "none",
                    textTransform: "uppercase",
                    color: pathname.includes("blog") ? "#FA7FB9" : "#009FDE",
                    ":hover": {
                      color: "#FA7FB9",
                    },
                  }}
                  onClick={() => setMobileMenuOpen(false)}
                >
                  Our Blog
                </NavLink>

                <Disclosure as="div" className="-mx-3">
                  {({ open }) => (
                    <>
                      <Disclosure.Button
                        className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5   hover:bg-gray-50"
                        style={{
                          fontFamily: "PT Sans Narrow",
                          fontSize: "20px",
                          textDecoration: "none",
                          textTransform: "uppercase",
                          color: pathname.includes("about")
                            ? "#FA7FB9"
                            : "#009FDE",
                          ":hover": {
                            color: "#FA7FB9",
                          },
                        }}
                      >
                        About Us
                        <ExpandMore
                          fontSize="large"
                          className={classNames(
                            open ? "rotate-180" : "",
                            "flex-none"
                          )}
                          aria-hidden="true"
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="mt-2 space-y-3 p-4 bg-[#009FDE] ">
                        {about.map((item) => (
                          <NavLink
                            key={item.name}
                            to={item.href}
                            className={({ isActive, isPending }) =>
                              isPending ? "pending" : isActive ? "active" : ""
                            }
                            style={{
                              fontFamily: "PT Sans Narrow",
                              fontSize: "20px",
                              textDecoration: "none",
                              color: "#fff",
                              display: "block",
                            }}
                            onClick={() => setMobileMenuOpen(false)}
                          >
                            {item.name}
                          </NavLink>
                        ))}
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              </div>
              <div className="py-6">
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{
                    borderRadius: "25px",
                    fontWeight: "bold",
                    border: "2px solid transparent",
                    color: "white",
                    "&:hover": {
                      color: "#fff",
                    },
                  }}
                  target="_blank"
                  disableElevation
                  href={RAFFLE_URL}
                >
                  Donate
                </Button>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
      {/* </HideOnScroll> */}
      <Offset id="back-to-top-anchor" className="mb-3 p-0" />
      <BackToTop>
        <Fab
          color="secondary"
          size="large"
          aria-label="back to top"
          className="m-0 p-0 opacity-70"
        >
          <KeyboardArrowUp />
        </Fab>
      </BackToTop>
    </header>
  );
}

import React from "react";
import Seo from "../components/layout/Seo";
import { Stack, Typography } from "@mui/material";
import ImageModal from "../components/features/ImageModal";

const images = [
  "/summary/0001.jpg",
  // "/summary/0002.jpg",
  // "/summary/0003.jpg",
  // "/summary/0004.jpg",
  // "/summary/0005.jpg",
  // "/summary/0006.jpg",
  // "/summary/0007.jpg",
  // "/summary/0008.jpg",
  // "/summary/0009.jpg",
  // "/summary/0010.jpg",
  // "/summary/0011.jpg",
  // "/summary/0012.jpg",
  // "/summary/0013.jpg",
  // "/summary/0014.jpg",
  // "/summary/0015.jpg",
  // "/summary/0016.jpg",
  // "/summary/0017.jpg",
  // "/summary/0018.jpg",
  // "/summary/0019.jpg",
];

function Summary() {
  const [selected, setSelected] = React.useState(null);

  return (
    <div>
      <Seo title="Summary" />
      <Stack className="container" spacing={3}>
        <img
          src="https://res.cloudinary.com/dfwvu4gct/image/upload/v1718023229/RHF/summary/sav_cover_akdkgw.jpg"
          alt="Rockhouse Foundation"
        />

        <SummaryCard
          setSelected={setSelected}
          content={
            <div>
              <Typography variant="h2" marginBottom={5}>
                WE ARE FAMILY
              </Typography>
              <p className="text-gray-900">
                For 20 years the Rockhouse Foundation has been at the forefront
                of educational transformation in western Jamaica. Investing over
                US$10 million, transforming six public schools and the Negril
                Public Library.
              </p>
              <p className="text-gray-900">
                This summer the Rockhouse Foundation continues its expansion of
                Jamaica’s FIRST FULL INCLUSION model pre-K through grade 12
                school, Savanna-La-Mar Inclusive Academy (“Sav Inclusive”). Not
                only is this school one of a kind in Jamaica, it is among the
                first globally. With an estimated 240 million children with
                disabilities worldwide, the need for quality education to allow
                these children to reach their full potential is paramount.
                Equally importantly, Sav Inclusive’s very existence serves to
                help destigmatize children with disabilities, allowing them to
                learn, grow, and thrive alongside children without disabilities.
                We see the impact everyday within the school but also an
                inspiring new thread within the community of support,
                acceptance, and belonging.
              </p>
              <p className="text-gray-900">
                The Sav Inclusive school is located in Savanna-la-Mar (Sav), the
                Capital city of Westmoreland, Jamaica’s western most parish. Sav
                is an historically volatile community, really a collection of
                discrete communities often in conflict with each other. There
                are limited services for neurodivergent and/or physically
                disabled children. Sav Inclusive is a bright light for many
                families who have long had no therapeutic options. Current
                enrollment is 225 students, with an anticipated ultimate
                population of 650 in pre-K through high school. Sav Inclusive’s
                waitlist exceeds its current enrolment, reflecting its
                reputation and the enormous need for its unique inclusive model.
              </p>
            </div>
          }
          images={[
            "https://res.cloudinary.com/dfwvu4gct/image/upload/v1688861154/RHF/rhffamily1_yuy2sr.png",
            "https://res.cloudinary.com/dfwvu4gct/image/upload/v1688861153/RHF/rhffamily2_os0udc.png",
            // "https://res.cloudinary.com/dfwvu4gct/image/upload/v1688861715/RHF/family3_jyfead.png",
            // "https://res.cloudinary.com/dfwvu4gct/image/upload/v1688861755/RHF/family4_zsakki.png",
          ]}
        />
        <SummaryCard
          setSelected={setSelected}
          content={
            <div>
              <Typography variant="h2" marginBottom={5}>
                WE ARE FAMILY (continued)
              </Typography>
              <p className="font-semibold">SUPPORT THE ROCKHOUSE FOUNDATION </p>
              <p className="text-gray-900">
                This is a historical educational movement, but we cannot do it
                alone. Our summer 2024 campaign is raising funds targeting an
                additional US $100K to complete the next development phase of
                Sav Inclusive that will underwrite the primary campus new
                classrooms. Additionally, we will construct a unique outdoor
                play space geared towards our full inclusion setting and every
                primary student regardless of their profile.
              </p>
              <p className="text-gray-900">
                The Rockhouse Foundation Board is just as committed to meeting
                this goal, as we have already raised over two thirds of the
                funds for this summer project- and can’t wait for you to join us
                in taking this over the finish line.
              </p>
              <p className="text-gray-900">
                There are many ways to get involved and be a true agent for
                change in education!
              </p>
              <ul className="list-disc space-y-4 font-extrabold text-gray-900">
                <li>
                  <a href="https://www.gofundme.com/f/sav-inclusive-expansion-campaign-2024">
                    {" "}
                    Make a Donation
                  </a>
                </li>
                <li>
                  <p className="font-extrabold">
                    <a href="https://www.rockhousefoundation.org/events">
                      Visit our Events Page and Support or Join our next Event
                    </a>
                  </p>
                </li>
                <li>
                  <p>
                    Visit the Rockhouse or Skylark and take the school TOUR and
                    see the construction progress and help spread the word about
                    our community work - and while coming pack with purpose and
                    bring items for the schools from our{" "}
                    <a href="https://www.rockhousefoundation.org/RHF_WishList_091624.pdf">
                      Wish List
                    </a>
                  </p>
                </li>
                <li>
                  <p>
                    Customized Volunteer Opportunities are available also with
                    advance notice
                  </p>
                </li>
              </ul>
              <Typography>
                The mantra for Sav Inclusive and the Rockhouse Foundation is,
                “We are Family”. WELCOME TO THE FAMILY!
              </Typography>
            </div>
          }
          images={[
            "https://res.cloudinary.com/dfwvu4gct/image/upload/v1688861715/RHF/family3_jyfead.png",
            "https://res.cloudinary.com/dfwvu4gct/image/upload/v1688861755/RHF/family4_zsakki.png",
          ]}
        />
        <SummaryCard
          setSelected={setSelected}
          content={
            <div className="text-gray-900">
              <Typography variant="h2" marginBottom={5}>
                OUR MISSION
              </Typography>
              <p className="text-gray-900 italic font-semibold">
                Rockhouse Foundation transforms the places where Jamaica’s
                children learn and supports the great people who teach them.
              </p>
              <p className="text-gray-900 ">
                Since its establishment in 2004 the Rockhouse Foundation has
                invested over US$10 million to help improve schools in western
                Jamaica.
              </p>
              <p className="text-gray-900 ">
                The Foundation has completely transformed and modernized six
                schools: Moreland Hill Primary and Infant School, Negril All Age
                School, Negril Basic School, Little Bay All Age and Infant
                School, Bunch of Stars E.C.I., and Savanna-la-Mar Inclusive
                Infant Academy (Sav Inclusive), in addition to the complete
                renovation and expansion of the Negril Community Library.
              </p>
              <p className="text-gray-900 ">
                During the global COVID-19 pandemic, with schools closed and
                communities struggling, the Foundation pivoted to provide
                emergency food supplies to 1000 people weekly through our
                partner network, with over 450 tons of food staples distributed
                during the 20 months schools were closed.
              </p>
              <p className="text-gray-900">
                Rockhouse Foundation is a registered 501(c)(3) based in New York
                State. In 2012, The Foundation also established a consolidated
                companion entity in Jamaica under the Registered Charities act,
                Rockhouse Foundation Jamaica, as a vehicle for local donations
                and a more efficient strategy for tax-exempt purchases in
                Jamaica.
              </p>
            </div>
          }
          images={[
            "https://res.cloudinary.com/dfwvu4gct/image/upload/v1688862698/RHF/Screenshot_2023-07-08_at_7.52.29_PM_exqdvr.png",
          ]}
        />
        <SummaryCard
          setSelected={setSelected}
          content={
            <div className="text-gray-900">
              <Typography variant="h2" marginBottom={5}>
                OUR PROJECTS
              </Typography>
              <p className="text-gray-900">
                Rockhouse Foundation partnerships are driven by extensive
                dialogue with community members before a scope of work is
                mutually developed. Once a building, renovation or development
                project is complete, The Foundation ’s ongoing support typically
                falls into several categories:
              </p>
              <ul className="list-disc space-y-4 font-extrabold text-gray-900">
                <li>
                  <p className="text-gray-900 ">
                    Free school breakfast, and lunch
                  </p>
                </li>
                <li>
                  <p className="text-gray-900 ">
                    Wage subsidy for instructional personnel to achieve good
                    student:teacher ratios
                  </p>
                </li>
                <li>
                  <p className="text-gray-900 ">
                    As needed assistance with utility costs, e.g., water,
                    electricity
                  </p>
                </li>
                <li>
                  <p className="text-gray-900 ">
                    Facilities repairs and maintenance as needed, e.g.,
                    furniture, plumbing, electrical, etc.
                  </p>
                </li>
                <li>
                  <p className="text-gray-900 ">
                    eSpark, a computer-based literacy and numeracy program, with
                    stable internet and devices for all children.
                  </p>
                </li>
              </ul>
              <p>
                The Rockhouse Foundation typically undertakes a new significant
                capital development project every few years.
              </p>
            </div>
          }
          images={[
            "https://res.cloudinary.com/dfwvu4gct/image/upload/v1688864415/RHF/Screenshot_2023-07-08_at_7.52.44_PM_l3jytp.png",
          ]}
        />
        <Stack spacing={2} bgcolor="#F2EDE0">
          <img
            onClick={() =>
              setSelected(
                "https://res.cloudinary.com/dfwvu4gct/image/upload/v1688864628/RHF/Screenshot_2023-07-08_at_9.02.11_PM_uw8tvy.png"
              )
            }
            src="https://res.cloudinary.com/dfwvu4gct/image/upload/v1688864628/RHF/Screenshot_2023-07-08_at_9.02.11_PM_uw8tvy.png"
            alt=""
          />
        </Stack>
        <SummaryCard
          setSelected={setSelected}
          content={
            <div className="text-gray-900">
              <Typography variant="h2" marginBottom={5}>
                SAVANNA-LA-MAR INCLUSIVE INFANT ACADEMY (SAV INCLUSIVE)
              </Typography>
              <p className="text-gray-900">
                In 2017 Rockhouse Foundation opened Jamaica’s only public school
                serving children with and without disabilities in a full
                inclusion setting. This is the first project the Foundation has
                conceived and built from the ground up, supporting a chronically
                underserved and stigmatized population. The Foundation
                underwrote the design and construction, and maintains the
                facilities, with the Ministry of Education and Youth
                underwriting the faculty, the therapeutic staff and some support
                personnel. With the success of Sav Inclusive, in 2020, the
                Foundation entered an agreement with the Ministry to expand the
                school through high school, with adjoining grounds on an
                expanded but contiguous footprint.
              </p>

              <p>
                Sav Inclusive currently has 225 age three through grade 4
                students, with an anticipated ultimate population of 650 in
                pre-K through high school when fully completed. Sav Inclusive's
                waitlist exceeds its current enrolment, reflecting its
                reputation and the enormous need for its unique inclusive model.
              </p>
              <p>
                Leadership and staff emphasize 'Love, Family and High
                Expectations', along with quality instruction and both physical
                and speech language therapies.
              </p>
            </div>
          }
          images={[
            "https://res.cloudinary.com/dfwvu4gct/image/upload/v1688864750/RHF/Screenshot_2023-07-08_at_7.53.01_PM_uq8juy.png",
            "https://res.cloudinary.com/dfwvu4gct/image/upload/v1688864819/RHF/Screenshot_2023-07-08_at_9.06.46_PM_hsuttl.png",
          ]}
        />
        <SummaryCard
          setSelected={setSelected}
          content={
            <div className="text-gray-900">
              <Typography variant="h2" marginBottom={5}>
                MORELAND HILL PRIMARY SCHOOL
              </Typography>
              <p className="text-gray-900">
                After decades of neglect, the Moreland Hill Primary School had
                an enrollment of just 32 students and was slated for closure by
                Jamaica’s Ministry of education. Over the summer of 2013, the
                Foundation completed a total transformation of the Moreland Hill
                Primary School, alongside a dynamic new principal, welcomed 120
                pre-K to 6th grade students who had previously traveled long
                distances in search of a better education that all children
                deserve. Since that initial investment the Foundation has
                continued to maintain and support the school adding a new
                computer room, classrooms to accommodate growing enrollment and
                sanitized water harvesting. Rockhouse Foundation continues its
                monthly financial support for ongoing maintenance, nutrition,
                teacher subsidy and the online learning platform, eSpark.
              </p>
            </div>
          }
          images={[
            "https://res.cloudinary.com/dfwvu4gct/image/upload/v1688864935/RHF/Screenshot_2023-07-08_at_9.08.41_PM_f4d1i0.png",
            "https://res.cloudinary.com/dfwvu4gct/image/upload/v1688864947/RHF/Screenshot_2023-07-08_at_7.53.36_PM_thje1w.png",
          ]}
        />
        <SummaryCard
          setSelected={setSelected}
          content={
            <div className="text-gray-900">
              <Typography variant="h2" marginBottom={5}>
                LITTLE BAY ALL AGE SCHOOL
              </Typography>
              <p className="text-gray-900">
                The Little Bay All Age School, like the community, was full of
                promise but when the Rockhouse Foundation began its work in 2010
                it had seen better days. The complete gut renovation and
                expansion ushered in a sense of pride and possibilities that are
                embedded in the school community today. A new technology center
                and teachers feed the minds, and a revitalized facility feeds
                the community spirit.
              </p>
            </div>
          }
          images={[
            "https://res.cloudinary.com/dfwvu4gct/image/upload/v1666525833/RHF/kids-gren-projects_bayfs3.jpg",
            "https://res.cloudinary.com/dfwvu4gct/image/upload/v1688865088/RHF/Screenshot_2023-07-08_at_9.10.58_PM_fpz1ew.png",
          ]}
        />
        <SummaryCard
          setSelected={setSelected}
          content={
            <div className="text-gray-900">
              <Typography variant="h2" marginBottom={5}>
                BUNCH OF STARS EARLY CHILDHOOD INSTITUTION
              </Typography>
              <p className="text-gray-900">
                Rockhouse Foundation fully renovated and expanded Bunch of Stars
                School beginning in 2012-2013, providing new classroom space,
                kitchen and sanitation facilities, a secure compound, and a
                school garden. Bunch of Stars ECI currently has an enrollment of
                47 children and is one of only 10% of pre-k schools nationwide,
                along with Sav Inclusive, to have achieved the arduous and
                prestigious Early Childhood Commission Certification status.
              </p>
            </div>
          }
          images={[
            "https://res.cloudinary.com/dfwvu4gct/image/upload/v1637844418/RHF/RHF_2Column_570x429_OUR-WORK_BunchOfStars_r5kd9r.jpg",
            "https://res.cloudinary.com/dfwvu4gct/image/upload/v1688865239/RHF/Screenshot_2023-07-08_at_7.53.56_PM_o2jbsu.png",
          ]}
        />
        <SummaryCard
          setSelected={setSelected}
          content={
            <div className="text-gray-900">
              <Typography variant="h2" marginBottom={5}>
                ST. PAUL'S PRIMARY
              </Typography>
              <p className="text-gray-900">
                St. Paul's Primary received Food Distribution during the
                pandemic closure. Based on its experience with the school staff,
                leadership and community, St. Paul;s Primary was added to the
                Rockhouse Foundation network. Rockhouse Foundation assists St.
                Paul's Primary with repairs and upgrades, including major
                repairs to the kitchen, staff bathroom, drainage and facilities
                beautification. In addition to repurposing an unused classroom
                as the new computer room, the Foundation underwrites the eSpark
                coordinator, and purchase of 25 Chromebooks.
              </p>
            </div>
          }
          images={[
            "https://res.cloudinary.com/dfwvu4gct/image/upload/v1688865323/RHF/Screenshot_2023-07-08_at_7.54.10_PM_ae9zi7.png",
            "https://res.cloudinary.com/dfwvu4gct/image/upload/v1688865336/RHF/Screenshot_2023-07-08_at_7.54.20_PM_swvodp.png",
          ]}
        />
        <SummaryCard
          setSelected={setSelected}
          content={
            <div className="text-gray-900">
              <Typography variant="h2" marginBottom={5}>
                NEGRIL PUBLIC LIBRARY
              </Typography>
              <p className="text-gray-900">
                In 2009, after extensive consultation and with the Jamaica
                Library Service and the “Friends of the Library” committee, the
                Rockhouse Foundation modernized and expanded the landmark
                structure, creating Negril's first new civic building in more
                than 40 years. Rockhouse Foundation undertook a complete
                renovation and expansion of the Negril Public Library. The
                Jamaica Library Service (JLS) underwrites most of the library's
                operating costs.
              </p>
            </div>
          }
          images={[
            "https://res.cloudinary.com/dfwvu4gct/image/upload/v1688865337/RHF/Screenshot_2023-07-08_at_7.54.35_PM_ntjc86.png",
            "https://res.cloudinary.com/dfwvu4gct/image/upload/v1688865337/RHF/Screenshot_2023-07-08_at_7.54.51_PM_taoskx.png",
          ]}
        />
        {/* <SummaryCard
          setSelected={setSelected}
          content={
            <div className="text-gray-900">
              <Typography variant="h2" marginBottom={5}>
                COVID RELIEF FOOD DISTRIBUTION PROGRAM
              </Typography>
              <p className="text-gray-900">
                In response to the COVID pandemic the Rockhouse Foundation in
                March 2020 began weekly distributions of food staples to the
                most vulnerable families at our partner schools. For many
                children most of their daily nutrition is normally received
                through the school breakfast and lunch programs, but with
                schools closed the Foundation pivoted to fill this void. The
                Foundation's COVID Relief Food Distribution Program delivered
                over 250 tons of food and helped to sustain over 1000 people
                weekly throughout the pandemic. The program began at four of our
                partner schools and an additional four schools were added in
                December 2020 with these eight schools participating throughout
                2021. The initiative ended in December 2021 after more than 80
                consecutive weeks, making it one of Jamaica's most robust and
                effective programs.
              </p>
            </div>
          }
          images={[
            "https://res.cloudinary.com/dfwvu4gct/image/upload/v1688865499/RHF/Screenshot_2023-07-08_at_7.55.05_PM_tif8aw.png",
            "https://res.cloudinary.com/dfwvu4gct/image/upload/v1688865499/RHF/Screenshot_2023-07-08_at_7.55.17_PM_nwdy7t.png",
          ]}
        /> */}
        <Stack spacing={2} bgcolor="#F2EDE0">
          <img
            onClick={() =>
              setSelected(
                "https://res.cloudinary.com/dfwvu4gct/image/upload/v1688865499/RHF/Screenshot_2023-07-08_at_7.56.04_PM_qrvxop.png"
              )
            }
            src="https://res.cloudinary.com/dfwvu4gct/image/upload/v1688865499/RHF/Screenshot_2023-07-08_at_7.56.04_PM_qrvxop.png"
            alt=""
          />
        </Stack>
        <Stack spacing={4} bgcolor="#F2EDE0">
          <img
            onClick={() =>
              setSelected(
                "https://res.cloudinary.com/dfwvu4gct/image/upload/v1718023996/RHF/summary/finan1_eg4jx1.jpg"
              )
            }
            src="https://res.cloudinary.com/dfwvu4gct/image/upload/v1718023996/RHF/summary/finan1_eg4jx1.jpg"
            alt=""
          />
          <img
            onClick={() =>
              setSelected(
                "https://res.cloudinary.com/dfwvu4gct/image/upload/v1718023996/RHF/summary/finan2_sfuupz.jpg"
              )
            }
            src="https://res.cloudinary.com/dfwvu4gct/image/upload/v1718023996/RHF/summary/finan2_sfuupz.jpg"
            alt=""
          />
          {/* <SummaryCard
          setSelected={setSelected}
          content={
            <div className="text-gray-900">
              <Typography variant="h2" marginBottom={5}>
                OUR FUNDRAISERS
              </Typography>
              <p className="text-gray-900">
                Enter the Rockhouse Foundation Raffle and win a trip to Jamaica!
                First Prize (a week at Rockhouse) and Second Prize (a week at
                Skylark) both include airfare from JetBlue. See a full listing
                of the ten prizes at our <a href="/events">Events Page</a> For
                each $50 donated to our Summer Construction Project expanding
                the Sav Inclusive School, you receive an entry in the raffle.
              </p>

              <p className="text-gray-900">
                On Saturday, July 15th, 2023 we’re teaming up with our friend,
                chef Eric Adjepong, for a special guest chef dinner on the beach
                at Skylark Negril Beach Resort in Jamaica. A first generation
                Ghanaian-American, chef Eric Adjepong is celebrated for infusing
                West African flavors into his dishes. Chef Eric has cooked in
                various Michelin-starred restaurants, was a finalist on Top
                Chef, and can now be found hosting Alex vs America on Food
                Network. Eric will prepare a three course dinner with welcome
                canapés, celebrating the flavors of his West African roots
                alongside the best of Jamaica’s local produce, much of which is
                sourced from Rockhouse’s new on-site hydroponic farm.{" "}
                <a href="https://rockhousefoundation.networkforgood.com/events/57708-dinner-on-the-beach-with-chef-eric-adjepong">
                  Buy Tickets Here
                </a>
              </p>
              <p>
                The July 15th dinner ends with the highly-anticipated drawing of
                Rockhouse’s annual Raffle (each ticket purchase includes an
                entry in the raffle) to be followed by an after party at Miss
                Lily’s at Skylark with DJ Max Glazer.
              </p>
            </div>
          }
          images={[
            "https://res.cloudinary.com/dfwvu4gct/image/upload/v1688988122/RHF/Screenshot_2023-07-10_at_7.21.19_AM_on2axu.png",
            "https://res.cloudinary.com/dfwvu4gct/image/upload/v1688988205/RHF/Screenshot_2023-07-10_at_7.23.13_AM_vblg9f.png",
          ]}
        /> */}
          {/* <Stack spacing={4} bgcolor="#F2EDE0">
          <img
            onClick={() =>
              setSelected(
                "https://res.cloudinary.com/dfwvu4gct/image/upload/v1688865497/RHF/Screenshot_2023-07-08_at_7.56.43_PM_j9siks.png"
              )
            }
            src="https://res.cloudinary.com/dfwvu4gct/image/upload/v1688865497/RHF/Screenshot_2023-07-08_at_7.56.43_PM_j9siks.png"
            alt=""
          />
          <img
            onClick={() =>
              setSelected(
                "https://res.cloudinary.com/dfwvu4gct/image/upload/v1688865497/RHF/Screenshot_2023-07-08_at_7.57.12_PM_ibabwf.png"
              )
            }
            src="https://res.cloudinary.com/dfwvu4gct/image/upload/v1688865497/RHF/Screenshot_2023-07-08_at_7.57.12_PM_ibabwf.png"
            alt=""
          /> */}
        </Stack>
        <SummaryCard
          setSelected={setSelected}
          content={
            <div className="text-gray-900 text-md">
              <Typography variant="h2" marginBottom={5}>
                BOARD MEMBERS
              </Typography>
              <p className="text-gray-900">NICOLE COSBY</p>
              <p>IAN COVILLE</p>

              <p className="text-gray-900">FABRIZIO IPPOLITI</p>
              <p>SAMUEL JACOB, TREASURER</p>
              <p>MAX GLAZER</p>
              <p>SHANA JOHNSON</p>
              <p>INISE LAWRENCE </p>
              <p>KAYSILYN LAWSON</p>
              <p>SEAN LEGISTER</p>
              <p>JULIE MONACO</p>
              <p>PETER PICARD PETER B. ROSE, PRESIDENT </p>
              <p>DAMIAN SALMON</p>
              <p>PAUL SALMON, CHAIRMAN </p>
              <p>PETER SCUTT</p>
              <p>AISHA STEINER</p>
              <p>TAMARRA WALKER </p>
              <p>CHARLOTTE WALLACE </p>
              <p>CHRIS WALSH</p>
            </div>
          }
          images={[
            "https://res.cloudinary.com/dfwvu4gct/image/upload/v1688866206/RHF/Screenshot_2023-07-08_at_9.29.52_PM_ulqpby.png",
            "https://res.cloudinary.com/dfwvu4gct/image/upload/v1688866205/RHF/Screenshot_2023-07-08_at_9.29.40_PM_y71old.png",
          ]}
        />
        <Stack spacing={4} bgcolor="#F2EDE0">
          <img
            onClick={() =>
              setSelected(
                "https://res.cloudinary.com/dfwvu4gct/image/upload/v1688866350/RHF/Screenshot_2023-07-08_at_9.31.59_PM_q6j8ff.png"
              )
            }
            src="https://res.cloudinary.com/dfwvu4gct/image/upload/v1688866350/RHF/Screenshot_2023-07-08_at_9.31.59_PM_q6j8ff.png"
            alt=""
          />
        </Stack>
      </Stack>
      {selected && (
        <ImageModal selectedImg={selected} setSelectedImg={setSelected} />
      )}
    </div>
  );
}

export default Summary;

const SummaryCard = ({ images = null, content = null, setSelected }) => {
  return (
    <>
      <div className="grid md:grid-cols-2 grid-cols-1">
        <Stack
          spacing={4}
          pt={{ md: 10, xs: 4 }}
          px={4}
          alignItems="center"
          bgcolor="#F2EDE0"
          className="text-gray-900"
        >
          {content}
        </Stack>
        <Stack spacing={2} bgcolor="#F2EDE0">
          {images?.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={image}
              onClick={() => setSelected(image)}
            />
          ))}
        </Stack>
      </div>
    </>
  );
};

import React, { useEffect } from "react";
import { useState } from "react";
import ImageGrid from "../../components/features/ImageGrid";
import ImageModal from "../../components/features/ImageModal";
import Seo from "../../components/layout/Seo";
import CustomDialog from "../../components/CustomDialog";

const images = [
  {
    img: "/rockhouse-foundation-benefit1.jpg",
  },
  {
    img: "/rockhouse-foundation-benefit2.jpg",
  },
  {
    img: "/rockhouse-foundation-sav.jpg",
  },
  {
    img: "/rockhouse-foundation-school-kids.jpg",
  },
  {
    img: "/rockhouse-foundation-school.jpg",
  },
  {
    img: "/rockhouse-foundation-school1.jpg",
  },
  {
    img: "/rockhouse-foundation-school2.jpg",
  },
  {
    title: "",
    img: "https://res.cloudinary.com/dfwvu4gct/image/upload/v1665925378/RHF/RHF_510x384_HISTORY_B_xtzvyf.jpg",
  },
  {
    title: "",
    img: "https://res.cloudinary.com/dfwvu4gct/image/upload/v1666473751/RHF/RHF_2Column_570x429_OUR-WORK_Savanna-La_Mar_hnt2cd.jpg",
  },
  {
    img: "https://res.cloudinary.com/dfwvu4gct/image/upload/v1637844418/RHF/RHF_2Column_570x429_OUR-WORK_BunchOfStars_r5kd9r.jpg",
  },
  {
    img: "https://res.cloudinary.com/dfwvu4gct/image/upload/v1666474823/RHF/RHF_2Column_570x429_OUR-WORK_NegrilBasicPreK_iadz9g.jpg",
  },
  {
    img: "https://res.cloudinary.com/dfwvu4gct/image/upload/v1637844418/RHF/RHF_2Column_570x429_OUR-WORK_LittleBay_i4br5l.jpg",
  },
  {
    img: "https://res.cloudinary.com/dfwvu4gct/image/upload/v1637844418/RHF/RHF_2Column_570x429_OUR-WORK_TechBased-1_ttttul.jpg",
  },
  {
    img: "https://res.cloudinary.com/dfwvu4gct/image/upload/v1666475981/RHF/kids-smiling-rockhouse-foundation_chizxb.jpg",
  },
  {
    img: "https://res.cloudinary.com/dfwvu4gct/image/upload/v1666476051/RHF/kids-school-rockhouse_nuwtan.jpg",
  },
  {
    img: "https://res.cloudinary.com/dfwvu4gct/image/upload/v1666525833/RHF/small-kids-uniform-rockhouse-foundation_eq4ymq.jpg",
  },
  {
    img: "https://res.cloudinary.com/dfwvu4gct/image/upload/v1666525833/RHF/kids-in-pink-unifrom-rockhouse-foundation_avtko4.jpg",
  },
  {
    img: "https://res.cloudinary.com/dfwvu4gct/image/upload/v1666525833/RHF/small-boys-rockhouse-foundation_czgyut.jpg",
  },
  {
    img: "https://res.cloudinary.com/dfwvu4gct/image/upload/v1666525833/RHF/kids-in-uniform-rockhouse-foundation_xyelwt.jpg",
  },
];

const Gallery = () => {
  const [selectedImg, setSelectedImg] = useState(null);
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
    setSelectedImg(null);
  };

  useEffect(() => {
    if (selectedImg) {
      setOpen(true);
    }
  }, [selectedImg]);

  return (
    <div>
      <Seo title="Gallery" />
      <div className="container py-10 z-0">
        <ImageGrid setSelectedImg={setSelectedImg} images={images} />
        {/* {selectedImg && (
          <ImageModal
            selectedImg={selectedImg}
            setSelectedImg={setSelectedImg}
          />
        )} */}
        <CustomDialog open={open} handleClose={handleClose}>
          {/* show selected image */}
          <img
            src={selectedImg}
            alt="enlarged pic"
            className="object-contain w-full h-auto max-w-xl mt-3"
          />
        </CustomDialog>
      </div>
    </div>
  );
};

export default Gallery;

import React from "react";
import StyledTitle from "./StyledTitle";
import BootstrapCarouselNew from "./BootstrapCarouselNew";
import BootstrapCarousel from '../layout/BootstrapCarousel'



const Pages = ({ children, imageUrl, title = "", mobileSrc = null, useNew = false }) => {
  return (
    <div>
      {
        useNew ? <BootstrapCarouselNew images={[{ src: imageUrl, mobileSrc: mobileSrc }]} /> :
          <BootstrapCarousel images={[{ src: imageUrl }]} />
      }
      {/* https://res.cloudinary.com/dfwvu4gct/image/upload/v1714410264/RHF/Screenshot_2024-04-29_at_1.04.01_PM_jpqwtp.png */}

      <div className="py-4 mb-4 container">
        {title !== "" && <StyledTitle title={title} />}
        {children}
      </div>
    </div>
  );
};

export default Pages;

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import EventItem from "./EventItem";
import EventItemReverse from "./EventItemReverse";
import StyledTitle from "../StyledTitle";
import { fetchEvents } from "../../../features/events/eventSlice";
import { Box, Stack } from "@mui/material";

// {
//     "id": "9735745e-103a-4a1c-abd3-b7f03bbede7c",
//     "pkid": 2,
//     "title": "test 2",
//     "subtitle": "INCLUDING THE ROCKHOUSE RAFFLE DRAW",
//     "date": "November 2021 NYC",
//     "text": "efdfg gwaf",
//     "text2": "",
//     "video_url": null,
//     "is_video": false,
//     "image": "https://res.cloudinary.com/dfwvu4gct/image/upload/v1/mediafiles/events/F0-DbJoaIAAR0Uu_jpmfuo",
//     "btn_text": null,
//     "btn_url": null,
//     "img_left": false,
//     "list_items": [],
//     "category": "Previous Events"
// },

function PreviousEvents() {
  // fetch previous events from database
  const dispatch = useDispatch();
  const { events } = useSelector((state) => state.events);

  useEffect(() => {
    dispatch(fetchEvents());
  }, []);
  console.log(events);
  return (
    <Stack spacing={4}>
      <StyledTitle title="PREVIOUS EVENTS" />

      {events
        ?.filter((evt) => evt.category === "Previous Events")
        .map(
          (
            {
              btn_url,
              id,
              list_items,
              image,
              btn_text,
              text,
              text2,
              title,
              subtitle,
              is_video,
              video_url,
              img_left,
              date,
            },
            i
          ) =>
            img_left ? (
              <EventItem
                key={i}
                title={title}
                subtitle={subtitle}
                date={date}
                text={text}
                text2={text2}
                videoUrl={video_url}
                btnText={btn_text}
                btnUrl={btn_url}
                video={is_video}
                hasBtn={!!btn_url}
                src={image}
                listItems={list_items}
              />
            ) : (
              <EventItemReverse
                key={i}
                title={title}
                subtitle={subtitle}
                date={date}
                text={text}
                text2={text2}
                videoUrl={video_url}
                btnText={btn_text}
                btnUrl={btn_url}
                video={is_video}
                src={image}
                hasBtn={!!btn_url}
                listItems={list_items}
              />
            )
        )}
    </Stack>
  );
}

export default PreviousEvents;

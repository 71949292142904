import { Box, Typography } from "@mui/material";
import React from "react";

import RHButtonText from "../RHButtonText";

const MainBlog = ({
  title,
  src,
  text,
  link,
  variant = "h5",
  border = false,
}) => {
  return (
    <div
      className={`${border ? "border border-inherit rounded-lg" : ""} m-0 p-0`}
    >
      <img src={src} className="object-contain w-full" alt={title} />

      {/* Title */}
      <Box className={`my-3 p-2 `}>
        <Typography variant="h4" color="secondary">
          {title}
        </Typography>
        <Typography variant="body1">
          {text}... <RHButtonText url={link} text="read more" />
        </Typography>
      </Box>
    </div>
  );
};

export default MainBlog;

import axios from "axios";
import { getToken, saveToken, getRefreshToken } from "./tokenService";

export const apiConfig = (type, token) => ({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  headers: {
    "Content-Type": type,
    Authorization: `Bearer ${token || ""}`,
  },
});

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  //   headers: {
  //     "Content-Type": "application/json",
  //   },
});

api.interceptors.request.use(
  (config) => {
    const token = getToken()?.access;
    // get type
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response Interceptor: Refresh token if expired
api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    // Check if it was a POST request and the token has expired (error 401)
    if (
      originalRequest?.method === "post" &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;

      // Call the token refresh endpoint with the expired token
      const refreshToken = getRefreshToken();
      const res = await axios.post("/token/refresh/", {
        refresh: refreshToken,
      });

      // Save the new token
      saveToken(res.data);

      // Modify the original request's headers
      originalRequest.headers["Authorization"] = `Bearer ${res.data.access}`;

      // Retry the original request with the new token
      return api(originalRequest);
    }

    return Promise.reject(error);
  }
);

export default api;

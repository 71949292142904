import { Box, Typography } from "@mui/material";
import React from "react";
import VideoPlayer from "../../components/layout/VideoPlayer";
import PageCallouts from "../../components/PageCallouts";
import BootstrapCarousel from "../../components/layout/BootstrapCarousel";

const MarcusSkylark = () => {
  return (
    <div>
      <BootstrapCarousel
        images={[
          {
            src: "https://res.cloudinary.com/dfwvu4gct/image/upload/v1665929632/RHF/RHF_BlogHeader1500x609_Marcus_O_x4mdz8.jpg",
            name: "MARCUS SAMUELSSON DINNER AT SKYLARK - Rockhouse Foundation",
          },
        ]}
      />

      <div className="container my-4">
        <Box className="grid grid-cols-1 md:grid-cols-4 gap-7">
          <Box className="md:col-span-3">
            <Typography variant="h1" color="secondary" className="mb-2">
              MARCUS SAMUELSSON DINNER AT SKYLARK
            </Typography>

            <Typography variant="body1">
              What would you say Ethiopia and Jamaica have in common? A whole
              lot more after Sunday, December 29 when Ethiopian-born chef Marcus
              Samuelsson hosted an Ethopian-Jamaican fusion four-course meal for
              the annual Rockhouse Foundation’s Benefit Dinner! The dinner took
              place at the iconic Miss Lily’s’ restaurant’s new outpost in
              Negril, in none other than Skylark, the newly opened spunky
              younger-sister property of Rockhouse. The evening’s menu was a
              face-off between the two countries’ cuisines, but the real winning
              ticket was the over- J$4 million (US$30,000) raised by guests for
              the Rockhouse Foundation’s initiative to improve education in
              western Jamaica. With all of the evenings costs underwritten by
              Rockhouse, Skylark and Miss Lily’s all the funds raised will be
              put towards expanding the Sav-Inclusive, a new Rockhouse
              Foundation built school supporting students with disabilities.
            </Typography>
            <Box className="my-3">
              <VideoPlayer url="https://www.youtube.com/embed/u5_sjuJ9ivI" />
            </Box>
            <Typography variant="body1">
              At sunset, 100 guests sipped on Gyaltego Bay and Dutty Wine rum
              cocktails, cooled down with Sky Juice alcoholic shave dice, and
              munched on reinterpreted international dishes, such as a lobster
              roll served on a warm coco bread and a smoked marlin summer roll
              served with a spicy peanut sauce. The hors d’oeuvres menu was
              designed by the evening’s co-collaborator, Jamaica’s own Andre
              Fowler, executive chef for Miss Lily’s restaurants and two-time
              winner of the <i>Food Network’s Chopped.</i>
            </Typography>

            <Typography variant="body1">
              For the first main course, Samuelsson had no question in his mind
              that the menu needed to start off paying homage to Jamaica’s
              seafood. A local red snapper grilled on the open fire was served
              with a paella made with coconut rice and peas and squid, crab,
              clams and shrimp aptly called “ocean rice and peas.” “There’s
              incredible seafood in Jamaica that should be highlighted, so of
              course that’s what we started with,” said the six-time James
              Beard-winning chef, who took time off from his award-winning
              Harlem-based restaurant Red Rooster to travel to Negril with his
              wife and young son.
            </Typography>
            <Typography variant="body1">
              After visiting the Rockhouse and Negril for over 15 years,
              Samuelsson has developed a deep love for Jamaica and its culture.
              “Negril is a special place to me. I came here 15 years ago and now
              I have brought all of my friends; some have gotten married here,
              and now I want my son to know it.”
            </Typography>
            <Typography>
              The second main dish of the evening reflected his belief that
              Jamaica and Ethiopia share an incredible connection. The chef
              interpreted goat two ways, one in the Jamaican fashion of a
              slow-simmer curry, and also in the Ethopian fashion with goat
              roasted over the fire in a jerk-berbere sauce. The dishes were
              served family-style, heightening the intimate feel of the evening.
              “Our countries share so much in common: talented people, a rich
              culture, a love for music,” he mentioned, waving a hand in
              acknowledgement of the Silver Birds Steel Orchestra, the calypso
              band that provided the melodic backdrop for the evening.
            </Typography>
            <Typography>
              But Jamaica and Ethiopia share more in common than this.
              Samuelsson also relates to the hard economic circumstances in
              Jamaica that too often limits the potential of the citizenry. “I
              came from humble beginnings in a hut in Ethiopia and I can relate
              to the tough environment Jamaica and Ethiopia share, and I want to
              be able to help …. sometimes all you need is a little extra help,”
              he expressed.
            </Typography>
            <Typography>
              This ethos is shared by the Rockhouse Foundation, which is
              celebrating 15 years of transforming the places where Jamaica’s
              children learn, and supports the people who teach them. The
              Foundation has invested over J$700 million (US$5 million) to
              build, transform and modernise six local schools, in addition to
              the renovation and expansion of the Negril Community Library.
            </Typography>
          </Box>
          <Box>
            <PageCallouts />
          </Box>
        </Box>
      </div>
    </div>
  );
};

export default MarcusSkylark;

import { Stack } from "@mui/material";
import React from "react";
import PageCallout from "./PageCallout";

const PageCallouts = () => {
  return (
    <Stack spacing={3} justifyContent="center" alignItems="center">
      <PageCallout
        page="About"
        src="https://res.cloudinary.com/dfwvu4gct/image/upload/v1665753875/RHF/RHF_Blog_700x527_ABOUT-1_tdywpq.jpg"
        link="/blog/all-about-the-rockhouse-foundation/"
      />
      <PageCallout
        page="Our Work"
        src="https://res.cloudinary.com/dfwvu4gct/image/upload/v1665753875/RHF/RHF_Blog_700x527_OUR-WORK-1_cjysh1.jpg"
        link="/our-work"
      />
      <PageCallout
        page="Events"
        src="https://res.cloudinary.com/dfwvu4gct/image/upload/v1665753875/RHF/RHF_Blog_700x527_EVENTS-1_azsmwi.jpg"
        link="/events"
      />
      <PageCallout
        page="Donate"
        src="https://res.cloudinary.com/dfwvu4gct/image/upload/v1665753875/RHF/RHF_Blog_700x527_DONATE-1_zgd1cg.jpg"
        external={true}
        link="https://www.gofundme.com/f/rockhouse-foundation-summer-2023-building-project"
      />
    </Stack>
  );
};

export default PageCallouts;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../components/api/axiosApi";

const REGISTER_URL = `/v1/auth/users/`;
const LOGIN_URL = `/v1/auth/jwt/create/`;
const ACTIVATE_URL = `/v1/auth/users/activation/`;
const USER_URL = `/v1/auth/users/me/`;
const ALL_USERS = `/users/all`;
const REFRESH_URL = `/v1/auth/jwt/refresh/`;
const VERIFY_TOKEN = `/v1/auth/jwt/verify/`;

const ADMIN_STATUS_URL = `/check_status`;
const SEND_RESET_PASSWORD_EMAIL = `/v1/auth/users/reset_password/`;
const RESET_PASSWORD_CONFIRMATION_URL = `/v1/auth/users/reset_password_confirm/`;

// Define the initial state of the auth slice
const initialState = {
  user: null,
  accessToken: null,
  refreshToken: null,
  status: "idle",
  error: null,
  message: null,
  isAdmin: false,
  currentUser: null,
  openDialog: false,
};

// Define the login async thunk
export const login = createAsyncThunk(
  "auth/login",
  async ({ email, password }, { rejectWithValue }) => {
    try {
      const response = await api.post(`${LOGIN_URL}`, {
        email,
        password,
      });
      if (!response.status === "200") {
        const data = await response.data;
        return rejectWithValue(data);
      }
      const data = await response.data;
      localStorage.setItem("user", JSON.stringify(data));
      localStorage.setItem("accessToken", data.access);
      localStorage.setItem("refreshToken", data.refresh);
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Define the register async thunk
export const register = createAsyncThunk(
  "auth/register",
  async ({ email, password }, { rejectWithValue }) => {
    try {
      const response = await api.post(`${REGISTER_URL}`, { email, password });
      if (!response.status === 201) {
        const data = await response.data;
        return rejectWithValue(data);
      }
      const data = await response.data;
      localStorage.setItem("user", JSON.stringify(data));

      localStorage.setItem("accessToken", data.access);
      localStorage.setItem("refreshToken", data.refresh);
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Get user
export const userInfo = createAsyncThunk("auth/userInfo", async (thunkAPI) => {
  try {
    const response = await api.get(USER_URL);
    return response.data;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();

    return thunkAPI.rejectWithValue(message);
  }
});

//  logout
export const logout = createAsyncThunk("auth/logout", async () => {
  localStorage.removeItem("user");
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
});

// Define the auth slice
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setOpenDialog(state, { payload }) {
      state.openDialog = payload;
    },
    setAdminStatus(state, { payload }) {
      state.isAdmin = payload;
    },
  },
  extraReducers: (builder) => {
    // Handle login pending state
    builder.addCase(login.pending, (state) => {
      state.status = "loading";
      state.error = null;
    });
    // Handle login fulfilled state
    builder.addCase(login.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.user = action.payload;
      state.accessToken = action.payload.access;
      state.refreshToken = action.payload.refresh;
    });
    // Handle login rejected state
    builder.addCase(login.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    });
    // Handle register pending state
    builder.addCase(register.pending, (state) => {
      state.status = "loading";
      state.error = null;
    });
    // Handle register fulfilled state
    builder.addCase(register.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.user = action.payload;
      state.accessToken = action.payload.access;
      state.refreshToken = action.payload.refresh;
    });
    // Handle register rejected state
    builder.addCase(register.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    });
    //
    builder.addCase(userInfo.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(userInfo.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.currentUser = action.payload;
      if (action.payload?.is_admin) {
        state.isAdmin = true;
      }
    });
    builder.addCase(userInfo.rejected, (state, action) => {
      state.isLoading = false;
      state.error = true;
      state.message = action.payload;
      state.isAdmin = false;
    });
    builder.addCase(logout.fulfilled, (state) => {
      state.user = null;
      state.accessToken = null;
      state.refreshToken = null;
      state.status = "idle";
    });
  },
});

export const { setOpenDialog, setAdminStatus } = authSlice.actions;
export default authSlice.reducer;

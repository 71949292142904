import { Box, Typography } from "@mui/material";
import React from "react";
import PageCallouts from "../../components/PageCallouts";
import BootstrapCarousel from "../../components/layout/BootstrapCarousel";

const HowardUniversity = () => {
  return (
    <div>
      <BootstrapCarousel
        images={[
          {
            src: "https://res.cloudinary.com/dfwvu4gct/image/upload/v1665929415/RHF/RHF_BlogHeader_Howard_mrx3sq.jpg",
            name: "Rockhouse Foundation - Howard University",
          },
        ]}
      />

      <div className="container my-4">
        <Box className="grid grid-cols-1 md:grid-cols-4 gap-7">
          <Box className="md:col-span-3">
            <Typography variant="h1" color="secondary" className="mb-2">
              HOWARD UNIVERSITY PARTNERSHIP
            </Typography>

            <Typography variant="body1">
              Rockhouse Foundation, Savanna-la-mar Inclusive Infant Academy,
              Howard University and the University of the West Indies join
              forces to host a two day Training Workshop: Working with Special
              Needs Children & Families.
            </Typography>

            <Typography variant="body1">
              It was the end of a long, hot Jamaican summer.
            </Typography>

            <Typography variant="body1">
              Just like Third World sang, it was 96 degrees in the shade. The
              kind of heat that makes sweat trickle down the back of your thighs
              and demands that you immediately lie down by the Rockhouse pool
              with coconut water (preferably mixed with a healthy shot or two of
              Appleton rum.) And yet, 130 teachers from across the western side
              of the island decided to forgo the last of their precious summer
              vacation to attend the two-day Savanna-la-Mar Inclusive Infant
              Academy (SIIA) Training Workshop, hosted by the Rockhouse
              Foundation in partnership with experts from Howard University and
              the University of the West Indies (UWI), at SIIA opened by the
              Foundation last year. In fact, the only teachers who couldn’t make
              it, were those that had their worship days on the Sabbath.
            </Typography>
            <Box className="my-2">
              <img
                src="https://res.cloudinary.com/dfwvu4gct/image/upload/v1665929402/RHF/2019-Howard-Training-Announcement_ev2jlj.jpg"
                alt="Rockhouse Foundation"
                className="object-contain w-full mx-auto"
              />
            </Box>
            <Typography variant="body1">
              Rockhouse is nothing without a vibrant Negril, and surrounding
              local communities where most of its staff live. That’s why the
              Rockhouse Foundation opened SIIA in 2018. The Foundation has
              invested over US$5 million dollars to build this school, modernize
              five other local schools, and renovated the Negril Community
              Library.
            </Typography>
            <Typography variant="body1">
              The SIIA school project supports children from the area,
              especially those with physical disabilities and developmental
              delays. Special needs children often fall through the cracks in
              the overburdened Jamaican education system and are separated from
              their peers in classrooms. SIIA wants to do something different.
              The school’s philosophy is “the full inclusion” model, which
              thinks children of all learning abilities should take classes and
              learn both together and from one and another. It is the first of
              its kind on the island.
            </Typography>
            <Typography variant="body1">
              If there was ever a university that connected with the Jamaican
              spirit it would be the iconic historically black college Howard
              University. For two days local teachers learned from seven Howard
              staff members that were experts in a wide cross section of
              departments from education to psychology to language therapy. It
              was unprecedented access to some of the top academic leaders in
              the U.S. and the start of an ongoing partnership with Rockhouse
              and Howard. Also present was “Smiles for Speech,” a language
              therapy group associated with UWI.
            </Typography>
            <Box className="my-2">
              <img
                src="https://res.cloudinary.com/dfwvu4gct/image/upload/v1665929402/RHF/2019-Howard-Speach_iiqqen.jpg"
                alt="Rockhouse Foundation"
                className="object-contain w-full mx-auto"
              />
            </Box>
          </Box>
          <Box>
            <PageCallouts />
          </Box>
        </Box>
      </div>
    </div>
  );
};

export default HowardUniversity;

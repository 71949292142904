import "./App.css";
import Layout from "./components/layout/Layout";

import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import About from "./pages/About";
import Home from "./pages/Home";
import OurBlog from "./pages/blog/OurBlog";

import OurWork from "./pages/OurWork";
import Events from "./pages/Events";
import Financials from "./pages/about-us/Financials";
import SignUp from "./pages/SignUp";
import Supporters from "./pages/Supporters";
import Board from "./pages/Board";
import History from "./pages/about-us/History";
import Gallery from "./pages/about-us/Gallery";
import Covid from "./pages/blog/Covid";
import SavInclusive from "./pages/blog/SavInclusive";
import HowardUniversity from "./pages/blog/HowardUniversity";
import MarcusSkylark from "./pages/blog/MarcusSkylark";
import AnnualBenefit from "./pages/blog/AnnualBenefit";
import RockhouseFoundation from "./pages/blog/RockhouseFoundation";
import PrivacyPolicy from "./pages/PrivacyPolicy";

import Summary from "./pages/Summary";
import Login from "./pages/admin/Login";
import Images from "./pages/admin/Images";
import Newslettter from "./pages/Newsletter";

// import SimpleReactLightbox from "simple-react-lightbox";

function App() {
  return (
    <BrowserRouter>
      <Layout>
        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us/supporters" element={<Supporters />} />
          <Route path="/about-us/board" element={<Board />} />
          <Route path="/about-us/history" element={<History />} />
          <Route path="/about-us/gallery" element={<Gallery />} />

          <Route
            path="/all-about-the-rockhouse-foundation"
            element={<RockhouseFoundation />}
          />
          <Route path="/our-work" element={<OurWork />} />
          <Route path="/summary" element={<Summary />} />
          <Route path="/blog" element={<OurBlog />} />
          <Route
            path="/blog/covid-19-update-for-negril-jamaica"
            element={<Covid />}
          />
          <Route
            path="/covid-19-update-for-negril-jamaica"
            element={<Covid />}
          />
          <Route
            path="/blog/physiotherapy-at-sav-inclusive"
            element={<SavInclusive />}
          />
          <Route
            path="/physiotherapy-at-sav-inclusive"
            element={<SavInclusive />}
          />
          <Route
            path="/blog/howard-university-partnership"
            element={<HowardUniversity />}
          />
          <Route
            path="/howard-university-partnership"
            element={<HowardUniversity />}
          />
          <Route
            path="/blog/marcus-samuelsson-at-skylark"
            element={<MarcusSkylark />}
          />
          <Route
            path="/marcus-samuelsson-at-skylark"
            element={<MarcusSkylark />}
          />
          <Route
            path="/blog/annual-benefit-at-the-public-theater"
            element={<AnnualBenefit />}
          />
          <Route
            path="/annual-benefit-at-the-public-theater"
            element={<AnnualBenefit />}
          />
          <Route
            path="/blog/all-about-the-rockhouse-foundation"
            element={<RockhouseFoundation />}
          />

          <Route path="/events" element={<Events />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />

          <Route path="/about-us/financials" element={<Financials />} />
          <Route path="/sign-up" element={<Newslettter />} />
          <Route path="/newsletter" element={<Newslettter />} />

          <Route path="/about-us" element={<About />} />
          <Route path="/admin/login" element={<Login />} />
          <Route path="/admin/images" element={<Images />} />

          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Layout>
    </BrowserRouter>
  );
}

export default App;

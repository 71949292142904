import { Button, Typography } from "@mui/material";
import React, { useEffect } from "react";

import { Link as MUILink } from "@mui/material";

import CustomMUILink from "../layout/CustomMUILink";
import { getInstaPosts } from "../../features/instagram/instagramSlice";
import { useDispatch, useSelector } from "react-redux";
import CarouselInsta from "./CarouselInsta";

const InstagramDiv = () => {
  const dispatch = useDispatch();
  // const blogs = useAppSelector((state) => state.blogs)
  const instaPosts = useSelector((state) => state.instagram.instaPosts);
  useEffect(() => {
    dispatch(getInstaPosts());
  }, []);
  return (
    <div className="px-6 text-center">
      <Typography variant="h2" align="center" color="secondary">
        STAY INFORMED
      </Typography>
      <Typography align="center" variant="body1">
        Sign up for our newsletter and follow{" "}
        <CustomMUILink url="https://www.instagram.com/rockhousefoundation/">
          @rockhousefoundation
        </CustomMUILink>{" "}
        to stay up to date on our work, events and developments.
      </Typography>
      <Typography variant="body1" align="center">
        Subscribe to our newsletter for regular updates:
      </Typography>

      <Button
        href="https://www.rockhousefoundation.org/sign-up"
        variant="contained"
        sx={{
          borderRadius: "25px",
          color: "#fff",
          fontWeight: "bold",
          fontFamily: "Helvetica",
          textDecoration: "none",
          ":hover": {
            textDecoration: "none",
            bgcolor: "secondary.main",
            color: "#fff",
          },
        }}
        disableElevation
      >
        Join Our Newsletter
      </Button>

      <Typography
        variant="h2"
        align="center"
        color="secondary"
        sx={{ textTransform: "uppercase", mt: 4 }}
      >
        Visit Our{" "}
        <MUILink
          href="https://www.instagram.com/rockhousefoundation/"
          className="text-[#009FDE] underline"
          target="_blank"
          sx={{
            ":hover": {
              color: "secondary.main",
            },
          }}
        >
          Instagram
        </MUILink>
      </Typography>
      {/* <div className="relative mx-auto mb-8 w-screen text-center"> */}
      <div className="relative mx-auto mb-8 w-screen max-w-full text-center">
        <CarouselInsta items={instaPosts || []} />
      </div>
      {/* </div> */}
    </div>
  );
};

export default InstagramDiv;

import { configureStore } from "@reduxjs/toolkit";
import EventReducer from "../features/events/eventSlice";
import InstagramReducer from "../features/instagram/instagramSlice";
import ImageReducer from "../features/images/ImageSlice";
import AuthReducer from "../features/auth/authSlice";

export default configureStore({
  reducer: {
    events: EventReducer,
    instagram: InstagramReducer,
    images: ImageReducer,
    auth: AuthReducer,
  },
});

import * as React from "react";

import Box from "@mui/material/Box";

import { CssBaseline, Stack } from "@mui/material";
import Footer from "./Footer";

import Header2 from "./Header2";
import { userInfo } from "../../features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import CustomDialog from "../CustomDialog";
import ButtonExternal from "../features/ButtonExternal";

function Layout({ children }) {
  const { user } = useSelector((state) => state.auth);
  const [open, setOpen] = React.useState(true);
  const dispatch = useDispatch();
  React.useEffect(() => {
    if (!isEmpty(user)) {
      dispatch(userInfo());
    }
  }, [JSON.stringify(user)]);
  return (
    <div className="h-full">
      <CssBaseline />
      <Box className="relative ">
        {/* <Header /> */}
        <Header2 />

        <main className="h-full relative pb-92 mb-0 pt-4 ">
          {/* <CustomDialog open={open} handleClose={() => setOpen(false)}>
            <Stack
              component="a"
              href="https://rockhousefoundation.networkforgood.com/events/65001-dinner-on-the-beach-with-chef-andre-fowles"
            >
              <img
                alt=""
                className="mx-auto object-contain md:w-3/4 w-full h-auto mb-2"
                loading="lazy"
                // src='https://res.cloudinary.com/dfwvu4gct/image/upload/v1693394714/RHF/RHF_AnnualDinner_Flyer_101623_01_dxhin1.jpg'
                src="/dinner-on-the-beach-Andrew-Fowles_Feed.jpeg"
              />
              <Box className="mx-auto mt-3">
                <ButtonExternal href="https://rockhousefoundation.networkforgood.com/events/65001-dinner-on-the-beach-with-chef-andre-fowles">
                  Buy Tickets
                </ButtonExternal>
              </Box>
            </Stack>
          </CustomDialog> */}
          {children}
        </main>

        <Footer />
      </Box>
    </div>
  );
}

export default Layout;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import api, { apiConfig } from "../../components/api/axiosApi";

const initialState = {
  images: [],
  loading: false,
  error: null,
  imageStatus: "idle",
};

export const fetchImages = createAsyncThunk("fetchImages", async (thunkAPI) => {
  try {
    const response = await api.get(`/images_upload/`);
    const data = await response.data;
    return data;
    // thunkAPI.dispatch(fetchImagesSuccess(response.data))
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    // thunkAPI.dispatch(fetchImagesFailure(error.message))

    return thunkAPI.rejectWithValue(message);
  }
});

export const createImage = createAsyncThunk(
  "createImages",
  async (data, thunkAPI) => {
    try {
      const response = await api.post(`/images_upload/`, data);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

const imageSlice = createSlice({
  name: "image",
  initialState,
  reducers: {
    setImageStatus(state, { payload }) {
      state.imageStatus = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchImages.pending, (state, action) => {
      state.loading = true;
      state.error = null;
      state.imageStatus = "loading";
    });
    builder.addCase(fetchImages.fulfilled, (state, action) => {
      state.loading = false;
      state.images = action.payload;
      state.imageStatus = "succeeded";
    });
    builder.addCase(fetchImages.rejected, (state, action) => {
      state.loading = false;
      //   state.error = action.payload
      state.imageStatus = "failed";
    });
    builder.addCase(createImage.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(createImage.fulfilled, (state, action) => {
      state.loading = false;
      state.images.push(action.payload);
    });
    builder.addCase(createImage.rejected, (state, action) => {
      state.loading = false;
      //   state.error = action.payload
    });
  },
});

export const {
  setImageStatus,
  //   fetchImagesSuccess,
  //   fetchImagesFailure,
  //   createImageStart,
  //   createImageSuccess,
  //   createImageFailure,
} = imageSlice.actions;

export default imageSlice.reducer;

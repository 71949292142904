import { Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

import LoginForm from "../../components/forms/LoginForm";

const Login = () => {
  const { user, currentUser } = useSelector((state) => state.auth);

  return (
    <>
      <div className="h-screen flex flex-col items-center justify-center w-full px-4">
        <Typography variant="h2" gutterBottom>
          Admin Portal
        </Typography>
        {user && currentUser?.is_admin ? <>hey there</> : <LoginForm />}
      </div>
    </>
  );
};

export default Login;

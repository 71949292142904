import React from "react";

import { motion } from "framer-motion";

const ImageGrid = ({ setSelectedImg, id, images }) => {
  // const { user } = useSelector((state) => state.user);

  // const { docs } = useFirestore('archives', id);
  console.log(id);

  return (
    <div className="grid md:grid-cols-3 grid-cols-2 md:gap-7 gap-2 z-0">
      {images &&
        images?.map((item, i) => (
          <motion.div
            className="relative opacity-85 py-[30%] h-0 overflow-hidden cursor-pointer"
            key={i}
            layout
            whileHover={{ opacity: 1 }}
            onClick={() => setSelectedImg(item.img)}
          >
            {/* {user !== null && (
							<XIcon
								onClick={(e) => {
									e.preventDefault();
									setImages(images.filter((x) => x.name !== file.name));
								}}
								className="text-gray-dark absolute hover:text-white cursor-pointer h-8"
							></XIcon>
						)} */}
            <motion.img
              src={item.img}
              alt={item?.title}
              className="object-contain absolute  min-w-full min-h-full top-0 left-0 my-0"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 1 }}
            />
          </motion.div>
        ))}
    </div>
  );
};

export default ImageGrid;

import { Typography } from "@mui/material";
import { Box } from "@mui/material";
import React from "react";
import DonationBanner from "../components/features/DonationBanner";
import TextImage from "../components/features/TextImage";
import TextImageReverse from "../components/features/TextImageReverse";
import TextSlides from "../components/features/TextSlides";

import VideoBlueBG from "../components/features/VideoBlueBG";
import Seo from "../components/layout/Seo";
import VideoPlayer from "../components/layout/VideoPlayer";

const OurWork = () => {
  return (
    <div>
      <Seo title="Our Work" />

      <VideoPlayer url="https://www.youtube.com/embed/Ao2LCq-O1EQ" />
      <DonationBanner />
      <div className="container">
        <TextImage
          title="OUR WORK"
          text1="The Rockhouse Foundation has been transforming learning environments in the Negril area for almost 20 years. Originally focused on the renovation of public primary schools, the Foundation broadened its focus to include the Negril Library, and next concentrating on early childhood education. During the pandemic the Foundation pivoted to develop one of the islands most robust COVID Relief Initiatives. Currently the primary focus is on the expansion of Sav Inclusive through High School, together with supporting the relationships with the other partner schools."
          url="https://res.cloudinary.com/dfwvu4gct/image/upload/v1637844418/RHF/RHF_2Column_570x429_Toby_fwekfk.jpg"
        />
      </div>

      <VideoBlueBG
        url="https://www.youtube.com/embed/Y9SI-_ind-U"
        title="THE SAVANNA-LA-MAR INCLUSIVE ACADEMY"
        text1="The Rockhouse Foundation's primary project is the expansion of the Savanna-la-Mar Inclusive School (Sav Inclusive). Established in 2017 as Jamaica's first public institution serving children with and without disabilities side by side in a full inclusion setting, the school was built from the ground up by the Rockhouse Foundation. Sav Inclusive has a rapidly growing enrollment with many more applicants than it can accommodate. Current the school enrolls over 250 children from age three to 5th grade."
        text2="Following the success of Sav Inclusive, Jamaica's Ministry of Education and the Rockhouse Foundation have signed a new joint commitment to expand the school through to 12th grade. This new commitment means that each year for the seven years the Foundation will be adding a new grade so the current 5th graders have a path right through high school. The understanding also includes additional adjoining lands allocated for construction of the expanded primary and high schools"
      />

      <div className="container">
        <TextImage
          title="COVID RELIEF INITIATIVE"
          text1="In response to the COVID pandemic the Rockhouse Foundation in March 2020 began weekly distributions of food staples to the most vulnerable families at our partner schools. For many children most of their daily nutrition is normally received through the school breakfast and lunch programs, but with schools closed the Foundation pivoted to fill this void. The Foundation's COVID Relief Food Distribution Program delivered over 250 tons of food and helped to sustain over 1000 people weekly throughout the pandemic. The program began at four of our partner schools and an additional four schools were added in December 2020 with these eight schools participating throughout 2021. The initiative ended in December 2021 after more than 90 consecutive weeks, making it one of Jamaica's most robust and effective programs."
          vUrl="https://www.youtube.com/embed/aRAdq4AVqPg"
        />
        <TextImageReverse
          title="BUNCH OF STARS PRE-K (BOS)"
          text1="The Foundation's first exclusively early childhood education project centered on Bunch of Stars Pre-K in Old Hope, approximately 15 miles east of the Rockhouse Hotel. For 20 years BOS has been a community fixture, initially located on the veranda of its founder, and then for 13 years in a small, two-room, rudimentary structure absent even the most basic early childhood supplies and amenities. The Foundation completed a full renovation and expansion of Bunch of Stars in 2011 and in 2021 undertook an extensive overhaul in anticipation of a 2022 reopening.  Bunch of Stars will soon welcome back an expanded student population onto a newly revitalized compound abundant with supplies, furniture, a vegetable garden for sustainable school nutrition, and a rejuvenated spirit."
          url="https://res.cloudinary.com/dfwvu4gct/image/upload/v1637844418/RHF/RHF_2Column_570x429_OUR-WORK_BunchOfStars_r5kd9r.jpg"
        />
      </div>
      <VideoBlueBG
        url="https://www.youtube.com/embed/LFsCpBVzLU8"
        title="MORELAND HILL PRIMARY SCHOOL"
        text1="After decades of neglect, the Moreland Hill Primary School had an enrollment of just 32 students in 2012 and was slated for closure by Jamaica's Ministry of Education. Over the summer of 2013, the Foundation completed a total transformation of the Moreland Hill Primary School and, alongside a dynamic new principal, welcomed 100 pre-K – 6th grade students who had previously traveled long distances in search of a better education. As schools begin to reopen in 2022.  Now, almost a decade later, Moreland Hill continues to be the school of choice in their area and was fully prepared for in-person learning as it gradually recommenced in January 2022."
      />
      <div className="container">
        <TextImage
          title="LITTLE BAY ALL AGE AND INFANT SCHOOL"
          text1="Little Bay often feels like a forgotten community.  It remains largely unchanged from the time more than 30 years ago when Bob Marley used to journey to the small fishing village for peace and quiet and a little roast fish on the beach. The Little Bay All Age School, like the community, was full of promise but had seen better days, so its foundation to rooftop renovation and expansion in 2010 ushered in a sense of pride and possibilities that are embedded in the school community today.  Technology and teachers feed the minds, an organic garden feeds the bodies, and a revitalized facility feeds the community spirit."
          url="https://res.cloudinary.com/dfwvu4gct/image/upload/v1637844418/RHF/RHF_2Column_570x429_OUR-WORK_LittleBay_i4br5l.jpg"
        />
      </div>
      <VideoBlueBG
        url="https://www.youtube.com/embed/mJkIcMLEFKI?si=ZvQwFw0TOF6FntY3"
        title="NEGRIL PUBLIC LIBRARY"
        text1="For decades, Negril students' and the wider community's only option was a 500 square foot library with minimal resources and no technology. In 2009, after extensive consultation and with the Jamaica Library Service and the “Friends of the Library” committee, the Rockhouse Foundation modernized and expanded the landmark structure, creating Negril's first new civic building in more than 40 years. Its award-winning design by Kingston architects, Chris Stone and David Douglas, seamlessly blended classic Caribbean feel and colors with contemporary function and beauty. Free access to computers and the Internet, as well as thousands more book titles than the original structure created a a vibrant learning environment for community residents of all ages."
        color="custom.main"
        custom={true}
      />
      <div className="container">
        <TextImageReverse
          title="TECH-BASED LITERACY PROGRAM"
          text1="The Jay Hatfield Family Foundation has provided Rockhouse Foundation with the largest sustained grant in its history to implement a computer-based literacy and numeracy program. Implementation, and overall technical expertise, has been driven and supervised by Foundation board member, Ian Coville. Since 2016 all students in 1st – 6th grades at the Moreland Hill and Little Bay Schools devote hours each week improving their math and reading skills. For the opening of the 2021/2022 school year, the Jay Hatfield Family Foundation has underwritten a new, more engaging and effective platform called eSpark. The new grant funds updated devices and more reliable internet services, greatly impacting our ability to assist the schools as they work hard to remediate the learning loss incurred during COVID. In 2021/2022 the Foundation expanded the initiative to include St Paul's Primary School."
          url="https://res.cloudinary.com/dfwvu4gct/image/upload/v1637844418/RHF/RHF_2Column_570x429_OUR-WORK_TechBased-1_ttttul.jpg"
        />
        <TextSlides
          title="HOWARD UNIVERSITY PARTNERSHIP"
          text1="The Rockhouse Foundation has developed a partner relationship with Howard University's Psychology, Education and Speech Language Therapy Departments, in support of Sav Inclusive's multi-faceted needs. Current partnership activities include conferences hosted at Sav Inclusive focused on teacher and parent strategies for supporting children with disabilities. We are also developing plans for staff training, undergrad and graduate student volunteer opportunities, graduate student praticums and development of online degree programs."
          text2="In 2019, 135 local teachers from the Savanna-la-Mar region experienced an engaging 2-day professional development workshop, led by Howard professors, all experts in a cross-section of critical disciplines. It was unprecedented access to some of the top academic practitioners in the U.S. and the start of an ongoing partnership between Rockhouse Foundation, Howard University and Smiles for Speech, an amazing non-profit established by a Howard alumna, supporting speech language therapy in Global South countries."
          slides={true}
          images={[
            {
              src: "https://res.cloudinary.com/dfwvu4gct/image/upload/v1637844418/RHF/RHF_570x429_Howard_C_stnqdi.jpg",
            },
            {
              src: "https://res.cloudinary.com/dfwvu4gct/image/upload/v1665590069/RHF/RHF_570x429_Howard_B_tgqnis.jpg",
            },
            {
              src: "https://res.cloudinary.com/dfwvu4gct/image/upload/v1665590102/RHF/RHF_570x429_Howard_A_y38gck.jpg",
            },
          ]}
        />
      </div>
      <VideoBlueBG
        url="https://www.youtube.com/embed/ubovzCFxsgw"
        title="NEGRIL ALL AGE SCHOOL & NEGRIL BASIC PRE-K SCHOOL"
        text1="Negril's only public elementary school was originally constructed for 100 students, many of them children of fishermen and farmers whose simple lifestyle had defined the community for generations. As Negril turned the page on a new millennium its school held nearly 500 children in over-crowded, outdated buildings with leaky roofs that suffered from years of deferred maintenance. Negril All Age School was in desperate need of assistance."
        text2="Negril Basic School, the primary feeder pre-K for the Negril All Age School and located on the same compound suffered from decades of neglect and minimal funding. The Rockhouse Foundation followed the transformation of Negril All Age with a similar makeover for its companion pre-K. Expanding the space and painting with the same bright colors and filled with new early childhood furniture and educational supplies, games and toys, Negril Basic took on the same rejuvenated spirit as the “big school”. These original two Negril projects were focused on physical transformation of the spaces after which there was not an ongoing commitment."
      />
      <div className="container">
        <Box className="w-full mx-auto my-4">
          <Typography
            variant="h2"
            color="secondary"
            align="center"
            gutterBottom
          >
            ROCKHOUSE FOUNDATION PROJECTS MAP
          </Typography>
          <img
            className="mx-auto object-contain"
            src="https://res.cloudinary.com/dfwvu4gct/image/upload/v1637844419/RHF/RHF_OurWork_Map-1_wggsi0.jpg"
            alt=""
            loading="lazy"
          />
        </Box>
      </div>
    </div>
  );
};

export default OurWork;

import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import {
  createImage,
  fetchImages,
  setImageStatus,
} from "../../features/images/ImageSlice";
import { DownloadTwoTone } from "@mui/icons-material";
import { saveAs } from "file-saver";
import { Button, Dialog, Link, Stack, Typography } from "@mui/material";
import PhotographIcon from "@heroicons/react/outline/PhotographIcon";
import { isEmpty } from "lodash";
import { setOpenDialog, userInfo } from "../../features/auth/authSlice";
import LoginForm from "../../components/forms/LoginForm";

const Images = () => {
  const dispatch = useDispatch();
  const { user, currentUser, openDialog } = useSelector((state) => state.auth);
  const { images, imageStatus } = useSelector((state) => state.images);
  const [selectedImages, setSelectedImages] = useState([]);
  const [imageList, setImageList] = useState({});
  const [image, setImage] = useState(null);

  useEffect(() => {
    if (imageStatus === "idle") {
      dispatch(fetchImages());
    }
  });
  useEffect(() => {
    if (!isEmpty(user)) {
      dispatch(userInfo());
    }
  }, []);

  const handleImageSelect = (e) => {
    setSelectedImages([...selectedImages, ...e.target.files]);
  };

  const handleImageUploads = () => {
    dispatch(createImage(selectedImages));
    dispatch(setImageStatus("idle"));
    setSelectedImages([]);
  };
  // ----------------------------------------------------------------
  const handleImageChange = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      setImage(event.target.files[0]);
    }
  };

  const handleImageUpload = async () => {
    if (!image) {
      return;
    }
    const formData = new FormData();
    formData.append("images", image);
    // get filename
    formData.append("title", image.name);
    // get domains and add id
    formData.append("domain", "7");
    dispatch(createImage(formData));
    setImage(null);
    dispatch(setImageStatus("idle"));
  };
  // ----------------------------------------------------------------
  //   fetch images from api useing fect api
  const download = (url, name) => {
    saveAs(url, name);
  };
  // console.log(images);
  const renderImages = () => {
    return images?.map((image) => {
      return (
        <div key={image.id} className="relative flex flex-col">
          <Typography variant="h6" align="center" fontSize={12}>
            {image.title}
          </Typography>
          <img src={image.images} className="h-96 w-full object-cover" />
          {/* add download icon to images */}
          <Link
            sx={{
              backgroundColor: "primary.main",
            }}
            href={image.images}
            download
            className="text-white absolute bottom-0 right-0 p-1 text-xs font-bold leading-5"
            onClick={() => download(image.images, image.title)}
          >
            Download <DownloadTwoTone />
          </Link>
        </div>
      );
    });
  };

  return (
    <div className="h-screen flex flex-col justify-center items-center px-4">
      {currentUser?.is_admin ? (
        <div className=" container mt-20">
          <Typography variant="h1" align="center" color="primary">
            Images Page
          </Typography>
          <Stack
            rowGap={2}
            justifyContent="flex-start"
            alignItems="flex-start"
            py={3}
          >
            <div className="border-gray-900/25 mx-auto mt-2 flex w-full max-w-md justify-center rounded-lg border border-dashed px-6 py-10">
              <div className="text-center">
                <PhotographIcon
                  className="mx-auto h-12 w-12 text-gray"
                  aria-hidden="true"
                />
                <div className="mt-4 flex justify-center text-sm leading-6 text-gray">
                  <label
                    htmlFor="file-upload"
                    className="bg-white text-indigo-600 focus-within:ring-indigo-600 hover:text-indigo-500 relative cursor-pointer rounded-md  font-semibold focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2"
                  >
                    <span className="text-center text-gray">
                      click to Upload a file
                    </span>
                    <input
                      id="file-upload"
                      type="file"
                      accept="image/*"
                      onChange={handleImageChange}
                      className="sr-only"
                    />
                  </label>
                </div>
                {image ? (
                  <img
                    src={URL.createObjectURL(image)}
                    className="mb-2 h-48 w-full object-cover"
                  />
                ) : null}
                <Button
                  variant="contained"
                  onClick={handleImageUpload}
                  disabled={!image}
                >
                  Upload Image
                </Button>
                <div className="mt-4 flex justify-center text-sm leading-6 text-gray">
                  <label
                    htmlFor="file-upload"
                    className="bg-white text-indigo-600 focus-within:ring-indigo-600 hover:text-indigo-500 relative cursor-pointer rounded-md  font-semibold focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2"
                  >
                    <span className="text-center text-gray">
                      click to Upload multiple fils
                    </span>
                    <input
                      id="file-upload"
                      type="file"
                      accept="image/*"
                      multiple
                      onChange={handleImageSelect}
                      className="sr-only"
                    />
                  </label>
                </div>
                <p className="text-gray-600 mb-3 text-xs leading-5">
                  PNG, JPG, GIF up to 10MB
                </p>
                {!!selectedImages?.length ? (
                  <div className="flex flex-wrap gap-2 space-x-2">
                    {selectedImages?.map((item) => (
                      <img
                        src={URL.createObjectURL(item)}
                        className="mb-2 h-48 w-full object-cover"
                      />
                    ))}
                  </div>
                ) : null}
                <Button
                  variant="contained"
                  onClick={handleImageUploads}
                  sx={{ mr: 2 }}
                  disabled={!selectedImages?.length}
                >
                  Upload Images
                </Button>
              </div>
            </div>
          </Stack>
          {/* List all images below */}
          <div className="grid sm:grid-cols-4 gap-2 grid-cols-2">
            {renderImages()}
          </div>
        </div>
      ) : (
        <>
          <Typography component="h1" variant="h5">
            You must be and logged in and an admin to view this page
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => dispatch(setOpenDialog(true))}
          >
            Login
          </Button>
        </>
      )}
      <Dialog
        open={openDialog}
        onClose={() => dispatch(setOpenDialog(false))}
        sx={{
          width: "100%",
          maxWidth: 400,
          mx: "auto",
        }}
      >
        <LoginForm />
      </Dialog>
    </div>
  );
};

export default Images;

import { Box, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import ImageModal from "./ImageModal";

const SupportersList = ({
  title,
  list1,
  list2,
  list3,
  img1 = "",
  img3 = "",
  img2 = "",
  list4 = [],
}) => {
  const [selectedImg, setSelectedImg] = useState(null);

  return (
    <Stack spacing={2}>
      <Typography variant="h2" align="center" color="secondary">
        {title}
      </Typography>
      <Box
        className={`grid grid-cols-${
          list4.length === 0 ? "1" : "2"
        } md:grid-cols-${list4.length === 0 ? "3" : "4"} gap-4`}
      >
        <Box>
          {list1.map((item, index) => (
            <Typography variant="body1" key={index} sx={{ paddingBottom: 0 }}>
              {item}
            </Typography>
          ))}
        </Box>
        <Box>
          {list2.map((item, index) => (
            <Typography variant="body1" key={index} sx={{ paddingBottom: 0 }}>
              {item}
            </Typography>
          ))}
        </Box>

        <Box>
          {list3.map((item, index) => (
            <Typography variant="body1" key={index} sx={{ paddingBottom: 0 }}>
              {item}
            </Typography>
          ))}
        </Box>
        {list4.length >= 1 && (
          <Box>
            {list4.map((item, index) => (
              <Typography variant="body1" key={index} sx={{ paddingBottom: 0 }}>
                {item}
              </Typography>
            ))}
          </Box>
        )}
      </Box>
      {/* 3 images */}
      <Box className="grid grid-cols-1 md:grid-cols-3 gap-4 my-3">
        <img
          src={img1}
          className="object-contain w-full"
          alt=""
          onClick={() => setSelectedImg(img1)}
        />
        <img
          src={img2}
          className="object-contain w-full"
          alt=""
          onClick={() => setSelectedImg(img2)}
        />

        <img
          src={img3}
          className="object-contain w-full"
          alt=""
          onClick={() => setSelectedImg(img3)}
        />
      </Box>
      {selectedImg && (
        <ImageModal selectedImg={selectedImg} setSelectedImg={setSelectedImg} />
      )}
    </Stack>
  );
};

export default SupportersList;

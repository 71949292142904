import React from "react";
import { Link } from "react-router-dom";

const RHButtonText = ({ url, text, variant = "text" }) => {
  return (
    <Link to={url} style={{ textDecoration: "none", display: "inline-block" }}>
      <span className="cursor-pointer hover:text-blue-900 hover:font-bold text-[#FA7FB9]">
        {text}
      </span>
    </Link>
  );
};

export default RHButtonText;

import { createTheme, responsiveFontSizes } from "@mui/material";

let theme = createTheme({
  palette: {
    primary: {
      main: "#FA7FB9",
    },
    secondary: {
      main: "#009FDE",
    },
    custom: {
      main: "#F4E7DA",
    },
  },
  typography: {
    // fontFamily: ['Arial', 'Helvetica', 'Arial', 'Lucida', 'sans-serif'].join(
    //   ','
    // ),
    h1: {
      fontWeight: "bold",
      fontSize: "45px",
      paddingBottom: "10px",
      textTransform: "uppercase",
      lineHeight: 1,
      letterSpacing: "2px",
      fontFamily: [
        '"PT Sans Narrow"',
        "Helvetica",
        "Arial",
        "Lucida",
        "sans-serif",
      ].join(","),
    },
    h2: {
      fontWeight: "bold",
      fontSize: "38px",
      paddingBottom: "10px",
      letterSpacing: "2px",
      textTransform: "uppercase",
      lineHeight: 1,
      fontFamily: [
        '"PT Sans Narrow"',
        "Helvetica",
        "Arial",
        "Lucida",
        "sans-serif",
      ].join(","),
    },

    h3: {
      fontWeight: "bold",
      fontSize: "23px",
      paddingBottom: "10px",
      textTransform: "uppercase",
      lineHeight: 1,
      letterSpacing: "2px",
      fontFamily: [
        '"PT Sans Narrow"',
        "Helvetica",
        "Arial",
        "Lucida",
        "sans-serif",
      ].join(","),
    },
    h4: {
      fontSize: "20px",
      paddingBottom: "10px",
      textTransform: "uppercase",
      lineHeight: 1,
      letterSpacing: "2px",

      fontFamily: [
        '"PT Sans Narrow"',
        "Helvetica",
        "Arial",
        "Lucida",
        "sans-serif",
      ].join(","),
    },
    h5: {
      fontSize: "18px",
      paddingBottom: "10px",
      textTransform: "uppercase",
      lineHeight: 1,
      letterSpacing: "2px",

      fontFamily: [
        '"PT Sans Narrow"',
        "Helvetica",
        "Arial",
        "Lucida",
        "sans-serif",
      ].join(","),
    },
    h6: {
      fontSize: "16px",
      paddingBottom: "10px",
      textTransform: "uppercase",
      lineHeight: 1,
      letterSpacing: "2px",

      fontFamily: [
        '"PT Sans Narrow"',
        "Helvetica",
        "Arial",
        "Lucida",
        "sans-serif",
      ].join(","),
    },
    body2: {
      fontSize: 12,
      fontFamily: [
        '"PT Sans Narrow"',
        "Helvetica",
        "Arial",
        "Lucida",
        "sans-serif",
      ].join(","),
    },
    body1: {
      fontFamily: ["Arial", "Helvetica", "Arial", "Lucida", "sans-serif"].join(
        ","
      ),
      paddingBottom: "16px",
    },
    subtitle2: {
      fontFamily: ["Arial", "Helvetica", "Arial", "Lucida", "sans-serif"].join(
        ","
      ),

      fontWeight: "bold",
      color: "#555",
      fontSize: 16,
      paddingBottom: "10px",
    },
  },
  // typography: {
  //   fontFamily: ['"PT Sans Narrow"', '"Open Sans"', "sans-serif"].join(","),
  //   h1: {
  //     fontSize: "2.5rem",
  //     fontWeight: "bold",
  //     paddingBottom: "5px",
  //     fontFamily: ['"PT Sans Narrow"', '"Open Sans"', "sans-serif"].join(","),
  //   },
  //   h2: {
  //     fontWeight: "bold",
  //     paddingBottom: "5px",
  //     fontFamily: ['"PT Sans Narrow"', '"Open Sans"', "sans-serif"].join(","),
  //     fontSize: "2.25rem",
  //   },
  //   h3: {
  //     fontWeight: "bold",
  //     paddingBottom: "5px",
  //     fontFamily: ['"PT Sans Narrow"', '"Open Sans"', "sans-serif"].join(","),
  //     fontSize: "2rem",
  //   },
  //   h4: {
  //     fontWeight: "bold",
  //     paddingBottom: "5px",
  //     fontFamily: ['"PT Sans Narrow"', '"Open Sans"', "sans-serif"].join(","),
  //     fontSize: "1.85rem",
  //   },
  //   h5: {
  //     fontWeight: "bold",
  //     paddingBottom: "5px",
  //     fontFamily: ['"PT Sans Narrow"', '"Open Sans"', "sans-serif"].join(","),
  //     fontSize: "1.5rem",
  //   },
  //   h6: {
  //     fontWeight: "bold",
  //     paddingBottom: "5px",
  //     fontSize: "1.25rem",
  //   },
  //   body1: {
  //     color: "#777",
  //     fontFamily: ["Open Sans", "sans-serif"].join(","),
  //     paddingBottom: "10px",
  //     fontSize: "1.125rem",
  //   },
  //   body2: {
  //     fontFamily: ['"PT Sans Narrow"', '"Open Sans"', "sans-serif"].join(","),
  //     fontSize: "1rem",
  //   },
  //   subtitle1: {
  //     color: "#777",
  //     fontWeight: "900",
  //     fontFamily: ["Helvetica", "sans-serif"].join(","),
  //     paddingBottom: "10px",
  //   },
  // },
  components: {
    // Name of the component
    MuiButtonBase: {
      fontFamily: [
        '"PT Sans Narrow"',
        "Helvetica",
        "Arial",
        "Lucida Sans",
        "sans-serif",
      ],
      fontWeight: "bold",
      //   defaultProps: {
      // 	// The props to change the default for.
      // 	disableRipple: true, // No more ripple, on the whole application 💣!
      //   },
    },
  },
});

theme = responsiveFontSizes(theme);

export default theme;
